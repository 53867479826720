import { Injectable } from '@angular/core';
import { Store } from '../models/store';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService extends Store<boolean> {

  public screenWidth: string;

  constructor() { super(false); }

  public checkWidth() {
    var width = window.innerWidth;
    if (width <= 768) {
      this.screenWidth = 'sm';
      this.setState(true);
    } else if (width > 768 && width <= 992) {
      this.screenWidth = 'md';
      this.setState(false);
    } else {
      this.screenWidth = 'lg';
      this.setState(false);
    }
  }

}
