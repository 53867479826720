import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { WebRequestConfig } from '../../wrcommon/classes/config';
import { CallUsContent } from '../models/call-us-content.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CallUsService = /** @class */ (function () {
    function CallUsService(http) {
        this.http = http;
    }
    CallUsService.prototype.GetRequestTypeCriticalContent = function (clientId) {
        return this.http.get(WebRequestConfig.baseUrlClientRequest + "public/GetRequestTypeCriticalContent/" + clientId)
            .pipe(map(function (data) { return CallUsContent.fromAPI(data); }));
    };
    ;
    CallUsService.prototype.GetCallUsContent = function (clientId) {
        return this.http.get(WebRequestConfig.baseUrlClientRequest + "public/GetCallUsContent/" + clientId)
            .pipe(map(function (data) { return CallUsContent.fromAPI(data); }));
    };
    ;
    CallUsService.prototype.GetCallUsPhoneNumber = function (clientId) {
        return this.http.get(WebRequestConfig.baseUrlClientRequest + "public/GetCallUsPhoneNumber/" + clientId);
    };
    ;
    CallUsService.prototype.GetBGISPhoneNumber = function () {
        return this.http.post(WebRequestConfig.baseUrlClientRequest + "public/GetBGISPhoneNumber/", null);
    };
    ;
    CallUsService.ngInjectableDef = i0.defineInjectable({ factory: function CallUsService_Factory() { return new CallUsService(i0.inject(i1.HttpClient)); }, token: CallUsService, providedIn: "root" });
    return CallUsService;
}());
export { CallUsService };
