import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserdatasharingService } from '../../services/userdatasharing.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  navLinks: any[];
  showTabs: boolean = true;

  constructor(private userService: UserdatasharingService,
    private route: ActivatedRoute) {
  }

  ngOnInit() {

    this.showTabs = this.userService.showTabs;

    if (!!this.route.firstChild.snapshot.data['needHelp']) {
      this.showTabs = true;
      this.navLinks = [
        {
          label: 'unlockAccount.Title',
          url: '/account/unlockaccount'
        },
        {
          label: 'resetPassword.Title',
          url: '/account/resetpassword'
        }
      ];
    } else {
      this.navLinks = [
        {
          label: 'login.Login',
          url: '/login'
        },
        {
          label: 'login.Signup',
          url: '/registration'
        }
      ];
    }
  }
}
