import * as tslib_1 from "tslib";
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { EmergencyRequestAlertComponent, AlertIcon } from '../components/emergency-request-alert/emergency-request-alert.component';
import { CallUsService } from './call-us.service';
import * as i0 from "@angular/core";
import * as i1 from "./call-us.service";
import * as i2 from "@angular/material/dialog";
var CallUsRequestService = /** @class */ (function () {
    function CallUsRequestService(callUsService, dialog) {
        var _this = this;
        this.callUsService = callUsService;
        this.dialog = dialog;
        this.clientPhoneNumber = null;
        this.callUsService.GetBGISPhoneNumber()
            .pipe(take(1))
            .subscribe(function (bgisPhoneNumber) { return _this.bgisPhoneNumber = bgisPhoneNumber; });
        this.callUsService.GetCallUsPhoneNumber(-1)
            .pipe(take(1))
            .subscribe(function (globalPhoneNumber) {
            _this.globalPhoneNumber = globalPhoneNumber;
        });
    }
    Object.defineProperty(CallUsRequestService.prototype, "callUsPhone", {
        get: function () {
            return this.clientPhoneNumber || this.globalPhoneNumber || this.bgisPhoneNumber || '1-877-353-4543';
        },
        enumerable: true,
        configurable: true
    });
    CallUsRequestService.prototype.init = function (clientId) {
        var _this = this;
        // check if client was changed
        if (this.clientId === clientId) {
            return;
        }
        this.clientId = clientId;
        if (!this.clientId || this.clientId <= 0) {
            this.clientPhoneNumber = null;
            return;
        }
        this.callUsService.GetCallUsPhoneNumber(this.clientId)
            .pipe(take(1))
            .subscribe(function (clientPhoneNumber) {
            _this.clientPhoneNumber = clientPhoneNumber;
        });
        this.callUsService.GetCallUsContent(this.clientId)
            .pipe(take(1))
            .subscribe(function (callUsContent) {
            _this.callUsContent = callUsContent;
        });
        this.callUsService.GetRequestTypeCriticalContent(this.clientId)
            .pipe(take(1))
            .subscribe(function (requestTypeCriticalContent) { return _this.requestTypeCriticalContent = requestTypeCriticalContent; });
    };
    CallUsRequestService.prototype.openEmergencyRequestKeywordAlert = function (emergencyKeywords) {
        if (!this.callUsContent) {
            return EMPTY.toPromise();
        }
        var callUsText = this.callUsContent.callUsTextEmergencyWords.replace(/{keyword}/g, "\"" + emergencyKeywords.join(', ') + "\"");
        return this.openEmergencyRequestAlert(AlertIcon.alert, 'EmergencyRequestTitle', callUsText, this.callUsPhone);
    };
    CallUsRequestService.prototype.openRequestTypeCriticalContentAlert = function () {
        if (!this.requestTypeCriticalContent) {
            return EMPTY.toPromise();
        }
        this.openEmergencyRequestAlert(AlertIcon.error, 'CriticalSiteTitle', this.requestTypeCriticalContent.callUsText, this.requestTypeCriticalContent.callUsPhoneNumber || this.callUsPhone);
    };
    CallUsRequestService.prototype.openDuplicateDisabledContentAlert = function () {
        if (!this.callUsContent) {
            return EMPTY.toPromise();
        }
        this.openEmergencyRequestAlert(AlertIcon.alert, 'EmergencyRequestTitle', this.callUsContent.duplicateDisabledText, this.callUsPhone);
    };
    CallUsRequestService.prototype.openCallUsContentAlert = function () {
        if (!this.callUsContent) {
            return EMPTY.toPromise();
        }
        this.openEmergencyRequestAlert(AlertIcon.alert, 'EmergencyRequestTitle', this.callUsContent.callUsText, this.callUsPhone);
    };
    CallUsRequestService.prototype.openEmergencyRequestAlert = function (alertIcon, title, content, phoneNumber) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogRef;
            return tslib_1.__generator(this, function (_a) {
                dialogRef = this.dialog.open(EmergencyRequestAlertComponent, {
                    data: {
                        icon: alertIcon,
                        //title: 'alert.' + title,
                        content: content,
                        callUs: 'alert.CallUs',
                        phoneNumber: phoneNumber
                    },
                    disableClose: true,
                    hasBackdrop: true,
                    restoreFocus: true,
                    role: 'alertdialog'
                });
                return [2 /*return*/, dialogRef.afterClosed().toPromise()];
            });
        });
    };
    CallUsRequestService.ngInjectableDef = i0.defineInjectable({ factory: function CallUsRequestService_Factory() { return new CallUsRequestService(i0.inject(i1.CallUsService), i0.inject(i2.MatDialog)); }, token: CallUsRequestService, providedIn: "root" });
    return CallUsRequestService;
}());
export { CallUsRequestService };
