import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MainNav } from '../../models/main-nav.model';
import { MainMenuService } from '../../services/main-menu.service';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { CallUsRequestService } from '../../services/call-us-request.service';

@Component({
  selector: 'app-side-nav-tree',
  templateUrl: './side-nav-tree.component.html',
  styleUrls: ['./side-nav-tree.component.scss']
})
export class SideNavTreeComponent implements OnInit {
  mainMenu: MainNav[];
  activeSection = '';


  constructor(private router: Router,
    public userService: UserdatasharingService,
    private mainMenuService: MainMenuService,
    public callUsRequestService: CallUsRequestService) {
  }

  ngOnInit() {
    this.getMenu();
  }

  getMenu(): void {
    this.mainMenuService.getMenuItems()
      .pipe(take(1))
      .subscribe(menuItems => (this.mainMenu = menuItems));
  }

  toggleSection(section: string) {
    if (this.activeSection !== section) {
      this.activeSection = section;
    } else {
      this.activeSection = '';
    }
    //console.log(this.activeSection);
  }

  get isAuthenticated(): Observable<boolean> {
    return this.userService.isAuthenticated();
  }

  logout(): void {
    this.userService.clear();
    this.router.navigateByUrl('/login');
  }
}
