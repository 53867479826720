var BiMap = /** @class */ (function () {
    function BiMap(map) {
        var _this = this;
        this.map = new Map();
        this.reverse = new Map();
        if (map) {
            if (map instanceof Map) {
                map
                    .forEach(function (value, key) {
                    _this.set(key, value);
                });
            }
            else if (Array.isArray(map)) {
                map
                    .forEach(function (entry) {
                    _this.set(entry[0], entry[1]);
                });
            }
            else {
                Object.keys(map)
                    .forEach(function (key) {
                    _this.set(key, map[key]);
                });
            }
        }
    }
    Object.defineProperty(BiMap.prototype, "size", {
        get: function () {
            return this.map.size;
        },
        enumerable: true,
        configurable: true
    });
    BiMap.prototype.set = function (key, value) {
        if (this.map.has(key)) {
            var existingValue = this.map.get(key);
            this.reverse.delete(existingValue);
        }
        if (this.reverse.has(value)) {
            var existingKey = this.reverse.get(value);
            this.map.delete(existingKey);
        }
        this.map.set(key, value);
        this.reverse.set(value, key);
        return this;
    };
    BiMap.prototype.clear = function () {
        this.map.clear();
        this.reverse.clear();
    };
    BiMap.prototype.getValue = function (key) {
        return this.map.get(key);
    };
    BiMap.prototype.getKey = function (value) {
        return this.reverse.get(value);
    };
    BiMap.prototype.deleteKey = function (key) {
        var value = this.map.get(key);
        this.reverse.delete(value);
        return this.map.delete(key);
    };
    BiMap.prototype.deleteValue = function (value) {
        var key = this.reverse.get(value);
        this.map.delete(key);
        return this.reverse.delete(value);
    };
    BiMap.prototype.hasKey = function (key) {
        return this.map.has(key);
    };
    BiMap.prototype.hasValue = function (value) {
        return this.reverse.has(value);
    };
    BiMap.prototype.keys = function () {
        return this.map.keys();
    };
    BiMap.prototype.values = function () {
        return this.reverse.keys();
    };
    BiMap.prototype.entries = function () {
        return this.map.entries();
    };
    BiMap.prototype.forEach = function (callbackfn, thisArg) {
        return this.map.forEach(callbackfn);
    };
    return BiMap;
}());
export { BiMap };
