import { BiMap } from './bi-map';
var Language = /** @class */ (function () {
    function Language() {
    }
    Object.defineProperty(Language, "English", {
        get: function () { return 'ENGLISH'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "French", {
        get: function () { return 'FRENCH'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "German", {
        get: function () { return 'GERMAN'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "Spanish", {
        get: function () { return 'SPANISH'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "Italian", {
        get: function () { return 'ITALIAN'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "Japanese", {
        get: function () { return 'JAPANESE'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "Russian", {
        get: function () { return 'RUSSIAN'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "Portuguese", {
        get: function () { return 'PORTUGUESE'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLanguageCanada", {
        get: function () { return 'ca'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLanguageEnglish", {
        get: function () { return 'en'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLanguageFrench", {
        get: function () { return 'fr'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLanguageGerman", {
        get: function () { return 'de'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLanguageSpanish", {
        get: function () { return 'es'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLanguageItalian", {
        get: function () { return 'it'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLanguageJapanese", {
        get: function () { return 'ja'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLanguageRussian", {
        get: function () { return 'ru'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLanguagePortuguese", {
        get: function () { return 'pt'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "SupportedLanguages", {
        get: function () {
            return new BiMap([
                [this.TranslationLanguageEnglish, this.English],
                [this.TranslationLanguageFrench, this.French],
                [this.TranslationLanguageGerman, this.German],
                [this.TranslationLanguageSpanish, this.Spanish],
                [this.TranslationLanguageItalian, this.Italian],
                [this.TranslationLanguageJapanese, this.Japanese],
                [this.TranslationLanguageRussian, this.Russian],
                [this.TranslationLanguagePortuguese, this.Portuguese]
            ]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "TranslationLoadedStorageKey", {
        get: function () {
            return 'TRANS_LOADED';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "CurrentLanguageStorageKey", {
        get: function () {
            return 'CURRENT_LANG';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Language, "LangQueryString", {
        get: function () { return 'lang'; },
        enumerable: true,
        configurable: true
    });
    // falback to English if the browser language is not supported
    // Extract the culture root, this is the substring precending the dash (i.e. en-CA) or the navigator language (i.e. ru).
    Language.getLanguageFromBrowser = function () {
        var result = Language.English;
        if (!!navigator.language) {
            result = navigator.language.toLowerCase();
            if (result.indexOf('-') !== -1) {
                result = result.split('-')[0];
            }
            result = Language.SupportedLanguages.hasKey(result) ? Language.SupportedLanguages.getValue(result) : Language.English;
        }
        return result;
    };
    return Language;
}());
export { Language };
