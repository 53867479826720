import { Injectable } from '@angular/core';
import { Store } from '../models/store';
import { ModuleOptionsForChargeToAccount } from '../../new-request/models/ModuleOptionsForChargeToAccount';

@Injectable({
  providedIn: 'root'
})
export class ChargeToAccountLabelStoreService extends Store<string> {

  enableWorkOrderChargeToAccount: boolean = true; // final decision flag
  enableChargeToAccountAsMandatory: boolean = false; //final decision flag for optional or mandatory
  enableCallerCallerChargeToAcctAutoPopulate: boolean = false;

  enableChargeToAccountOnAllRequestTypes: boolean = false;
  enableChargeToAccountOnAllRequestTypeAsMandatory: boolean = false;
  enableChargeToAccountOnCMWorkOrders: boolean = false;
  enableChargeToAccountOnCMWorkOrdersAsMandatory: boolean = false;
  chargeToAccountBillableInd: boolean = false;

  constructor() { super(''); }

  setChargeToAccountBasedOnBillableFlag(billableInd: boolean) {
    console.log('Is service billable???', billableInd);
    this.chargeToAccountBillableInd = billableInd;
    if (this.chargeToAccountBillableInd) {
      this.enableChargeToAccountAsMandatory = true;
    }
    else {
      this.enableChargeToAccountAsMandatory = this.enableChargeToAccountOnAllRequestTypeAsMandatory;
    }
    this.enableWorkOrderChargeToAccount = this.enableChargeToAccountOnAllRequestTypes || billableInd;
    console.log('Final flag enableWorkOrderChargeToAccount--->>>', this.enableWorkOrderChargeToAccount);
    console.log('Final flag enableChargeToAccountAsMandatory--->>>', this.enableChargeToAccountAsMandatory);
  }

  showChargeToAccountAsMandatoryField() {
    return this.chargeToAccountBillableInd ||
      (this.enableChargeToAccountOnAllRequestTypes &&
        this.enableChargeToAccountOnAllRequestTypeAsMandatory)
  }

  setChargeToAccountFields(data: ModuleOptionsForChargeToAccount) {
    this.enableWorkOrderChargeToAccount = data.EnableWorkOrderChargeToAccount;
    this.enableCallerCallerChargeToAcctAutoPopulate = data.EnableCallerChargeToAcctAutoPopulate;
    this.enableChargeToAccountOnAllRequestTypes = data.EnableChargeToAccountOnAllRequestTypes;
    this.enableChargeToAccountOnAllRequestTypeAsMandatory = data.EnableChargeToAccountOnAllRequestTypeAsMandatory;
    this.enableChargeToAccountOnCMWorkOrders = data.EnableChargeToAccountOnCMWorkOrders;
    this.enableChargeToAccountOnCMWorkOrdersAsMandatory = data.EnableChargeToAccountOnCMWorkOrdersAsMandatory;
  }
}
