import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountModel } from 'src/app/modules/core/models/accountmodel';
import { Language } from '../../wrcommon/classes/language';

@Injectable({ providedIn: 'root' })
export class RegistrationDataSharingService {
  private readonly INITIAL_VALUE: AccountModel = {
    userId: -1,
    clientId: -1,
    clientCode: '',
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    phoneNumberCountryCode: '',
    password: '',
    confirmPassword: '',
    RetCode: null,
    ErrMsg: null,

    RememberMe: false,
    language: Language.English,
    requestReceivedNotification: false,
    requestAssignedNotification: false,
    arrivedNotification: false,
    inProgressNotification: false,
    completeNotification: false,
    hasWorkOrders: null,
    AccessToken: null,
    IsFederated: null
  };

  private subject: BehaviorSubject<AccountModel> = new BehaviorSubject<
    AccountModel
    >(this.INITIAL_VALUE);

  // set the service data
  addData(data: AccountModel) {
    
    if (data == null ) {
      throw new Error(`Invalid data: data is null.`);
    }

    this.subject.next(data);
  }


  // re-set the service data to its initial state
  clearMessages() {
    this.subject.next({
      userId: -1,
      clientId: -1,
      clientCode: '',
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      phoneNumber: '',
      phoneNumberCountryCode: '',
      password: '',
      confirmPassword: '',
      RetCode: null,
      ErrMsg: null,

      RememberMe: false,
      language: Language.English,
      requestReceivedNotification: false,
      requestAssignedNotification: false,
      arrivedNotification: false,
      inProgressNotification: false,
      completeNotification: false,
      AccessToken: null,
      IsFederated: null
    } as AccountModel);
  }

  // return the data as an observable
  getData(): Observable<AccountModel> {
    return this.subject.asObservable();
  }
}
