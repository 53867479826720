var Constants = /** @class */ (function () {
    function Constants() {
    }
    Object.defineProperty(Constants, "WebAssist", {
        get: function () { return 'WEB_ASSIST'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "CanadaCountryCode", {
        get: function () { return 'CA'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "EmailRegex", {
        get: function () {
            return /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*(\.\w{2,7})+$/i;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "PasswordRegex", {
        get: function () {
            return /^[A-Za-z\d]{8,}$|^[A-Za-z~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?\/]{8,}$|^[a-z\d~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?\/]{8,}$|^[A-Z\d~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?\/]{8,}$|^[A-Za-z\d~!@#$%^&*_\-+=`|\\(){}[\]:;"'<>,.?\/]{8,}$/;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "UserNameRegex", {
        get: function () {
            return /^[^\\/\[\]:;|=\+\*?<>"]+$/;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "NameRegex", {
        get: function () {
            return /^([ \u00c0-\u01ffa-zA-Z0-9'\-.])+$/;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "MaxLengthPhoneNumber", {
        get: function () {
            return 17; // this is MAX_LENGTH_FOR_NSN of the Google's phoneNumberUtil library
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "RequestStatusComplete", {
        get: function () {
            return 'COMPLETE';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "RequestStatusCanceled", {
        get: function () {
            return 'CANCELD'; // CANCELD is correct is not a spelling mistake. Pls see RHLP_WO_REQUEST_STATUS table data.
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "HttpCodeNotFound", {
        get: function () {
            return 404;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "AcceptLanguage", {
        get: function () {
            return 'Accept-Language';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "Image", {
        get: function () {
            return 'image';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "Audio", {
        get: function () {
            return 'audio';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "Video", {
        get: function () {
            return 'video';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "TenantKey", {
        get: function () { return 'ssoTenant'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "AccountStorageKey", {
        get: function () { return 'ACCOUNT'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "ProgressNoteSortKey", {
        get: function () { return 'PROGRESS_NOTE_SORT'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "UserNameStorageKey", {
        get: function () { return 'USER_NAME'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "IsHighContrastStorageKey", {
        get: function () { return 'IS_HIGH_CONTRAST'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "WebAssistWeb", {
        get: function () { return 'WEB ASSIST WEB'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "WebAssistMobile", {
        get: function () { return 'WEB ASSIST MOBILE'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "AnswerSelect", {
        get: function () {
            return 'answerSelect';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "SearchFormControlDefaultClassName", {
        get: function () {
            return 'dark-bg';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "Fill", {
        get: function () {
            return 'fill';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "ChargeToAccountTitle", {
        get: function () {
            return 'steps.ChargeToAccountLabel';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "AccountLockedReturnCode", {
        get: function () {
            return -13;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "ChargeToAccountKeyword", {
        get: function () {
            return '{ChargeToAccount}';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "MinLengthPhoneNumberWithoutCountryCode", {
        get: function () {
            return 10;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Constants, "Countries", {
        get: function () {
            return [
                { "nameEn": "Afghanistan", "nameFr": "Afghanistan", "countryCode": "AF", "phoneCode": "+93" },
                { "nameEn": "Åland Islands", "nameFr": "Åland Islands", "countryCode": "AX", "phoneCode": "+358" },
                { "nameEn": "Albania", "nameFr": "Albanie", "countryCode": "AL", "phoneCode": "+355" },
                { "nameEn": "Algeria", "nameFr": "Algérie", "countryCode": "DZ", "phoneCode": "+213" },
                { "nameEn": "American Samoa", "nameFr": "American Samoa", "countryCode": "AS", "phoneCode": "+1684" },
                { "nameEn": "Andorra", "nameFr": "Andorre", "countryCode": "AD", "phoneCode": "+376" },
                { "nameEn": "Angola", "nameFr": "Angola", "countryCode": "AO", "phoneCode": "+244" },
                { "nameEn": "Anguilla", "nameFr": "Anguilla", "countryCode": "AI", "phoneCode": "+1264" },
                { "nameEn": "Antarctica", "nameFr": "Antarctica", "countryCode": "AQ", "phoneCode": "+672" },
                { "nameEn": "Antigua and Barbuda", "nameFr": "Antigua-et-Barbuda", "countryCode": "AG", "phoneCode": "+1268" },
                { "nameEn": "Argentina", "nameFr": "Argentine", "countryCode": "AR", "phoneCode": "+54" },
                { "nameEn": "Armenia", "nameFr": "Arménie", "countryCode": "AM", "phoneCode": "+374" },
                { "nameEn": "Aruba", "nameFr": "Aruba", "countryCode": "AW", "phoneCode": "+297" },
                { "nameEn": "Australia", "nameFr": "Australie", "countryCode": "AU", "phoneCode": "+61" },
                { "nameEn": "Austria", "nameFr": "Autriche", "countryCode": "AT", "phoneCode": "+43" },
                { "nameEn": "Azerbaijan", "nameFr": "Azerbaïdjan", "countryCode": "AZ", "phoneCode": "+994" },
                { "nameEn": "Bahamas", "nameFr": "Bahamas", "countryCode": "BS", "phoneCode": "+1242" },
                { "nameEn": "Bahrain", "nameFr": "Bahreïn", "countryCode": "BH", "phoneCode": "+973" },
                { "nameEn": "Bangladesh", "nameFr": "Bangladesh", "countryCode": "BD", "phoneCode": "+880" },
                { "nameEn": "Barbados", "nameFr": "Barbade", "countryCode": "BB", "phoneCode": "+1246" },
                { "nameEn": "Belarus", "nameFr": "Biélorussie", "countryCode": "BY", "phoneCode": "+375" },
                { "nameEn": "Belgium", "nameFr": "Belgique", "countryCode": "BE", "phoneCode": "+32" },
                { "nameEn": "Belize", "nameFr": "Belize", "countryCode": "BZ", "phoneCode": "+501" },
                { "nameEn": "Benin", "nameFr": "Bénin", "countryCode": "BJ", "phoneCode": "+229" },
                { "nameEn": "Bermuda", "nameFr": "Bermuda", "countryCode": "BM", "phoneCode": "+1441" },
                { "nameEn": "Bhutan", "nameFr": "Bhoutan", "countryCode": "BT", "phoneCode": "+975" },
                { "nameEn": "Bolivia, Plurinational State of bolivia", "nameFr": "Bolivie", "countryCode": "BO", "phoneCode": "+591" },
                { "nameEn": "Bosnia and Herzegovina", "nameFr": "Bosnie-Herzégovine", "countryCode": "BA", "phoneCode": "+387" },
                { "nameEn": "Botswana", "nameFr": "Botswana", "countryCode": "BW", "phoneCode": "+267" },
                { "nameEn": "Bouvet Island", "nameFr": "Bouvet Island", "countryCode": "BV", "phoneCode": "+47" },
                { "nameEn": "Brazil", "nameFr": "Brésil", "countryCode": "BR", "phoneCode": "+55" },
                { "nameEn": "British Indian Ocean Territory", "nameFr": "British Indian Ocean Territory", "countryCode": "IO", "phoneCode": "+246" },
                { "nameEn": "Brunei Darussalam", "nameFr": "Brunei", "countryCode": "BN", "phoneCode": "+673" },
                { "nameEn": "Bulgaria", "nameFr": "Bulgarie", "countryCode": "BG", "phoneCode": "+359" },
                { "nameEn": "Burkina Faso", "nameFr": "Burkina Faso", "countryCode": "BF", "phoneCode": "+226" },
                { "nameEn": "Burundi", "nameFr": "Burundi", "countryCode": "BI", "phoneCode": "+257" },
                { "nameEn": "Cambodia", "nameFr": "Cambodge", "countryCode": "KH", "phoneCode": "+855" },
                { "nameEn": "Cameroon", "nameFr": "Cameroun", "countryCode": "CM", "phoneCode": "+237" },
                { "nameEn": "Canada", "nameFr": "Canada", "countryCode": "CA", "phoneCode": "+1" },
                { "nameEn": "Cape Verde", "nameFr": "Cap-Vert", "countryCode": "CV", "phoneCode": "+238" },
                { "nameEn": "Cayman Islands", "nameFr": "Cayman Islands", "countryCode": "KY", "phoneCode": "+ 345" },
                { "nameEn": "Central African Republic", "nameFr": "République centrafricaine", "countryCode": "CF", "phoneCode": "+236" },
                { "nameEn": "Chad", "nameFr": "Tchad", "countryCode": "TD", "phoneCode": "+235" },
                { "nameEn": "Chile", "nameFr": "Chili", "countryCode": "CL", "phoneCode": "+56" },
                { "nameEn": "China", "nameFr": "Chine", "countryCode": "CN", "phoneCode": "+86" },
                { "nameEn": "Christmas Island", "nameFr": "Christmas Island", "countryCode": "CX", "phoneCode": "+61" },
                { "nameEn": "Cocos (Keeling) Islands", "nameFr": "Cocos (Keeling) Islands", "countryCode": "CC", "phoneCode": "+61" },
                { "nameEn": "Colombia", "nameFr": "Colombie", "countryCode": "CO", "phoneCode": "+57" },
                { "nameEn": "Comoros", "nameFr": "Comores (pays)", "countryCode": "KM", "phoneCode": "+269" },
                { "nameEn": "Congo", "nameFr": "République du Congo", "countryCode": "CG", "phoneCode": "+242" },
                { "nameEn": "Congo, The Democratic Republic of the Congo", "nameFr": "République démocratique du Congo", "countryCode": "CD", "phoneCode": "+243" },
                { "nameEn": "Cook Islands", "nameFr": "Cook Islands", "countryCode": "CK", "phoneCode": "+682" },
                { "nameEn": "Costa Rica", "nameFr": "Costa Rica", "countryCode": "CR", "phoneCode": "+506" },
                { "nameEn": "Cote d'Ivoire", "nameFr": "Côte d'Ivoire", "countryCode": "CI", "phoneCode": "+225" },
                { "nameEn": "Croatia", "nameFr": "Croatie", "countryCode": "HR", "phoneCode": "+385" },
                { "nameEn": "Cuba", "nameFr": "Cuba", "countryCode": "CU", "phoneCode": "+53" },
                { "nameEn": "Cyprus", "nameFr": "Chypre (pays)", "countryCode": "CY", "phoneCode": "+357" },
                { "nameEn": "Czech Republic", "nameFr": "Tchéquie", "countryCode": "CZ", "phoneCode": "+420" },
                { "nameEn": "Denmark", "nameFr": "Danemark", "countryCode": "DK", "phoneCode": "+45" },
                { "nameEn": "Djibouti", "nameFr": "Djibouti", "countryCode": "DJ", "phoneCode": "+253" },
                { "nameEn": "Dominica", "nameFr": "Dominique", "countryCode": "DM", "phoneCode": "+1767" },
                { "nameEn": "Dominican Republic", "nameFr": "République dominicaine", "countryCode": "DO", "phoneCode": "+1849" },
                { "nameEn": "Ecuador", "nameFr": "Équateur (pays)", "countryCode": "EC", "phoneCode": "+593" },
                { "nameEn": "Egypt", "nameFr": "Égypte", "countryCode": "EG", "phoneCode": "+20" },
                { "nameEn": "El Salvador", "nameFr": "Salvador", "countryCode": "SV", "phoneCode": "+503" },
                { "nameEn": "Equatorial Guinea", "nameFr": "Guinée équatoriale", "countryCode": "GQ", "phoneCode": "+240" },
                { "nameEn": "Eritrea", "nameFr": "Érythrée", "countryCode": "ER", "phoneCode": "+291" },
                { "nameEn": "Estonia", "nameFr": "Estonie", "countryCode": "EE", "phoneCode": "+372" },
                { "nameEn": "Ethiopia", "nameFr": "Éthiopie", "countryCode": "ET", "phoneCode": "+251" },
                { "nameEn": "Falkland Islands (Malvinas)", "nameFr": "Falkland Islands (Malvinas)", "countryCode": "FK", "phoneCode": "+500" },
                { "nameEn": "Faroe Islands", "nameFr": "Faroe Islands", "countryCode": "FO", "phoneCode": "+298" },
                { "nameEn": "Fiji", "nameFr": "Fidji", "countryCode": "FJ", "phoneCode": "+679" },
                { "nameEn": "Finland", "nameFr": "Finlande", "countryCode": "FI", "phoneCode": "+358" },
                { "nameEn": "France", "nameFr": "France", "countryCode": "FR", "phoneCode": "+33" },
                { "nameEn": "French Guiana", "nameFr": "French Guiana", "countryCode": "GF", "phoneCode": "+594" },
                { "nameEn": "French Polynesia", "nameFr": "French Polynesia", "countryCode": "PF", "phoneCode": "+689" },
                { "nameEn": "French Southern Territories", "nameFr": "French Southern Territories", "countryCode": "TF", "phoneCode": "+262" },
                { "nameEn": "Gabon", "nameFr": "Gabon", "countryCode": "GA", "phoneCode": "+241" },
                { "nameEn": "Gambia", "nameFr": "Gambie", "countryCode": "GM", "phoneCode": "+220" },
                { "nameEn": "Georgia", "nameFr": "Géorgie (pays)", "countryCode": "GE", "phoneCode": "+995" },
                { "nameEn": "Germany", "nameFr": "Allemagne", "countryCode": "DE", "phoneCode": "+49" },
                { "nameEn": "Ghana", "nameFr": "Ghana", "countryCode": "GH", "phoneCode": "+233" },
                { "nameEn": "Gibraltar", "nameFr": "Gibraltar", "countryCode": "GI", "phoneCode": "+350" },
                { "nameEn": "Greece", "nameFr": "Grèce", "countryCode": "GR", "phoneCode": "+30" },
                { "nameEn": "Greenland", "nameFr": "Greenland", "countryCode": "GL", "phoneCode": "+299" },
                { "nameEn": "Grenada", "nameFr": "Grenade (pays)", "countryCode": "GD", "phoneCode": "+1473" },
                { "nameEn": "Guadeloupe", "nameFr": "Guadeloupe", "countryCode": "GP", "phoneCode": "+590" },
                { "nameEn": "Guam", "nameFr": "Guam", "countryCode": "GU", "phoneCode": "+1671" },
                { "nameEn": "Guatemala", "nameFr": "Guatemala", "countryCode": "GT", "phoneCode": "+502" },
                { "nameEn": "Guernsey", "nameFr": "Guernsey", "countryCode": "GG", "phoneCode": "+44" },
                { "nameEn": "Guinea", "nameFr": "Guinée", "countryCode": "GN", "phoneCode": "+224" },
                { "nameEn": "Guinea-Bissau", "nameFr": "Guinée-Bissau", "countryCode": "GW", "phoneCode": "+245" },
                { "nameEn": "Guyana", "nameFr": "Guyana", "countryCode": "GY", "phoneCode": "+592" },
                { "nameEn": "Haiti", "nameFr": "Haïti", "countryCode": "HT", "phoneCode": "+509" },
                { "nameEn": "Heard Island and Mcdonald Islands", "nameFr": "Heard Island and Mcdonald Islands", "countryCode": "HM", "phoneCode": "+0" },
                { "nameEn": "Holy See (Vatican City State)", "nameFr": "Holy See (Vatican City State)", "countryCode": "VA", "phoneCode": "+379" },
                { "nameEn": "Honduras", "nameFr": "Honduras", "countryCode": "HN", "phoneCode": "+504" },
                { "nameEn": "Hong Kong", "nameFr": "Hong Kong", "countryCode": "HK", "phoneCode": "+852" },
                { "nameEn": "Hungary", "nameFr": "Hongrie", "countryCode": "HU", "phoneCode": "+36" },
                { "nameEn": "Iceland", "nameFr": "Islande", "countryCode": "IS", "phoneCode": "+354" },
                { "nameEn": "India", "nameFr": "Inde", "countryCode": "IN", "phoneCode": "+91" },
                { "nameEn": "Indonesia", "nameFr": "Indonésie", "countryCode": "ID", "phoneCode": "+62" },
                { "nameEn": "Iran, Islamic Republic of Persian Gulf", "nameFr": "Iran", "countryCode": "IR", "phoneCode": "+98" },
                { "nameEn": "Iraq", "nameFr": "Irak", "countryCode": "IQ", "phoneCode": "+964" },
                { "nameEn": "Ireland", "nameFr": "Irlande (pays)", "countryCode": "IE", "phoneCode": "+353" },
                { "nameEn": "Isle of Man", "nameFr": "Isle of Man", "countryCode": "IM", "phoneCode": "+44" },
                { "nameEn": "Israel", "nameFr": "Israël", "countryCode": "IL", "phoneCode": "+972" },
                { "nameEn": "Italy", "nameFr": "Italie", "countryCode": "IT", "phoneCode": "+39" },
                { "nameEn": "Jamaica", "nameFr": "Jamaïque", "countryCode": "JM", "phoneCode": "+1876" },
                { "nameEn": "Japan", "nameFr": "Japon", "countryCode": "JP", "phoneCode": "+81" },
                { "nameEn": "Jersey", "nameFr": "Jersey", "countryCode": "JE", "phoneCode": "+44" },
                { "nameEn": "Jordan", "nameFr": "Jordanie", "countryCode": "JO", "phoneCode": "+962" },
                { "nameEn": "Kazakhstan", "nameFr": "Kazakhstan", "countryCode": "KZ", "phoneCode": "+7" },
                { "nameEn": "Kenya", "nameFr": "Kenya", "countryCode": "KE", "phoneCode": "+254" },
                { "nameEn": "Kiribati", "nameFr": "Kiribati", "countryCode": "KI", "phoneCode": "+686" },
                { "nameEn": "Korea, Democratic People's Republic of Korea", "nameFr": "Corée du Nord", "countryCode": "KP", "phoneCode": "+850" },
                { "nameEn": "Korea, Republic of South Korea", "nameFr": "Corée du Sud", "countryCode": "KR", "phoneCode": "+82" },
                { "nameEn": "Kosovo", "nameFr": "Kosovo", "countryCode": "XK", "phoneCode": "+383" },
                { "nameEn": "Kuwait", "nameFr": "Koweït", "countryCode": "KW", "phoneCode": "+965" },
                { "nameEn": "Kyrgyzstan", "nameFr": "Kirghizistan", "countryCode": "KG", "phoneCode": "+996" },
                { "nameEn": "Laos", "nameFr": "Laos", "countryCode": "LA", "phoneCode": "+856" },
                { "nameEn": "Latvia", "nameFr": "Lettonie", "countryCode": "LV", "phoneCode": "+371" },
                { "nameEn": "Lebanon", "nameFr": "Liban", "countryCode": "LB", "phoneCode": "+961" },
                { "nameEn": "Lesotho", "nameFr": "Lesotho", "countryCode": "LS", "phoneCode": "+266" },
                { "nameEn": "Liberia", "nameFr": "Liberia", "countryCode": "LR", "phoneCode": "+231" },
                { "nameEn": "Libyan Arab Jamahiriya", "nameFr": "Libye", "countryCode": "LY", "phoneCode": "+218" },
                { "nameEn": "Liechtenstein", "nameFr": "Liechtenstein", "countryCode": "LI", "phoneCode": "+423" },
                { "nameEn": "Lithuania", "nameFr": "Lituanie", "countryCode": "LT", "phoneCode": "+370" },
                { "nameEn": "Luxembourg", "nameFr": "Luxembourg (pays)", "countryCode": "LU", "phoneCode": "+352" },
                { "nameEn": "Macao", "nameFr": "Macao", "countryCode": "MO", "phoneCode": "+853" },
                { "nameEn": "Macedonia", "nameFr": "Macédoine du Nord", "countryCode": "MK", "phoneCode": "+389" },
                { "nameEn": "Madagascar", "nameFr": "Madagascar", "countryCode": "MG", "phoneCode": "+261" },
                { "nameEn": "Malawi", "nameFr": "Malawi", "countryCode": "MW", "phoneCode": "+265" },
                { "nameEn": "Malaysia", "nameFr": "Malaisie", "countryCode": "MY", "phoneCode": "+60" },
                { "nameEn": "Maldives", "nameFr": "Maldives", "countryCode": "MV", "phoneCode": "+960" },
                { "nameEn": "Mali", "nameFr": "Mali", "countryCode": "ML", "phoneCode": "+223" },
                { "nameEn": "Malta", "nameFr": "Malte", "countryCode": "MT", "phoneCode": "+356" },
                { "nameEn": "Marshall Islands", "nameFr": "Îles Marshall (pays)", "countryCode": "MH", "phoneCode": "+692" },
                { "nameEn": "Martinique", "nameFr": "Martinique", "countryCode": "MQ", "phoneCode": "+596" },
                { "nameEn": "Mauritania", "nameFr": "Mauritanie", "countryCode": "MR", "phoneCode": "+222" },
                { "nameEn": "Mauritius", "nameFr": "Maurice (pays)", "countryCode": "MU", "phoneCode": "+230" },
                { "nameEn": "Mayotte", "nameFr": "Mayotte", "countryCode": "YT", "phoneCode": "+262" },
                { "nameEn": "Mexico", "nameFr": "Mexique", "countryCode": "MX", "phoneCode": "+52" },
                { "nameEn": "Micronesia, Federated States of Micronesia", "nameFr": "États fédérés de Micronésie (pays)", "countryCode": "FM", "phoneCode": "+691" },
                { "nameEn": "Moldova", "nameFr": "Moldavie", "countryCode": "MD", "phoneCode": "+373" },
                { "nameEn": "Monaco", "nameFr": "Monaco", "countryCode": "MC", "phoneCode": "+377" },
                { "nameEn": "Mongolia", "nameFr": "Mongolie", "countryCode": "MN", "phoneCode": "+976" },
                { "nameEn": "Montenegro", "nameFr": "Monténégro", "countryCode": "ME", "phoneCode": "+382" },
                { "nameEn": "Montserrat", "nameFr": "Montserrat", "countryCode": "MS", "phoneCode": "+1664" },
                { "nameEn": "Morocco", "nameFr": "Maroc", "countryCode": "MA", "phoneCode": "+212" },
                { "nameEn": "Mozambique", "nameFr": "Mozambique", "countryCode": "MZ", "phoneCode": "+258" },
                { "nameEn": "Myanmar", "nameFr": "Birmanie", "countryCode": "MM", "phoneCode": "+95" },
                { "nameEn": "Namibia", "nameFr": "Namibie", "countryCode": "NA", "phoneCode": "+264" },
                { "nameEn": "Nauru", "nameFr": "Nauru", "countryCode": "NR", "phoneCode": "+674" },
                { "nameEn": "Nepal", "nameFr": "Népal", "countryCode": "NP", "phoneCode": "+977" },
                { "nameEn": "Netherlands", "nameFr": "Pays-Bas", "countryCode": "NL", "phoneCode": "+31" },
                { "nameEn": "Netherlands Antilles", "nameFr": "Netherlands Antilles", "countryCode": "AN", "phoneCode": "+599" },
                { "nameEn": "New Caledonia", "nameFr": "New Caledonia", "countryCode": "NC", "phoneCode": "+687" },
                { "nameEn": "New Zealand", "nameFr": "Nouvelle-Zélande", "countryCode": "NZ", "phoneCode": "+64" },
                { "nameEn": "Nicaragua", "nameFr": "Nicaragua", "countryCode": "NI", "phoneCode": "+505" },
                { "nameEn": "Niger", "nameFr": "Niger", "countryCode": "NE", "phoneCode": "+227" },
                { "nameEn": "Nigeria", "nameFr": "Nigeria", "countryCode": "NG", "phoneCode": "+234" },
                { "nameEn": "Niue", "nameFr": "Niue", "countryCode": "NU", "phoneCode": "+683" },
                { "nameEn": "Norfolk Island", "nameFr": "Norfolk Island", "countryCode": "NF", "phoneCode": "+672" },
                { "nameEn": "Northern Mariana Islands", "nameFr": "Northern Mariana Islands", "countryCode": "MP", "phoneCode": "+1670" },
                { "nameEn": "Norway", "nameFr": "Norvège", "countryCode": "NO", "phoneCode": "+47" },
                { "nameEn": "Oman", "nameFr": "Oman", "countryCode": "OM", "phoneCode": "+968" },
                { "nameEn": "Pakistan", "nameFr": "Pakistan", "countryCode": "PK", "phoneCode": "+92" },
                { "nameEn": "Palau", "nameFr": "Palaos", "countryCode": "PW", "phoneCode": "+680" },
                { "nameEn": "Palestinian Territory, Occupied", "nameFr": "Palestinian Territory, Occupied", "countryCode": "PS", "phoneCode": "+970" },
                { "nameEn": "Panama", "nameFr": "Panama", "countryCode": "PA", "phoneCode": "+507" },
                { "nameEn": "Papua New Guinea", "nameFr": "Papouasie-Nouvelle-Guinée", "countryCode": "PG", "phoneCode": "+675" },
                { "nameEn": "Paraguay", "nameFr": "Paraguay", "countryCode": "PY", "phoneCode": "+595" },
                { "nameEn": "Peru", "nameFr": "Pérou", "countryCode": "PE", "phoneCode": "+51" },
                { "nameEn": "Philippines", "nameFr": "Philippines", "countryCode": "PH", "phoneCode": "+63" },
                { "nameEn": "Pitcairn", "nameFr": "Pitcairn", "countryCode": "PN", "phoneCode": "+64" },
                { "nameEn": "Poland", "nameFr": "Pologne", "countryCode": "PL", "phoneCode": "+48" },
                { "nameEn": "Portugal", "nameFr": "Portugal", "countryCode": "PT", "phoneCode": "+351" },
                { "nameEn": "Puerto Rico", "nameFr": "Puerto Rico", "countryCode": "PR", "phoneCode": "+1939" },
                { "nameEn": "Qatar", "nameFr": "Qatar", "countryCode": "QA", "phoneCode": "+974" },
                { "nameEn": "Romania", "nameFr": "Roumanie", "countryCode": "RO", "phoneCode": "+40" },
                { "nameEn": "Russia", "nameFr": "Russie", "countryCode": "RU", "phoneCode": "+7" },
                { "nameEn": "Rwanda", "nameFr": "Rwanda", "countryCode": "RW", "phoneCode": "+250" },
                { "nameEn": "Reunion", "nameFr": "Reunion", "countryCode": "RE", "phoneCode": "+262" },
                { "nameEn": "Saint Barthelemy", "nameFr": "Saint Barthelemy", "countryCode": "BL", "phoneCode": "+590" },
                { "nameEn": "Saint Helena, Ascension and Tristan Da Cunha", "nameFr": "Saint Helena, Ascension and Tristan Da Cunha", "countryCode": "SH", "phoneCode": "+290" },
                { "nameEn": "Saint Kitts and Nevis", "nameFr": "Saint-Christophe-et-Niévès", "countryCode": "KN", "phoneCode": "+1869" },
                { "nameEn": "Saint Lucia", "nameFr": "Sainte-Lucie", "countryCode": "LC", "phoneCode": "+1758" },
                { "nameEn": "Saint Martin", "nameFr": "Saint Martin", "countryCode": "MF", "phoneCode": "+590" },
                { "nameEn": "Saint Pierre and Miquelon", "nameFr": "Saint Pierre and Miquelon", "countryCode": "PM", "phoneCode": "+508" },
                { "nameEn": "Saint Vincent and the Grenadines", "nameFr": "Saint-Vincent-et-les-Grenadines", "countryCode": "VC", "phoneCode": "+1784" },
                { "nameEn": "Samoa", "nameFr": "Samoa", "countryCode": "WS", "phoneCode": "+685" },
                { "nameEn": "San Marino", "nameFr": "Saint-Marin", "countryCode": "SM", "phoneCode": "+378" },
                { "nameEn": "Sao Tome and Principe", "nameFr": "Sao Tomé-et-Principe", "countryCode": "ST", "phoneCode": "+239" },
                { "nameEn": "Saudi Arabia", "nameFr": "Arabie saoudite", "countryCode": "SA", "phoneCode": "+966" },
                { "nameEn": "Senegal", "nameFr": "Sénégal", "countryCode": "SN", "phoneCode": "+221" },
                { "nameEn": "Serbia", "nameFr": "Serbie", "countryCode": "RS", "phoneCode": "+381" },
                { "nameEn": "Seychelles", "nameFr": "Seychelles", "countryCode": "SC", "phoneCode": "+248" },
                { "nameEn": "Sierra Leone", "nameFr": "Sierra Leone", "countryCode": "SL", "phoneCode": "+232" },
                { "nameEn": "Singapore", "nameFr": "Singapour", "countryCode": "SG", "phoneCode": "+65" },
                { "nameEn": "Slovakia", "nameFr": "Slovaquie", "countryCode": "SK", "phoneCode": "+421" },
                { "nameEn": "Slovenia", "nameFr": "Slovénie", "countryCode": "SI", "phoneCode": "+386" },
                { "nameEn": "Solomon Islands", "nameFr": "Salomon", "countryCode": "SB", "phoneCode": "+677" },
                { "nameEn": "Somalia", "nameFr": "Somalie", "countryCode": "SO", "phoneCode": "+252" },
                { "nameEn": "South Africa", "nameFr": "Afrique du Sud", "countryCode": "ZA", "phoneCode": "+27" },
                { "nameEn": "South Sudan", "nameFr": "Soudan du Sud", "countryCode": "SS", "phoneCode": "+211" },
                { "nameEn": "South Georgia and the South Sandwich Islands", "nameFr": "South Georgia and the South Sandwich Islands", "countryCode": "GS", "phoneCode": "+500" },
                { "nameEn": "Spain", "nameFr": "Espagne", "countryCode": "ES", "phoneCode": "+34" },
                { "nameEn": "Sri Lanka", "nameFr": "Sri Lanka", "countryCode": "LK", "phoneCode": "+94" },
                { "nameEn": "Sudan", "nameFr": "Soudan", "countryCode": "SD", "phoneCode": "+249" },
                { "nameEn": "Suriname", "nameFr": "Suriname", "countryCode": "SR", "phoneCode": "+597" },
                { "nameEn": "Svalbard and Jan Mayen", "nameFr": "Svalbard and Jan Mayen", "countryCode": "SJ", "phoneCode": "+47" },
                { "nameEn": "Swaziland", "nameFr": "Swaziland", "countryCode": "SZ", "phoneCode": "+268" },
                { "nameEn": "Sweden", "nameFr": "Suède", "countryCode": "SE", "phoneCode": "+46" },
                { "nameEn": "Switzerland", "nameFr": "Suisse", "countryCode": "CH", "phoneCode": "+41" },
                { "nameEn": "Syrian Arab Republic", "nameFr": "Syrie", "countryCode": "SY", "phoneCode": "+963" },
                { "nameEn": "Taiwan", "nameFr": "Taiwan", "countryCode": "TW", "phoneCode": "+886" },
                { "nameEn": "Tajikistan", "nameFr": "Tadjikistan", "countryCode": "TJ", "phoneCode": "+992" },
                { "nameEn": "Tanzania, United Republic of Tanzania", "nameFr": "Tanzanie", "countryCode": "TZ", "phoneCode": "+255" },
                { "nameEn": "Thailand", "nameFr": "Thaïlande", "countryCode": "TH", "phoneCode": "+66" },
                { "nameEn": "Timor-Leste", "nameFr": "Timor oriental", "countryCode": "TL", "phoneCode": "+670" },
                { "nameEn": "Togo", "nameFr": "Togo", "countryCode": "TG", "phoneCode": "+228" },
                { "nameEn": "Tokelau", "nameFr": "Tokelau", "countryCode": "TK", "phoneCode": "+690" },
                { "nameEn": "Tonga", "nameFr": "Tonga", "countryCode": "TO", "phoneCode": "+676" },
                { "nameEn": "Trinidad and Tobago", "nameFr": "Trinité-et-Tobago", "countryCode": "TT", "phoneCode": "+1868" },
                { "nameEn": "Tunisia", "nameFr": "Tunisie", "countryCode": "TN", "phoneCode": "+216" },
                { "nameEn": "Turkey", "nameFr": "Turquie", "countryCode": "TR", "phoneCode": "+90" },
                { "nameEn": "Turkmenistan", "nameFr": "Turkménistan", "countryCode": "TM", "phoneCode": "+993" },
                { "nameEn": "Turks and Caicos Islands", "nameFr": "Turks and Caicos Islands", "countryCode": "TC", "phoneCode": "+1649" },
                { "nameEn": "Tuvalu", "nameFr": "Tuvalu", "countryCode": "TV", "phoneCode": "+688" },
                { "nameEn": "Uganda", "nameFr": "Ouganda", "countryCode": "UG", "phoneCode": "+256" },
                { "nameEn": "Ukraine", "nameFr": "Ukraine", "countryCode": "UA", "phoneCode": "+380" },
                { "nameEn": "United Arab Emirates", "nameFr": "Émirats arabes unis", "countryCode": "AE", "phoneCode": "+971" },
                { "nameEn": "United Kingdom", "nameFr": "Royaume-Uni", "countryCode": "GB", "phoneCode": "+44" },
                { "nameEn": "United States", "nameFr": "États-Unis", "countryCode": "US", "phoneCode": "+1" },
                { "nameEn": "Uruguay", "nameFr": "Uruguay", "countryCode": "UY", "phoneCode": "+598" },
                { "nameEn": "Uzbekistan", "nameFr": "Ouzbékistan", "countryCode": "UZ", "phoneCode": "+998" },
                { "nameEn": "Vanuatu", "nameFr": "Vanuatu", "countryCode": "VU", "phoneCode": "+678" },
                { "nameEn": "Venezuela, Bolivarian Republic of Venezuela", "nameFr": "Venezuela", "countryCode": "VE", "phoneCode": "+58" },
                { "nameEn": "Vietnam", "nameFr": "Viêt Nam", "countryCode": "VN", "phoneCode": "+84" },
                { "nameEn": "Virgin Islands, British", "nameFr": "Virgin Islands, British", "countryCode": "VG", "phoneCode": "+1284" },
                { "nameEn": "Virgin Islands, U.S.", "nameFr": "Virgin Islands, U.S.", "countryCode": "VI", "phoneCode": "+1340" },
                { "nameEn": "Wallis and Futuna", "nameFr": "Wallis and Futuna", "countryCode": "WF", "phoneCode": "+681" },
                { "nameEn": "Yemen", "nameFr": "Yémen", "countryCode": "YE", "phoneCode": "+967" },
                { "nameEn": "Zambia", "nameFr": "Zambie", "countryCode": "ZM", "phoneCode": "+260" },
                { "nameEn": "Zimbabwe", "nameFr": "Zimbabwe", "countryCode": "ZW", "phoneCode": "+263" }
            ];
        },
        enumerable: true,
        configurable: true
    });
    return Constants;
}());
export { Constants };
