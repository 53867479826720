import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { ChargeToAccountLabelStoreService } from '../../services/charge-to-account-label-store.service';
import { Constants } from 'src/app/modules/wrcommon/classes/constants';
var BreadcrumbComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BreadcrumbComponent, _super);
    function BreadcrumbComponent(router, route, chargeToAccountLabelStoreService) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.route = route;
        _this.chargeToAccountLabelStoreService = chargeToAccountLabelStoreService;
        _this.breadcrumbs = [];
        return _this;
    }
    BreadcrumbComponent.prototype.ngOnInit = function () {
        var _this = this;
        var breadcrumb = {
            label: 'SideNav.Home',
            url: ''
        };
        this.chargeToAccountLabelStoreService.state$
            .pipe(takeUntil(this.destroyed))
            .subscribe(function (labelValue) {
            if (!!labelValue) {
                _this.breadcrumbs
                    .filter(function (breadcrumb) { return breadcrumb.label === Constants.ChargeToAccountTitle; })
                    .forEach(function (breadcrumb) {
                    breadcrumb.labelKeyword = Constants.ChargeToAccountKeyword;
                    breadcrumb.labelValue = labelValue;
                });
            }
        });
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), takeUntil(this.destroyed))
            .subscribe(function (event) {
            //set breadcrumbs
            var root = _this.route.root;
            _this.breadcrumbs = _this.getBreadcrumbs(root);
            //this.breadcrumbs = [breadcrumb, ...this.breadcrumbs]
        });
    };
    BreadcrumbComponent.prototype.getBreadcrumbs = function (route, url, breadcrumbs) {
        if (url === void 0) { url = ""; }
        if (breadcrumbs === void 0) { breadcrumbs = []; }
        var ROUTE_DATA_BREADCRUMB = "breadcrumb";
        //get the child routes
        var children = route.children;
        //return if there are no more children
        if (children.length === 0) {
            return breadcrumbs;
        }
        //iterate over each children
        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
            var child = children_1[_i];
            //verify primary route
            if (child.outlet !== PRIMARY_OUTLET) {
                continue;
            }
            //get the route's URL segment
            var routeURL = child.snapshot.url.map(function (segment) { return segment.path; }).join("/");
            //append route URL to URL
            url += "/" + routeURL;
            //verify the custom data property "breadcrumb" is specified on the route
            if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
                return this.getBreadcrumbs(child, url, breadcrumbs);
            }
            //add breadcrumb
            var breadcrumb = {
                label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
                url: url
            };
            if (breadcrumbs.length > 0 && breadcrumbs[breadcrumbs.length - 1].label === breadcrumb.label) {
                breadcrumbs.pop();
            }
            breadcrumbs.push(breadcrumb);
            //recursive
            return this.getBreadcrumbs(child, url, breadcrumbs);
        }
        return breadcrumbs;
    };
    BreadcrumbComponent.prototype.trackFn = function (index, item) {
        var uniqueCode = item.label + "-" + item.labelValue;
        return uniqueCode;
    };
    return BreadcrumbComponent;
}(DestroyableComponentBase));
export { BreadcrumbComponent };
