import { Component, Renderer2, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { LoaderService } from 'src/app/modules/core/services/loader.service';
import { TenantService } from 'src/app/modules/core/services/tenant.service';
import { UserdatasharingService } from 'src/app/modules/core/services/userdatasharing.service';
import { Constants } from 'src/app/modules/wrcommon/classes/constants';
import { TranslationService } from 'src/app/modules/wrcommon/services/translation.service';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { Language } from 'src/app/modules/wrcommon/classes/language';
import { LocalstorageService } from 'src/app/modules/core/services/localstorage.service';
import { WebRequestConfig } from 'src/app/modules/wrcommon/classes/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends DestroyableComponentBase implements OnInit {

  title = Constants.WebAssist;
  isLoading: boolean = false;

  constructor(private loaderService: LoaderService,
    private tenantService: TenantService,
    private userService: UserdatasharingService,
    private renderer: Renderer2,
    private translationService: TranslationService,
    private userIdle: UserIdleService,
    private storageService: LocalstorageService) {

    super();

    // used for all other routes with racing support
    this.loaderService.state$
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this.destroyed))
      .subscribe((v) => {
      this.isLoading = v;
    });

    this.userService.currentLanguage
      .pipe(takeUntil(this.destroyed))
      .subscribe(lang => {
        const locale = Language.SupportedLanguages.getKey(lang);
        this.renderer.setAttribute(document.querySelector('html'), 'lang', locale);
        this.translationService.setCurrentLanguage(locale);
      });

    this.tenantService.state$
      .pipe(takeUntil(this.destroyed))
      .subscribe(tenant => {
        const theme = 'theme-' + (tenant ? tenant.Theme : 'bgis');
        const regex = /^theme-([a-zA-Z])+$/;
        const classes = Array.from(document.querySelector('body').classList);   // get all classes
        classes.forEach((cl) => {
          if (cl.match(regex)) {
            this.renderer.removeClass(document.querySelector('body'), cl);
          }
        });

        this.renderer.addClass(document.querySelector('body'), theme);
        const isHighContrast = this.storageService.getIsHighContrast();
        if (isHighContrast) {
          this.renderer.addClass(document.querySelector('body'), 'theme-hq');
        } else {
          this.renderer.removeClass(document.querySelector('body'), 'theme-hq');
        }
      });
  }

  ngOnInit() {
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watching when user idle is starting and reset if user action is there.
    this.userIdle.onTimerStart().subscribe(count => {
      var eventList = ['click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'keyup'];
      for (let event of eventList) {
        document.body.addEventListener(event, () => this.userIdle.resetTimer());
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(async () => {
      // check if a user is authenticated
      if (!!this.storageService.getAccount()) {
        alert(await this.translationService.translate('session.Timeout'));
        window.location.href = `${WebRequestConfig.baseUrlRealSuite}User/logout?redirectUrl=${WebRequestConfig.baseUrlWebAssist}login?logout=true`;
      } else {
        this.userIdle.resetTimer();
      }
    })
  }
}
