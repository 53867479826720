import { Injectable } from '@angular/core';
import { AccountModel } from '../models/accountmodel';
import { Constants } from '../../wrcommon/classes/constants';
import { Language } from '../../wrcommon/classes/language';
import { Tenant } from './tenant.service';
import SecureLS from 'secure-ls';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  private secureLS = new SecureLS();

  constructor() {
  }

  //Add/Get/Remove account data (model) to/from sessionStorage
  public addAccount(obj: any) {
    this.saveSessionVariable(Constants.AccountStorageKey, obj);
  }
  public getAccount(): AccountModel {
    return this.getSessionVariable<AccountModel>(Constants.AccountStorageKey);
  }
  public deleteAccount() {
    this.deleteSessionVariable(Constants.AccountStorageKey);
  }

  //Add/Get/Remove user name data to/from localStorage
  public addUserName(obj: string) {
    this.saveLocalVariable(Constants.UserNameStorageKey, obj);
  }
  public getUserName(): string {
    return this.getLocalVariable<string>(Constants.UserNameStorageKey);
  }
  public deleteUserName() {
    this.deleteLocalVariable(Constants.AccountStorageKey);
  }


  //Add/Get/Remove contrast info to/from sessionStorage
  public setIsHighContrast(isHighContrast: boolean) {
    this.saveSessionVariable(Constants.IsHighContrastStorageKey, isHighContrast);
  }
  public getIsHighContrast(): boolean {
    let isHighContrast: boolean | null = this.getSessionVariable<boolean>(Constants.IsHighContrastStorageKey);
    return isHighContrast == null ? false: isHighContrast;
  }
  public deleteIsHighContrast() {
    this.deleteSessionVariable(Constants.IsHighContrastStorageKey);
  }

  //Add/Get/Remove tenant to/from sessionStorage
  public setTenant(tenant: Tenant) {
    this.saveSecureVariable(Constants.TenantKey, tenant);
  }
  public getTenant(): Tenant {
    return this.getSecureVariable<Tenant>(Constants.TenantKey);
  }
  public deleteTenantUser() {
    const tenant = this.getTenant();
    if (tenant) {
      delete tenant.LoginName;
      delete tenant.UserId;
      delete tenant.IsUserRegistered;
      delete tenant.Email;
      this.setTenant(tenant);
    }
  }

  // set translation
  public setTranslation(translationKey: string, translationValue: string) {
    this.saveSessionVariable(translationKey, translationValue);
  }
  public getTranslation(translationKey: string) : string | null {
    return this.getSessionVariable<string | null>(translationKey);
  }
  public setCurrentLanguage(currentLanguage: string) {
    this.saveLocalVariable(Language.CurrentLanguageStorageKey, currentLanguage);
  }
  public getCurrentLanguage(): string | null {
    return this.getLocalVariable<string | null>(Language.CurrentLanguageStorageKey);
  }
  public setTranslationIsLoaded(isTranslationLoaded: boolean) {
    this.saveSessionVariable(Language.TranslationLoadedStorageKey, isTranslationLoaded);
  }
  public getTranslationIsLoaded(): boolean {
    let isTranslationLoaded: boolean | null = this.getSessionVariable<boolean>(Language.TranslationLoadedStorageKey);
    return isTranslationLoaded == null ? false : isTranslationLoaded;
  }

  private saveLocalVariable(key: string, obj: any) {
    localStorage.setItem(key, JSON.stringify(obj));
  }
  private getLocalVariable<T>(key: string) {
    var item = localStorage.getItem(key);
    return item ? <T>JSON.parse(item) : null;
  }
  private deleteLocalVariable(key: string) {
    localStorage.removeItem(key);
  }

  private saveSessionVariable(key: string, obj: any) {
    sessionStorage.setItem(key, JSON.stringify(obj));
  }
  private getSessionVariable<T>(key: string) {
    var item = sessionStorage.getItem(key);
    return item ? <T>JSON.parse(item) : null;
  }
  private deleteSessionVariable(key: string) {
    sessionStorage.removeItem(key);
  }

  private saveSecureVariable(key: string, obj: any) {
    this.secureLS.set(key, JSON.stringify(obj));
  }
  private getSecureVariable<T>(key: string) {
    var item = this.secureLS.get(key);
    return item ? <T>JSON.parse(item) : null;
  }
  private deleteSecureVariable(key: string) {
    this.secureLS.remove(key);
  }
}
