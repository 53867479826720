import { BehaviorSubject } from 'rxjs';
import { Language } from '../../wrcommon/classes/language';
import * as i0 from "@angular/core";
var RegistrationDataSharingService = /** @class */ (function () {
    function RegistrationDataSharingService() {
        this.INITIAL_VALUE = {
            userId: -1,
            clientId: -1,
            clientCode: '',
            firstName: '',
            lastName: '',
            userName: '',
            email: '',
            phoneNumber: '',
            phoneNumberCountryCode: '',
            password: '',
            confirmPassword: '',
            RetCode: null,
            ErrMsg: null,
            RememberMe: false,
            language: Language.English,
            requestReceivedNotification: false,
            requestAssignedNotification: false,
            arrivedNotification: false,
            inProgressNotification: false,
            completeNotification: false,
            hasWorkOrders: null,
            AccessToken: null,
            IsFederated: null
        };
        this.subject = new BehaviorSubject(this.INITIAL_VALUE);
    }
    // set the service data
    RegistrationDataSharingService.prototype.addData = function (data) {
        if (data == null) {
            throw new Error("Invalid data: data is null.");
        }
        this.subject.next(data);
    };
    // re-set the service data to its initial state
    RegistrationDataSharingService.prototype.clearMessages = function () {
        this.subject.next({
            userId: -1,
            clientId: -1,
            clientCode: '',
            firstName: '',
            lastName: '',
            userName: '',
            email: '',
            phoneNumber: '',
            phoneNumberCountryCode: '',
            password: '',
            confirmPassword: '',
            RetCode: null,
            ErrMsg: null,
            RememberMe: false,
            language: Language.English,
            requestReceivedNotification: false,
            requestAssignedNotification: false,
            arrivedNotification: false,
            inProgressNotification: false,
            completeNotification: false,
            AccessToken: null,
            IsFederated: null
        });
    };
    // return the data as an observable
    RegistrationDataSharingService.prototype.getData = function () {
        return this.subject.asObservable();
    };
    RegistrationDataSharingService.ngInjectableDef = i0.defineInjectable({ factory: function RegistrationDataSharingService_Factory() { return new RegistrationDataSharingService(); }, token: RegistrationDataSharingService, providedIn: "root" });
    return RegistrationDataSharingService;
}());
export { RegistrationDataSharingService };
