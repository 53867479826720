import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { Subject } from 'rxjs';
import { TranslationService } from '../services/translation.service';
import { UserdatasharingService } from '../../core/services/userdatasharing.service';
var PaginatorLocale = /** @class */ (function (_super) {
    tslib_1.__extends(PaginatorLocale, _super);
    function PaginatorLocale(userService, translationService) {
        var _this = _super.call(this) || this;
        _this.userService = userService;
        _this.translationService = translationService;
        _this.destroyed = new Subject();
        _this.OF_LABEL = 'of';
        _this.getRangeLabel = function (page, pageSize, length) {
            if (length === 0 || pageSize === 0) {
                return "0 " + _this.OF_LABEL + " " + length;
            }
            length = Math.max(length, 0);
            var startIndex = page * pageSize;
            var endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return startIndex + 1 + " - " + endIndex + " " + _this.OF_LABEL + " " + length;
        };
        //this.userService.currentLanguage
        //  .pipe(takeUntil(this.destroyed))
        //  .subscribe(() => {
        //    this.initTranslations();
        //  });
        _this.initTranslations();
        return _this;
    }
    PaginatorLocale.prototype.ngOnDestroy = function () {
        this.destroyed.next();
        this.destroyed.complete();
    };
    PaginatorLocale.prototype.initTranslations = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f;
            return tslib_1.__generator(this, function (_g) {
                switch (_g.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.translationService.translate('paginator.ItemsPerPage')];
                    case 1:
                        _a.itemsPerPageLabel = _g.sent();
                        _b = this;
                        return [4 /*yield*/, this.translationService.translate('paginator.NextPage')];
                    case 2:
                        _b.nextPageLabel = _g.sent();
                        _c = this;
                        return [4 /*yield*/, this.translationService.translate('paginator.PreviousPage')];
                    case 3:
                        _c.previousPageLabel = _g.sent();
                        _d = this;
                        return [4 /*yield*/, this.translationService.translate('paginator.FirstPage')];
                    case 4:
                        _d.firstPageLabel = _g.sent();
                        _e = this;
                        return [4 /*yield*/, this.translationService.translate('paginator.LastPage')];
                    case 5:
                        _e.lastPageLabel = _g.sent();
                        _f = this;
                        return [4 /*yield*/, this.translationService.translate('paginator.Of')];
                    case 6:
                        _f.OF_LABEL = _g.sent();
                        this.changes.next();
                        return [2 /*return*/];
                }
            });
        });
    };
    return PaginatorLocale;
}(MatPaginatorIntl));
export { PaginatorLocale };
