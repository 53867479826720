import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AccountModel } from "src/app/modules/core/models/accountmodel";
import { FullLoginModel } from "../models/loginmodel";
import { WebRequestConfig } from "../../wrcommon/classes/config";
import { UserUpdateModel } from '../models/userupdatepmodel';
import { ChangePasswordModel } from '../models/changepasswordmodel';
import { WebAssistHttpHeaders } from '../../wrcommon/classes/httpHeaders';
import { UnlockWithTokenModel } from '../../unlockreset/models/unlockWithTokenModel';
import { NeedHelpModel, LoginModel } from '../../unlockreset/models/needhelpmodel';
import { ProgressNoteSortOrderRequestModel } from "../models/progressnotesortorderrequestmodel";
import { ProgressNoteSortOrderModel } from "../models/progressnotesortordermodel";

export interface UserAccount {
  UserId: number;
  ClientId: number;
  ClientCode: string;
  FirstName: string;
  LastName: string;
  UserName: string;
  Email: string;
  PhoneNumber: string;
  PhoneNumberCountryCode?: string;
  Password: string;
  ConfirmPassword: string;
  RememberMe: boolean;

  RetCode: number | null;
  ErrMsg: string | null;

  Language: string;

  CallerId?: number;
  RequestReceivedNotification: boolean;
  RequestAssignedNotification: boolean;
  ArrivedNotification: boolean;
  InProgressNotification: boolean;
  CompleteNotification: boolean;
  LocationId?: number | null;

  AccessToken: string;

  IsUserRegistered?: boolean;
}

@Injectable({
  providedIn: "root"
})
export class AccountService {

  constructor(private http: HttpClient) {
  }

  getUserAccount(userName: string, userId: number): Observable<UserAccount> {
    return this.http.get<UserAccount>(`${WebRequestConfig.baseUrlUserAccount}account/GetPhoneNumberAndEmail/${userName}/${userId}`);
  }

  // calls the account/update API and returns a boolean indicating if the operation has succeeded
  updateUserAccount(accountModel: UserUpdateModel): Observable<boolean> {
    return this.http.post<boolean>(
      WebRequestConfig.baseUrlUserAccount + "account/update",
      accountModel
    );
  }

  // calls the account/update API and returns the new account information
  changePassword(model: ChangePasswordModel): Observable<AccountModel> {
    return this.http.post<AccountModel>(
      WebRequestConfig.baseUrlUserAccount + "account/changePassword",
      model,
      WebAssistHttpHeaders.HttpOptions
    );
  }

  getClientCode(rsClientId: number): Observable<string> {
    return this.http.get<string>(`${WebRequestConfig.baseUrlClientRequest}public/getClientCode/${rsClientId}`);
  }

  addAccount(loginModel: AccountModel): Observable<FullLoginModel> {
    return this.http.post<FullLoginModel>(
      WebRequestConfig.baseUrlUserAccount + "public/register",
      loginModel
    );
  }
  bellValidateIfCallerAccountExists(loginModel: AccountModel): Observable<boolean> {
    return this.http.post<boolean>(
      WebRequestConfig.baseUrlUserAccount + "public/validateBellUserBeforeRegistration",
      loginModel
    );
  }
  // resets the temporary password
  resetTemporaryPassword(model: ChangePasswordModel): Observable<AccountModel> {
    console.log(`resetTemporaryPassword`);
    return this.http.post<AccountModel>(
      WebRequestConfig.baseUrlUserAccount + "public/resetTemporaryPassword",
      model,
      WebAssistHttpHeaders.HttpOptions
    );
  }

  helpSignIn(needHelpModel: NeedHelpModel, isAccountUnlock: boolean = true): Observable<LoginModel> {
    return isAccountUnlock ? this.unlockAccount(needHelpModel) : this.resetPassword(needHelpModel);
  }

  resetPassword(needHelpModel: NeedHelpModel): Observable<LoginModel> {
    return this.http.post<LoginModel>(
      WebRequestConfig.baseUrlUserAccount + "public/resetPassword",
      needHelpModel,
      WebAssistHttpHeaders.HttpOptions
    );
  }

  unlockAccount(needHelpModel: NeedHelpModel): Observable<LoginModel> {
    return this.http.post<LoginModel>(
      WebRequestConfig.baseUrlUserAccount + "public/unlockAccount",
      needHelpModel,
      WebAssistHttpHeaders.HttpOptions
    );
  }

  unlockWithToken(model: UnlockWithTokenModel): Observable<LoginModel> {
    return this.http.post<LoginModel>(
      WebRequestConfig.baseUrlUserAccount + "public/unlockWithToken",
      model,
      WebAssistHttpHeaders.HttpOptions
    );
  }

  isProgressNoteSortOrderAscending(model: ProgressNoteSortOrderRequestModel): Observable<boolean> {
    return this.http.post<boolean>(
      WebRequestConfig.baseUrlUserAccount + "public/isProgressNoteSortOrderAscending",
      model,
      WebAssistHttpHeaders.HttpOptions
    );
  }

  saveProgressNoteSortOrderConfigForUser(model: ProgressNoteSortOrderModel): Observable<ProgressNoteSortOrderModel> {
    return this.http.post<ProgressNoteSortOrderModel>(
      WebRequestConfig.baseUrlUserAccount + "public/saveProgressNoteSortOrderConfigForUser",
      model,
      WebAssistHttpHeaders.HttpOptions
    );
  }
}

