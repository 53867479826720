import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkOrderModelDTO } from '../models/workOrderModel';
import { WebRequestConfig } from '../../wrcommon/classes/config';
import { FullWorkOrderModel } from '../models/fullWorkOrderModel';
import { CreateWorkOrderResponseModel } from '../models/createWorkOrderResponseModel';
import { WorkOrderRequestModel } from '../models/work-order-request.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Constants } from '../../wrcommon/classes/constants';
import { ProgressNoteListEntity } from '../models/progressNoteModel';
import { ProgressNoteRequestModel } from '../models/ProgressNoteRequestModel';
import { ModuleOption } from '../models/ModuleOptions';
import { FollowUpModel } from '../models/FollowUpModel';


@Injectable({
  providedIn: 'root'
})
export class WorkOrderRequestService {

  deviceInfo = null;
  workOrderSource: string = Constants.WebAssistWeb;

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  )
  {
    this.checkIsMobile();
  }

  checkIsMobile() {
    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile: boolean = this.deviceService.isMobile(); // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    const isTablet: boolean = this.deviceService.isTablet();   // returns if the device us a tablet (iPad etc)
    if (isMobile || isTablet) {
      this.workOrderSource = Constants.WebAssistMobile;
    }
  }

  HasActiveWorkOrders(userId: number): Observable<boolean> {
    return this.http.get<boolean>(WebRequestConfig.baseUrlClientRequest + 'workorder/hasactiveworkorders/' + userId.toString());
  };

  getActiveWorkOrderList(userId: number): Observable<WorkOrderModelDTO> {
    return this.http.post<WorkOrderModelDTO>(WebRequestConfig.baseUrlClientRequest + 'workorder/getactivelist/' + userId.toString(), null)
  };

  getArchiveWorkOrderList(userId: number): Observable<WorkOrderModelDTO> {
    return this.http.post<WorkOrderModelDTO>(WebRequestConfig.baseUrlClientRequest + 'workorder/getarchivelist/' + userId.toString(), null)
  };


  getFullWorkorderRequest(clientId: number, workOrderNumber: string, needToDuplicate: boolean = false): Observable<FullWorkOrderModel> {
    const url: string = `${WebRequestConfig.baseUrlClientRequest}/workorder/getfullworkorderbyworkordernum/${clientId}/${workOrderNumber}/${needToDuplicate}`;
    return this.http.get<FullWorkOrderModel>(url);
  };

  archiveWorkOrder(userId: number, workOrderNumber: string): Observable<boolean> {
    return this.http.post<boolean>(`${WebRequestConfig.baseUrlClientRequest}/workorder/archive/${userId.toString()}/${workOrderNumber}`, null);
  };

  saveWorkOrder(request: WorkOrderRequestModel): Observable<CreateWorkOrderResponseModel> {
    request.workOrderSource = this.workOrderSource;
    return this.http.post<CreateWorkOrderResponseModel>(`${WebRequestConfig.baseUrlClientRequest}workOrder/create/`, request);
  }

  getAPIVersionNumber(clientId: number): Observable<number>{
    return this.http.post<number>(`${WebRequestConfig.baseUrlClientRequest}client/getversionnumber/${clientId.toString()}`, null);
  }

  getProgressNotes(request: ProgressNoteRequestModel): Observable<ProgressNoteListEntity>{
    return this.http.post<ProgressNoteListEntity>(`${WebRequestConfig.baseUrlClientRequest}workorder/getprogressnotes/`, request);
  }

  getReceiveProgressNoteFlag(clientId: number): Observable<boolean>{
    return this.http.get<boolean>(`${WebRequestConfig.baseUrlClientRequest}workorder/getreceiveprogressnoteflag/${clientId}`);
  }

  shouldDisplayProgressNotes(clientId: number, serviceRequestNumber: string): Observable<boolean> {
    return this.http.get<boolean>(`${WebRequestConfig.baseUrlClientRequest}workorder/shouldDisplayProgressNotes/${clientId}/${serviceRequestNumber}`);
  }

  getModuleOptions(clientId: number): Observable<ModuleOption> {
    return this.http.post<ModuleOption>(`${WebRequestConfig.baseUrlClientRequest}moduleoption/get/${clientId}`, clientId);
  }

  saveFollowUp(followUpModel: FollowUpModel): Observable<boolean> {
    return this.http.post<boolean>(`${WebRequestConfig.baseUrlWebApi}servicerequest/savefollowupnote/`, followUpModel);
  }
}
