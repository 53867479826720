import { BiMap } from './bi-map';

export class Language {


  constructor() {
  }  

  public static get English(): string { return 'ENGLISH'; }
  public static get French(): string { return 'FRENCH'; }
  public static get German(): string { return 'GERMAN'; }
  public static get Spanish(): string { return 'SPANISH'; }
  public static get Italian(): string { return 'ITALIAN'; }
  public static get Japanese(): string { return 'JAPANESE'; }
  public static get Russian(): string { return 'RUSSIAN'; }
  public static get Portuguese(): string { return 'PORTUGUESE'; }

  public static get TranslationLanguageCanada(): string { return 'ca'; }
  public static get TranslationLanguageEnglish(): string { return 'en'; }
  public static get TranslationLanguageFrench(): string { return 'fr'; }
  public static get TranslationLanguageGerman(): string { return 'de'; }
  public static get TranslationLanguageSpanish(): string { return 'es'; }
  public static get TranslationLanguageItalian(): string { return 'it'; }
  public static get TranslationLanguageJapanese(): string { return 'ja'; }
  public static get TranslationLanguageRussian(): string { return 'ru'; }
  public static get TranslationLanguagePortuguese(): string { return 'pt'; }



  public static get SupportedLanguages(): BiMap<string, string> {
    return new BiMap([
      [this.TranslationLanguageEnglish, this.English],
      [this.TranslationLanguageFrench, this.French],
      [this.TranslationLanguageGerman, this.German],
      [this.TranslationLanguageSpanish, this.Spanish],
      [this.TranslationLanguageItalian, this.Italian],
      [this.TranslationLanguageJapanese, this.Japanese],
      [this.TranslationLanguageRussian, this.Russian],
      [this.TranslationLanguagePortuguese, this.Portuguese]
    ]);
  }


  public static get TranslationLoadedStorageKey(): string
  {
    return 'TRANS_LOADED';
  }
  public static get CurrentLanguageStorageKey(): string
  {
    return 'CURRENT_LANG';
  }


  public static get LangQueryString(): string { return 'lang'; }


  // falback to English if the browser language is not supported
  // Extract the culture root, this is the substring precending the dash (i.e. en-CA) or the navigator language (i.e. ru).
  public static getLanguageFromBrowser(): string {

    let result: string = Language.English;
    if (!!navigator.language) {
      result = navigator.language.toLowerCase();
      if (result.indexOf('-') !== -1) {
        result = result.split('-')[0];
      }
      result = Language.SupportedLanguages.hasKey(result) ? Language.SupportedLanguages.getValue(result) : Language.English;
    }

    return result;
  }


}
