import { Injectable } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Store } from 'src/app/modules/core/models/store';
import { UserdatasharingService } from 'src/app/modules/core/services/userdatasharing.service';
import { ChargeToAccountLabelStoreService } from 'src/app/modules/core/services/charge-to-account-label-store.service';
import { LocationModel } from 'src/app/modules/shared-location/models/locationModel';
import { WorkOrderRequestModel, AdditionalInfo } from '../models/work-order-request.model';
import { RequestTypeModel } from '../models/RequestCategoryResultModel';
import { ChargeToAccountModel } from '../models/ChargeToAccountModel';
import { FullWorkOrderModel } from '../models/fullWorkOrderModel';
import { QuestionModel } from '../models/questionModel';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderRequestStoreService extends Store<WorkOrderRequestModel> {

  constructor(private userService: UserdatasharingService,
              private chargeToAccountLabelStoreService: ChargeToAccountLabelStoreService) {
    super(
      new WorkOrderRequestModel(
      userService.getClientId(),
      userService.getUserId(),
      userService.getCallerId()
    ));
  }

  reset() {
    this.setState(new WorkOrderRequestModel(
      this.userService.getClientId(),
      this.userService.getUserId(),
      this.userService.getCallerId()
    ));
  }

  setLocation(location: LocationModel): boolean {
    // is the locatonId different than the store?
    if (location != null && ( this.state.locationId == null || this.state.locationId != location.LocationId ) )
    {
      const state: WorkOrderRequestModel = Object.assign(this.state,
        {
          _clientId: this.userService.getClientId(),
          _userId: this.userService.getUserId(),
          _callerId: this.userService.getCallerId(),

          _locationId: location.LocationId,
          _locationType: location.LocationType,
          _locationDescription: location.DisplayText,
          _operationalCode: location.OperationalCode,
          _operationalCodeLabel: location.OperationalCodeLabel,
          _floorId: null,
          _selectedFloorText: null,
          _workspaceId: null,
          _selectedWorkspaceText: null,
          _insideFlag: null,
          _requestTypeId: null,
          _selectedRequestTypeText: null,
          _accountId: null,
          _chargeToAccountLabel: null,
          _selectedAccountText: null,
          _questions: [],
          _questionsAndAnswers: [],
          _suppressedEmergencyKeywords: []
        });

      this.setState(state);

      return true;
    }

    return false;
  }

  setInsideFlag(insideFlag: boolean | null ): boolean {

    if (
        // is the insideFlag different than the store?
        this.state.insideFlag == null && insideFlag != null ||
        this.state.insideFlag != null && insideFlag == null ||
        this.state.insideFlag != null && insideFlag != null && this.state.insideFlag != insideFlag
      )
    {
      const state: WorkOrderRequestModel = Object.assign(this.state,
        {
          _insideFlag: insideFlag,
          _floorId: null,
          _selectedFloorText: null,
          _workspaceId: null,
          _selectedWorkspaceText: null,
          _requestTypeId: null,
          _selectedRequestTypeText: null,
          _accountId: null,
          _chargeToAccountLabel: null,
          _selectedAccountText: null,
          _questions: [],
          _questionsAndAnswers: []
        });

      this.setState(state);

      return true;
    }

    return false;
  }

  setFloorArea(
    floorId: number | null,
    floorText: string | null,
    workspaceId: number | null = null,
    workspaceText: string | null = null
  ): boolean {

    if (
        // is the floor different than the store?
        this.state.floorId == null && floorId != null ||
        this.state.floorId != null && floorId == null ||
        this.state.floorId != null && floorId != null && this.state.floorId != floorId ||

        // is the workspace different than the store?
        this.state.workspaceId == null && workspaceId != null ||
        this.state.workspaceId != null && workspaceId == null ||
        this.state.workspaceId != null && workspaceId != null && this.state.workspaceId != workspaceId
      ) {
      const state: WorkOrderRequestModel = Object.assign(this.state,
        {
          _floorId: floorId,
          _selectedFloorText: floorText,
          _workspaceId: workspaceId,
          _selectedWorkspaceText: workspaceText,
          _requestTypeId: null,
          _selectedRequestTypeText: null,
          _accountId: null,
          _chargeToAccountLabel: null,
          _selectedAccountText: null,
          _questions: [],
          _questionsAndAnswers: []
        });

      this.setState(state);

      return true;
    }

    return false;
  }

  setRequestType(requestType: RequestTypeModel): boolean {

    // is the RequestTypeId different than the store?
    if (
        requestType != null &&
        (
          this.state.requestTypeId == null && requestType.RequestTypeId != null ||
          this.state.requestTypeId != null && requestType.RequestTypeId == null ||
          this.state.requestTypeId != null && requestType.RequestTypeId != null && this.state.requestTypeId != requestType.RequestTypeId
        )
      ) {
        const state: WorkOrderRequestModel = Object.assign(this.state,
          {
            _requestTypeId: requestType.RequestTypeId,
            _selectedRequestTypeText: requestType.DisplayText,
            _accountId: null,
            _chargeToAccountLabel: null,
            _selectedAccountText: null,
            _questions: [],
            _questionsAndAnswers: []
          });

      this.setState(state);

      return true;
    }

    return false;
  }

  setRequestDetails(
    description: string,
    additionalInfo: Array<AdditionalInfo>,
    questionsAndAnswers: Array<QuestionModel>,
    uploader: FileUploader,
    isConfirmationPage: boolean = false
  ): boolean {

    let result: boolean = false;
    let state: WorkOrderRequestModel = null;
    if (
      // is the description different than the store?
      this.state.description == null && description != null ||
      this.state.description != null && description == null ||
      this.state.description != null && description != null && this.state.description !== description ||

      // are the question/answers different than the store?
      this.state.questions == null && additionalInfo != null ||
      this.state.questions != null && additionalInfo == null ||
      this.state.questions != null && additionalInfo != null && JSON.stringify(this.state.questions) !== JSON.stringify(additionalInfo)
    ) {
      state = Object.assign(this.state,
        {
          _description: description,
          _questions: additionalInfo,
          _questionsAndAnswers: questionsAndAnswers,
        });

        result = true;
    }

    if (!isConfirmationPage) {
      state = Object.assign(this.state,
        {
          _accountId: null,
          _chargeToAccountLabel: null,
          _selectedAccountText: null
        });
    }

    // cannot verify if the media files are different than the previously uploaded media files
    // changing the media files doesn't require the deletion of the information from the next wizard pages
    state = Object.assign(this.state,
    {
      _uploader: uploader
    });

    this.setState(state);

    return result;
  }


  setChargeToAccount(chargeToAccount: ChargeToAccountModel, chargeToAccountLabel: string ): boolean {

    let result: boolean = false;
    let state: WorkOrderRequestModel = null;
    if (chargeToAccount == null && this.state.accountId != null)
    {
      state = Object.assign(this.state,
      {
        _accountId: null,
        _chargeToAccountLabel: chargeToAccountLabel,
        _selectedAccountText: null
      });

      result = true;
    }
    else if (
      chargeToAccount != null &&
      ( this.state.accountId == null || this.state.accountId != chargeToAccount.AccountId )
    )
    {
      state = Object.assign(this.state,
      {
        _accountId: chargeToAccount.AccountId,
        _chargeToAccountLabel: chargeToAccountLabel,
        _selectedAccountText: chargeToAccount.AccountName
      });

      result = true;
    }

    if (result) {
      this.setState(state);
    }

    return result;
  }

  suppressedEmergencyKeywords(suppressedEmergencyKeywords: string[]): void {
    let state: WorkOrderRequestModel = Object.assign(this.state,
      {
        _suppressedEmergencyKeywords: [...this.state.suppressedEmergencyKeywords, ...suppressedEmergencyKeywords]
      });
    this.setState(state);
  }

  duplicate(workOrder: FullWorkOrderModel) {
    let state: WorkOrderRequestModel = Object.assign(this.state,
      {
        _locationId: workOrder.LocationId,
        _locationType: workOrder.LocationType,
        _locationDescription: workOrder.LocationDisplayText,
        _floorId: workOrder.FloorId,
        _selectedFloorText: workOrder.FloorName,
        _workspaceId: workOrder.WorkspaceId,
        _selectedWorkspaceText: workOrder.WorkspaceName,
        _insideFlag: workOrder.InsideRequestTypeFlagForDuplication,
        _requestTypeId: workOrder.RequestTypeIdForDuplication,
        _selectedRequestTypeText: workOrder.RequestTypeForDuplication,
        //_description: workOrder.Description,
        _uploader: null,
        //_accountId: workOrder.AccountId,
        //_selectedAccountText: workOrder.AccountName,
        _chargeToAccountLabel: workOrder.AccountLabel,
        //_questions: workOrder.Questions,
        _priorityId: workOrder.Priority,
        _priorityDescription: workOrder.PriorityDescription,
        _suppressedEmergencyKeywords: [],
        _isDuplicated: true,
        _operationalCode: workOrder.OperationalCode,
        _operationalCodeLabel: workOrder.OperationalCodeLabel
      });

    this.setState(state);
    this.chargeToAccountLabelStoreService.setState(workOrder.AccountLabel);
  }
}
