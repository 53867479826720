import { LocationType } from 'src/app/modules/shared-location/models/locationType';
import { FileUploader } from 'ng2-file-upload';
import { QuestionModel } from './questionModel';
import { Constants } from '../../wrcommon/classes/constants';

export class AdditionalInfo {
  constructor(
    public questionId: number,
    public answerId: number,
    public answer: string) {
  }
}

export class WorkOrderRequestModel {
  workOrderSource: string = Constants.WebAssistWeb;

  constructor(
    private _clientId: number,
    private _userId: number,
    private _callerId: number,
    private _locationId: number = 0,
    private _locationType: LocationType = LocationType.Building,
    private _locationDescription: string = null,
    private _operationalCode: string = null,
    private _operationalCodeLabel: string = null,

    private _insideFlag: boolean | null = null,

    private _floorId: number | null = null,
    private _workspaceId: number | null = null,
    private _requestTypeId: number = null,
    private _description: string = null,
    private _accountId: number | null = null,

    private _priorityId: number | null = null,
    private _priorityDescription: string = null
  )
  {
  }

  private _selectedFloorText: string | null;
  private _selectedWorkspaceText: string | null;
  private _selectedRequestTypeText: string | null;

  private _questions: Array<AdditionalInfo>;
  private _questionsAndAnswers: Array<QuestionModel>;

  private _chargeToAccountLabel: string | null;
  private _selectedAccountText: string | null;

  private _suppressedEmergencyKeywords: Array<string> = [];
  private _uploader: FileUploader = null;

  private _isDuplicated: boolean = false;


  // Override for JSON serialization so we get a smaller object only with the getters we need
  public toJSON(): string {
    let result: any = {};

    for (let key in this) {
      if (key[0] !== '_' && key[0] !== key[0].toUpperCase()) {
        result[key] = this[key];
      }
    }

    return result;
  }

  public get clientId(): number {
    return this._clientId;
  }

  public get userId(): number {
    return this._userId;
  }

  public get callerId(): number {
    return this._callerId;
  }

  public get locationId(): number {
    return this._locationId;
  }

  public get locationType(): LocationType {
    return this._locationType;
  }

  public get locationDescription(): string {
    return this._locationDescription;
  }

  public get operationalCode(): string {
    return this._operationalCode;
  }

  public get operationalCodeLabel(): string {
    return this._operationalCodeLabel;
  }

  public get floorId(): number | null {
    return this._floorId;
  }

  public get SelectedFloorText(): string | null {
    return this._selectedFloorText;
  }
  public get workspaceId(): number | null {
    return this._workspaceId;
  }

  public get SelectedWorkspaceText(): string | null {
    return this._selectedWorkspaceText;
  }

  public get requestTypeId(): number {
    return this._requestTypeId;
  }

  public get SelectedRequestTypeText(): string | null {
    return this._selectedRequestTypeText;
  }

  public get description(): string {
    return this._description;
  }

  public get accountId(): number {
    return this._accountId;
  }

  public get SelectedAccountText(): string | null {
    return this._selectedAccountText;
  }

  public get ChargeToAccountLabel(): string | null {
    return this._chargeToAccountLabel;
  }

  public get questions(): Array<AdditionalInfo> {
    return this._questions;
  }

  public get questionsAndAnswers(): Array<QuestionModel> {
    return this._questionsAndAnswers;
  }
  public get insideFlag(): boolean | null {
    return this._insideFlag;
  }

  public get suppressedEmergencyKeywords(): Array<string> {
    return this._suppressedEmergencyKeywords;
  }

  public get Uploader(): FileUploader {
    return this._uploader;
  }

  public get PriorityId(): number {
    return this._priorityId;
  }

  public get PriorityDescription(): string {
    return this._priorityDescription;
  }

  public get IsDuplicated(): boolean {
    return this._isDuplicated;
  }
}
