import { Component, AfterViewInit, ElementRef, ViewChild, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { takeUntil } from "rxjs/operators";
import { LocalstorageService } from 'src/app/modules/core/services/localstorage.service';
import { TenantService } from 'src/app/modules/core/services/tenant.service';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { HighContrastStoreService } from '../../services/high-contrast-store.service';
import { brandVariables } from 'src/brand-variables';

@Component({
  selector: 'app-clientlogo',
  templateUrl: './clientlogo.component.html',
  styleUrls: ['./clientlogo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientlogoComponent extends DestroyableComponentBase implements AfterViewInit {

  client: string = 'bgis';
  hasClientLogo: boolean = false;
  Math: any = Math;
  @ViewChild('logo') logo: ElementRef;
  wideImage: boolean = brandVariables.showWideImage;
  defaultClientName: string = brandVariables.defaultClientName;

  constructor(private storageService: LocalstorageService,
    private tenantService: TenantService,
    public highContrastStoreService: HighContrastStoreService,
    private cdref: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const tenant = this.storageService.getTenant();
      this.client = (tenant ? (tenant.Host || 'bgis') : 'bgis').replace(/\d+/g, '');
      if (this.client !== 'bgis' && !this.hasClientLogo && !this.wideImage) {
        this.hasClientLogo = true;
        this.cdref.markForCheck();

        setTimeout(() => {
          if (this.client !== 'bgis' && !this.wideImage && this.logo && Math.abs(this.logo.nativeElement.offsetHeight - this.logo.nativeElement.offsetWidth) > 20) {
            this.wideImage = true;
            this.cdref.markForCheck();
          }
        }, 200);
      }
    }, 200);

    this.tenantService.state$
      .pipe(takeUntil(this.destroyed))
      .subscribe(tenant => {
        this.client = (tenant ? (tenant.Host || 'bgis') : 'bgis').replace(/\d+/g, '');
        if (this.client !== 'bgis' && !this.hasClientLogo && !this.wideImage) {
          this.hasClientLogo = true;
          this.cdref.markForCheck();

          setTimeout(() => {
            if (this.client !== 'bgis' && !this.wideImage && this.logo && Math.abs(this.logo.nativeElement.offsetHeight - this.logo.nativeElement.offsetWidth) > 20) {
              this.wideImage = true;
              this.cdref.markForCheck();
            }
          }, 200);
        }
      });
  }

  setDefaultLogo() {
    this.client = 'bgis';
    this.hasClientLogo = false;
    console.log('setDefaultLogo');
  }
}

