import { MatDialogRef } from '@angular/material';
var ConfirmationDialogComponent = /** @class */ (function () {
    function ConfirmationDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    Object.defineProperty(ConfirmationDialogComponent.prototype, "contentData", {
        get: function () {
            return this.data;
        },
        enumerable: true,
        configurable: true
    });
    return ConfirmationDialogComponent;
}());
export { ConfirmationDialogComponent };
