import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { ChargeToAccountLabelStoreService } from '../../services/charge-to-account-label-store.service';
import { Constants } from 'src/app/modules/wrcommon/classes/constants';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends DestroyableComponentBase implements OnInit {

  public breadcrumbs: Breadcrumb[] = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private chargeToAccountLabelStoreService: ChargeToAccountLabelStoreService) {
      super();
  }

  ngOnInit() {

    let breadcrumb: Breadcrumb = {
      label: 'SideNav.Home',
      url: ''
    };

    this.chargeToAccountLabelStoreService.state$
      .pipe(takeUntil(this.destroyed))
      .subscribe(labelValue => {
        if (!!labelValue) {
          this.breadcrumbs
            .filter(breadcrumb => breadcrumb.label === Constants.ChargeToAccountTitle)
            .forEach(breadcrumb => {
              breadcrumb.labelKeyword = Constants.ChargeToAccountKeyword;
              breadcrumb.labelValue = labelValue;
            });
        }
      });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroyed))
      .subscribe(event => {
      //set breadcrumbs
      let root: ActivatedRoute = this.route.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
      //this.breadcrumbs = [breadcrumb, ...this.breadcrumbs]
    });
  }

  private getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";
    //get the child routes
    let children: ActivatedRoute[] = route.children;

    //return if there are no more children
    if (children.length === 0) {
      return breadcrumbs;
    }

    //iterate over each children
    for (let child of children) {
      //verify primary route
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }

      //get the route's URL segment
      let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");

      //append route URL to URL
      url += `/${routeURL}`;

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
        return this.getBreadcrumbs(child, url, breadcrumbs);
      }

      //add breadcrumb
      let breadcrumb: Breadcrumb = {
        label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
        url: url
      };

      if (breadcrumbs.length > 0 && breadcrumbs[breadcrumbs.length - 1].label === breadcrumb.label) {
        breadcrumbs.pop();
      }
      breadcrumbs.push(breadcrumb);

      //recursive
      return this.getBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }

  trackFn(index: number, item: Breadcrumb): string
  {
    const uniqueCode = `${item.label}-${item.labelValue}`;
    return uniqueCode;
  }
}

export interface Breadcrumb {
  label: string;
  url: string;
  labelKeyword?: string;
  labelValue?: string;
}
