<nav class="side-nav-tree">
    <app-clientlogo></app-clientlogo>
    <ul *ngIf="isAuthenticated | async" class="sidenav" [attr.aria-label]="'layout.ProfileMenu' | translate"
        role="menubar" aria-hidden="false">
        <li class="clearfix" [class.separate]="item.isSeparate" *ngFor="let item of mainMenu" role="none">
            <svg-icon class="menu-icon" role="presentation"
                src="assets/images/icons/menu-icons/{{item.icon}}.svg"></svg-icon>
            <a routerLinkActive="active" role="menuitem"
                routerLink="{{item.routerLink? item.routerLink : 'javascript:void'}}" (click)="toggleSection(item.id)"
                [attr.id]="item.id" [attr.aria-expanded]='item.id === activeSection'
                [attr.aria-haspopup]="item.subnav? true: false"
                [attr.aria-controls]="item.subnav ? 'sub-' + item.id : null">
                {{ 'SideNav.' + item.name | translate}}
            </a>
            <ul *ngIf="item.subnav" class="sub-nav" role="menu" tabindex="-1" [attr.id]='"sub-" + item.id'
                [ngClass]="'expanded'" [attr.aria-labelledby]="item.id">
                <li *ngFor="let subnav of item.subnav | keys" role="none">
                    <svg-icon class="menu-icon" role="presentation" focusable="true"
                        src="assets/images/icons/menu-icons/{{subnav.icon}}.svg"></svg-icon>
                    <a routerLinkActive="active" role="menuitem"
                        routerLink="{{subnav.routerLink ? subnav.routerLink : 'javascript:void'}}">
                        {{ 'SideNav.' + subnav.name | translate}}
                    </a>
                </li>
            </ul>
        </li>
    </ul>

</nav>
<div class="emergency-container">
    <div class="emergency" *ngIf="userService.isAuthenticated() | async">
        <button mat-icon-button [attr.aria-label]="'alert.EmergencyRequestTitle' | translate"
            (click)="callUsRequestService.openCallUsContentAlert()">
            <svg-icon class="emergency-request" role="presentation" src="assets/images/icons/warning.svg">
            </svg-icon>
            {{'alert.EmergencyRequest' | translate}}
        </button>
    </div>
</div>