import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { takeUntil } from "rxjs/operators";
import { Subject } from 'rxjs';
import { TranslationService } from '../services/translation.service';
import { UserdatasharingService } from '../../core/services/userdatasharing.service';

@Injectable()
export class PaginatorLocale extends MatPaginatorIntl implements OnDestroy {

  private destroyed = new Subject<any>();
  OF_LABEL: string = 'of';

  constructor(private userService: UserdatasharingService,
              private translationService: TranslationService) {
    super();

    //this.userService.currentLanguage
    //  .pipe(takeUntil(this.destroyed))
    //  .subscribe(() => {
    //    this.initTranslations();
    //  });

    this.initTranslations();
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  } 

  async initTranslations() {
    this.itemsPerPageLabel = await this.translationService.translate('paginator.ItemsPerPage');
    this.nextPageLabel = await this.translationService.translate('paginator.NextPage');
    this.previousPageLabel = await this.translationService.translate('paginator.PreviousPage');
    this.firstPageLabel = await this.translationService.translate('paginator.FirstPage');
    this.lastPageLabel = await this.translationService.translate('paginator.LastPage');
    this.OF_LABEL = await this.translationService.translate('paginator.Of');
    this.changes.next();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.OF_LABEL} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;
  }
}
