/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./main.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tabs";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/material/core";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "../../../wrcommon/pipes/translate.pipe";
import * as i9 from "../../../wrcommon/services/translation.service";
import * as i10 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i11 from "@angular/cdk/bidi";
import * as i12 from "@angular/cdk/scrolling";
import * as i13 from "./main.component";
import * as i14 from "../../services/userdatasharing.service";
var styles_MainComponent = [i0.styles];
var RenderType_MainComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MainComponent, data: {} });
export { RenderType_MainComponent as RenderType_MainComponent };
function View_MainComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "mat-tab-link"], ["mat-tab-link", ""], ["routerLinkActive", "active"]], [[1, "aria-current", 0], [1, "aria-disabled", 0], [1, "tabIndex", 0], [2, "mat-tab-disabled", null], [2, "mat-tab-label-active", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, [[1, 4]], 0, i2.MatTabLink, [i2.MatTabNav, i1.ElementRef, i1.NgZone, i3.Platform, [2, i4.MAT_RIPPLE_GLOBAL_OPTIONS], [8, null], i5.FocusMonitor], { active: [0, "active"] }, null), i1.ɵdid(2, 671744, [[3, 4]], 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, [["rla", 4]], 2, i6.RouterLinkActive, [i6.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(0, i8.AsyncTranslatePipe, [i9.TranslationService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_7 = i1.ɵnov(_v, 3).isActive; _ck(_v, 1, 0, currVal_7); var currVal_8 = _v.context.$implicit.url; _ck(_v, 2, 0, currVal_8); var currVal_9 = "active"; _ck(_v, 3, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).active; var currVal_1 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_2 = i1.ɵnov(_v, 1).tabIndex; var currVal_3 = i1.ɵnov(_v, 1).disabled; var currVal_4 = i1.ɵnov(_v, 1).active; var currVal_5 = i1.ɵnov(_v, 2).target; var currVal_6 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_10 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.label)); _ck(_v, 6, 0, currVal_10); }); }
function View_MainComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "nav", [["class", "tab-group mat-tab-nav-bar"], ["mat-tab-nav-bar", ""]], null, null, null, i10.View_MatTabNav_0, i10.RenderType_MatTabNav)), i1.ɵdid(1, 3325952, null, 1, i2.MatTabNav, [i1.ElementRef, [2, i11.Directionality], i1.NgZone, i1.ChangeDetectorRef, i12.ViewportRuler], null, null), i1.ɵqud(603979776, 1, { _tabLinks: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MainComponent_2)), i1.ɵdid(4, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navLinks; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_MainComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MainComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTabs; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_MainComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-main", [], null, null, null, View_MainComponent_0, RenderType_MainComponent)), i1.ɵdid(1, 114688, null, 0, i13.MainComponent, [i14.UserdatasharingService, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MainComponentNgFactory = i1.ɵccf("app-main", i13.MainComponent, View_MainComponent_Host_0, {}, {}, []);
export { MainComponentNgFactory as MainComponentNgFactory };
