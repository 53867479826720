import * as tslib_1 from "tslib";
import { Store } from '../models/store';
import * as i0 from "@angular/core";
var ResponsiveService = /** @class */ (function (_super) {
    tslib_1.__extends(ResponsiveService, _super);
    function ResponsiveService() {
        return _super.call(this, false) || this;
    }
    ResponsiveService.prototype.checkWidth = function () {
        var width = window.innerWidth;
        if (width <= 768) {
            this.screenWidth = 'sm';
            this.setState(true);
        }
        else if (width > 768 && width <= 992) {
            this.screenWidth = 'md';
            this.setState(false);
        }
        else {
            this.screenWidth = 'lg';
            this.setState(false);
        }
    };
    ResponsiveService.ngInjectableDef = i0.defineInjectable({ factory: function ResponsiveService_Factory() { return new ResponsiveService(); }, token: ResponsiveService, providedIn: "root" });
    return ResponsiveService;
}(Store));
export { ResponsiveService };
