import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { UserdatasharingService } from 'src/app/modules/core/services/userdatasharing.service';
import { CallUsRequestService } from 'src/app/modules/core/services/call-us-request.service';

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomNavComponent {

  visible = false;

  constructor(private userService: UserdatasharingService,
    public callUsRequestService: CallUsRequestService) {
  }
  get isAuthenticated(): Observable<boolean> {
    return this.userService.isAuthenticated();
  }
}
