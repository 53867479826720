import { CanActivate, CanLoad, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { LocalstorageService } from './localstorage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./localstorage.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, userData) {
        this.router = router;
        this.userData = userData;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        return this.canNavigate(state.url);
    };
    AuthGuard.prototype.canActivateChild = function (next, state) {
        return this.canNavigate(state.url);
    };
    AuthGuard.prototype.canLoad = function (route) {
        return this.canNavigate(route.path);
    };
    AuthGuard.prototype.canNavigate = function (url) {
        if (this.isAuthenticated()) {
            return true;
        }
        this.router.navigate(["/login"], { queryParams: { redirectUrl: url } });
        return false;
    };
    AuthGuard.prototype.isAuthenticated = function () {
        var tenant = this.userData.getTenant();
        var account = this.userData.getAccount();
        return !!account && !!tenant && account.clientId === tenant.rsClientId && account.userId > 0;
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.Router), i0.inject(i2.LocalstorageService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
