import { LocationType } from 'src/app/modules/shared-location/models/locationType';
import { Constants } from '../../wrcommon/classes/constants';
var AdditionalInfo = /** @class */ (function () {
    function AdditionalInfo(questionId, answerId, answer) {
        this.questionId = questionId;
        this.answerId = answerId;
        this.answer = answer;
    }
    return AdditionalInfo;
}());
export { AdditionalInfo };
var WorkOrderRequestModel = /** @class */ (function () {
    function WorkOrderRequestModel(_clientId, _userId, _callerId, _locationId, _locationType, _locationDescription, _operationalCode, _operationalCodeLabel, _insideFlag, _floorId, _workspaceId, _requestTypeId, _description, _accountId, _priorityId, _priorityDescription) {
        if (_locationId === void 0) { _locationId = 0; }
        if (_locationType === void 0) { _locationType = LocationType.Building; }
        if (_locationDescription === void 0) { _locationDescription = null; }
        if (_operationalCode === void 0) { _operationalCode = null; }
        if (_operationalCodeLabel === void 0) { _operationalCodeLabel = null; }
        if (_insideFlag === void 0) { _insideFlag = null; }
        if (_floorId === void 0) { _floorId = null; }
        if (_workspaceId === void 0) { _workspaceId = null; }
        if (_requestTypeId === void 0) { _requestTypeId = null; }
        if (_description === void 0) { _description = null; }
        if (_accountId === void 0) { _accountId = null; }
        if (_priorityId === void 0) { _priorityId = null; }
        if (_priorityDescription === void 0) { _priorityDescription = null; }
        this._clientId = _clientId;
        this._userId = _userId;
        this._callerId = _callerId;
        this._locationId = _locationId;
        this._locationType = _locationType;
        this._locationDescription = _locationDescription;
        this._operationalCode = _operationalCode;
        this._operationalCodeLabel = _operationalCodeLabel;
        this._insideFlag = _insideFlag;
        this._floorId = _floorId;
        this._workspaceId = _workspaceId;
        this._requestTypeId = _requestTypeId;
        this._description = _description;
        this._accountId = _accountId;
        this._priorityId = _priorityId;
        this._priorityDescription = _priorityDescription;
        this.workOrderSource = Constants.WebAssistWeb;
        this._suppressedEmergencyKeywords = [];
        this._uploader = null;
        this._isDuplicated = false;
    }
    // Override for JSON serialization so we get a smaller object only with the getters we need
    WorkOrderRequestModel.prototype.toJSON = function () {
        var result = {};
        for (var key in this) {
            if (key[0] !== '_' && key[0] !== key[0].toUpperCase()) {
                result[key] = this[key];
            }
        }
        return result;
    };
    Object.defineProperty(WorkOrderRequestModel.prototype, "clientId", {
        get: function () {
            return this._clientId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "userId", {
        get: function () {
            return this._userId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "callerId", {
        get: function () {
            return this._callerId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "locationId", {
        get: function () {
            return this._locationId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "locationType", {
        get: function () {
            return this._locationType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "locationDescription", {
        get: function () {
            return this._locationDescription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "operationalCode", {
        get: function () {
            return this._operationalCode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "operationalCodeLabel", {
        get: function () {
            return this._operationalCodeLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "floorId", {
        get: function () {
            return this._floorId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "SelectedFloorText", {
        get: function () {
            return this._selectedFloorText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "workspaceId", {
        get: function () {
            return this._workspaceId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "SelectedWorkspaceText", {
        get: function () {
            return this._selectedWorkspaceText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "requestTypeId", {
        get: function () {
            return this._requestTypeId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "SelectedRequestTypeText", {
        get: function () {
            return this._selectedRequestTypeText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "description", {
        get: function () {
            return this._description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "accountId", {
        get: function () {
            return this._accountId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "SelectedAccountText", {
        get: function () {
            return this._selectedAccountText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "ChargeToAccountLabel", {
        get: function () {
            return this._chargeToAccountLabel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "questions", {
        get: function () {
            return this._questions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "questionsAndAnswers", {
        get: function () {
            return this._questionsAndAnswers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "insideFlag", {
        get: function () {
            return this._insideFlag;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "suppressedEmergencyKeywords", {
        get: function () {
            return this._suppressedEmergencyKeywords;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "Uploader", {
        get: function () {
            return this._uploader;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "PriorityId", {
        get: function () {
            return this._priorityId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "PriorityDescription", {
        get: function () {
            return this._priorityDescription;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestModel.prototype, "IsDuplicated", {
        get: function () {
            return this._isDuplicated;
        },
        enumerable: true,
        configurable: true
    });
    return WorkOrderRequestModel;
}());
export { WorkOrderRequestModel };
