import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, CanActivateChild, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalstorageService } from './localstorage.service';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(private router: Router,
    private storageService: LocalstorageService,
    private tenantService: TenantService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.canNavigate(state.url);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.canNavigate(state.url);
  }

  canLoad(route: Route): boolean | Observable<boolean> {
    return this.canNavigate(route.path);
  }

  private canNavigate(url: string): boolean {
    const tenant = this.storageService.getTenant();
    if (!tenant) {
      this.router.navigateByUrl("/access-forbidden");
      return false;
    }

    this.tenantService.setState(tenant);

    let isUserRegistered: boolean = tenant.IsUserRegistered;
    let loginName: string = tenant.LoginName;
    const account = this.storageService.getAccount();
    if (!!account) {
      isUserRegistered = isUserRegistered || !!account.callerId;
      loginName = loginName || account.userName;
    }
    if ((!!isUserRegistered) || (tenant.ssoFederated && (!loginName))) {
      this.router.navigate(["/login"]);
      return false;
    }

    return true;
  }
}
