
export interface AccountModel {
  userId: number;
  clientId: number;
  clientCode: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  phoneNumber: string;
  phoneNumberCountryCode?: string;
  password: string;
  confirmPassword: string;
  RememberMe: boolean;
  RetCode: number | null;
  ErrMsg: string | null;

  language: string;

  callerId?: number;
  requestReceivedNotification: boolean;
  requestAssignedNotification: boolean;
  arrivedNotification: boolean;
  inProgressNotification: boolean;
  completeNotification: boolean;
  LocationId?: number | null;

  hasWorkOrders: boolean | null;
  AccessToken: string;
  IsFederated?: boolean;
}

// clears the confidential info from an accoutn model.
// use this function before the accoutn is stored to the local/session storage
export function clearPassword(account: AccountModel): AccountModel
{
  if (account != null) {
    account.password = '';
    account.confirmPassword = '';
  }

  return account;
}


