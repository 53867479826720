import { HttpClient } from "@angular/common/http";
import { WebRequestConfig } from "../../wrcommon/classes/config";
import { WebAssistHttpHeaders } from '../../wrcommon/classes/httpHeaders';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AccountService = /** @class */ (function () {
    function AccountService(http) {
        this.http = http;
    }
    AccountService.prototype.getUserAccount = function (userName, userId) {
        return this.http.get(WebRequestConfig.baseUrlUserAccount + "account/GetPhoneNumberAndEmail/" + userName + "/" + userId);
    };
    // calls the account/update API and returns a boolean indicating if the operation has succeeded
    AccountService.prototype.updateUserAccount = function (accountModel) {
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "account/update", accountModel);
    };
    // calls the account/update API and returns the new account information
    AccountService.prototype.changePassword = function (model) {
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "account/changePassword", model, WebAssistHttpHeaders.HttpOptions);
    };
    AccountService.prototype.getClientCode = function (rsClientId) {
        return this.http.get(WebRequestConfig.baseUrlClientRequest + "public/getClientCode/" + rsClientId);
    };
    AccountService.prototype.addAccount = function (loginModel) {
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "public/register", loginModel);
    };
    AccountService.prototype.bellValidateIfCallerAccountExists = function (loginModel) {
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "public/validateBellUserBeforeRegistration", loginModel);
    };
    // resets the temporary password
    AccountService.prototype.resetTemporaryPassword = function (model) {
        console.log("resetTemporaryPassword");
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "public/resetTemporaryPassword", model, WebAssistHttpHeaders.HttpOptions);
    };
    AccountService.prototype.helpSignIn = function (needHelpModel, isAccountUnlock) {
        if (isAccountUnlock === void 0) { isAccountUnlock = true; }
        return isAccountUnlock ? this.unlockAccount(needHelpModel) : this.resetPassword(needHelpModel);
    };
    AccountService.prototype.resetPassword = function (needHelpModel) {
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "public/resetPassword", needHelpModel, WebAssistHttpHeaders.HttpOptions);
    };
    AccountService.prototype.unlockAccount = function (needHelpModel) {
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "public/unlockAccount", needHelpModel, WebAssistHttpHeaders.HttpOptions);
    };
    AccountService.prototype.unlockWithToken = function (model) {
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "public/unlockWithToken", model, WebAssistHttpHeaders.HttpOptions);
    };
    AccountService.prototype.isProgressNoteSortOrderAscending = function (model) {
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "public/isProgressNoteSortOrderAscending", model, WebAssistHttpHeaders.HttpOptions);
    };
    AccountService.prototype.saveProgressNoteSortOrderConfigForUser = function (model) {
        return this.http.post(WebRequestConfig.baseUrlUserAccount + "public/saveProgressNoteSortOrderConfigForUser", model, WebAssistHttpHeaders.HttpOptions);
    };
    AccountService.ngInjectableDef = i0.defineInjectable({ factory: function AccountService_Factory() { return new AccountService(i0.inject(i1.HttpClient)); }, token: AccountService, providedIn: "root" });
    return AccountService;
}());
export { AccountService };
