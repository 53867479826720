import { HttpClient } from '@angular/common/http';
import { WebRequestConfig } from '../../wrcommon/classes/config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserInfoService = /** @class */ (function () {
    function UserInfoService(http) {
        this.http = http;
    }
    UserInfoService.prototype.GetUserInfo = function () {
        return this.http.get(WebRequestConfig.baseUrlRealSuite + "User/UserInfo");
    };
    ;
    UserInfoService.prototype.GetClientProfile = function (rsClientId) {
        var url = WebRequestConfig.baseUrlRealSuiteUserAuth + "ClientProfile?rsClientId=" + rsClientId + "&Scope=WEB.ASSIST";
        return this.http.get(url);
    };
    UserInfoService.ngInjectableDef = i0.defineInjectable({ factory: function UserInfoService_Factory() { return new UserInfoService(i0.inject(i1.HttpClient)); }, token: UserInfoService, providedIn: "root" });
    return UserInfoService;
}());
export { UserInfoService };
