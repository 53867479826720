import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { MainMenuService } from '../../services/main-menu.service';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { CallUsRequestService } from '../../services/call-us-request.service';
var SideNavTreeComponent = /** @class */ (function () {
    function SideNavTreeComponent(router, userService, mainMenuService, callUsRequestService) {
        this.router = router;
        this.userService = userService;
        this.mainMenuService = mainMenuService;
        this.callUsRequestService = callUsRequestService;
        this.activeSection = '';
    }
    SideNavTreeComponent.prototype.ngOnInit = function () {
        this.getMenu();
    };
    SideNavTreeComponent.prototype.getMenu = function () {
        var _this = this;
        this.mainMenuService.getMenuItems()
            .pipe(take(1))
            .subscribe(function (menuItems) { return (_this.mainMenu = menuItems); });
    };
    SideNavTreeComponent.prototype.toggleSection = function (section) {
        if (this.activeSection !== section) {
            this.activeSection = section;
        }
        else {
            this.activeSection = '';
        }
        //console.log(this.activeSection);
    };
    Object.defineProperty(SideNavTreeComponent.prototype, "isAuthenticated", {
        get: function () {
            return this.userService.isAuthenticated();
        },
        enumerable: true,
        configurable: true
    });
    SideNavTreeComponent.prototype.logout = function () {
        this.userService.clear();
        this.router.navigateByUrl('/login');
    };
    return SideNavTreeComponent;
}());
export { SideNavTreeComponent };
