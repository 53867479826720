import * as tslib_1 from "tslib";
import { Store } from '../models/store';
import * as i0 from "@angular/core";
var LoaderService = /** @class */ (function (_super) {
    tslib_1.__extends(LoaderService, _super);
    function LoaderService() {
        return _super.call(this, false) || this;
    }
    LoaderService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}(Store));
export { LoaderService };
