<nav mat-tab-nav-bar class="tab-group" *ngIf="showTabs">
  <a mat-tab-link
     *ngFor="let link of navLinks"
     [routerLink]="link.url"
     routerLinkActive="active" #rla="routerLinkActive"
     [active]="rla.isActive">
    {{link.label | translate}}
  </a>
</nav>
<router-outlet></router-outlet>
