import { Renderer2, AfterViewInit, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { WebRequestConfig } from 'src/app/modules/wrcommon/classes/config';
import { Language } from 'src/app/modules/wrcommon/classes/language';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { HighContrastStoreService } from '../../services/high-contrast-store.service';
import { LocalstorageService } from '../../services/localstorage.service';
import { brandVariables } from 'src/brand-variables';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(userData, renderer, storageService, highContrastStoreService) {
        this.userData = userData;
        this.renderer = renderer;
        this.storageService = storageService;
        this.highContrastStoreService = highContrastStoreService;
        this.isHighContrast = false;
    }
    FooterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userData.getAppVersion().subscribe(function (res) { return _this.version = res; });
        this.currentYear = new Date().getFullYear();
        this.footerCopyrightTranslationLabel = brandVariables.footerCopyright;
    };
    FooterComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.isHighContrast = _this.storageService.getIsHighContrast();
            _this.applyContrastStyle();
        });
    };
    Object.defineProperty(FooterComponent.prototype, "currentLanguage", {
        get: function () {
            return this.userData.getCurrentLanguage() === Language.French ? Language.TranslationLanguageFrench : Language.TranslationLanguageCanada;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "environment", {
        get: function () {
            return WebRequestConfig.environment;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "environmentName", {
        get: function () {
            return environment.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "environmentVersion", {
        get: function () {
            return environment.version;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "buildDate", {
        get: function () {
            return new Date(environment.timeStamp);
        },
        enumerable: true,
        configurable: true
    });
    FooterComponent.prototype.toggleHighContrast = function (event) {
        this.isHighContrast = event.checked;
        this.highContrastStoreService.setState(this.isHighContrast);
        this.storageService.setIsHighContrast(this.isHighContrast);
        this.applyContrastStyle();
    };
    FooterComponent.prototype.applyContrastStyle = function () {
        if (this.isHighContrast) {
            this.renderer.addClass(document.querySelector('body'), 'theme-hq');
        }
        else {
            this.renderer.removeClass(document.querySelector('body'), 'theme-hq');
        }
    };
    return FooterComponent;
}());
export { FooterComponent };
