import { UserdatasharingService } from 'src/app/modules/core/services/userdatasharing.service';
import { CallUsRequestService } from 'src/app/modules/core/services/call-us-request.service';
var BottomNavComponent = /** @class */ (function () {
    function BottomNavComponent(userService, callUsRequestService) {
        this.userService = userService;
        this.callUsRequestService = callUsRequestService;
        this.visible = false;
    }
    Object.defineProperty(BottomNavComponent.prototype, "isAuthenticated", {
        get: function () {
            return this.userService.isAuthenticated();
        },
        enumerable: true,
        configurable: true
    });
    return BottomNavComponent;
}());
export { BottomNavComponent };
