import { Injectable } from '@angular/core';
import { Store } from '../models/store';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class HighContrastStoreService extends Store<boolean> {

  constructor(private storageService: LocalstorageService) {
    super(storageService.getIsHighContrast());
  }
}

