import { BehaviorSubject } from 'rxjs';
var Store = /** @class */ (function () {
    function Store(initialState) {
        this._state$ = new BehaviorSubject(initialState);
        this.state$ = this._state$.asObservable();
    }
    Object.defineProperty(Store.prototype, "state", {
        get: function () {
            return this._state$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Store.prototype.setState = function (nextState) {
        this._state$.next(nextState);
    };
    return Store;
}());
export { Store };
