/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-nav-tree.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/angular-svg-icon/angular-svg-icon.ngfactory";
import * as i3 from "angular-svg-icon";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../../wrcommon/pipes/translate.pipe";
import * as i7 from "../../../wrcommon/services/translation.service";
import * as i8 from "@angular/flex-layout/extended";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i11 from "@angular/material/button";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "@angular/platform-browser/animations";
import * as i15 from "../../../wrcommon/pipes/keys.pipe";
import * as i16 from "../clientlogo/clientlogo.component.ngfactory";
import * as i17 from "../clientlogo/clientlogo.component";
import * as i18 from "../../services/localstorage.service";
import * as i19 from "../../services/tenant.service";
import * as i20 from "../../services/high-contrast-store.service";
import * as i21 from "./side-nav-tree.component";
import * as i22 from "../../services/userdatasharing.service";
import * as i23 from "../../services/main-menu.service";
import * as i24 from "../../services/call-us-request.service";
var styles_SideNavTreeComponent = [i0.styles];
var RenderType_SideNavTreeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideNavTreeComponent, data: {} });
export { RenderType_SideNavTreeComponent as RenderType_SideNavTreeComponent };
function View_SideNavTreeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["role", "none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["class", "menu-icon"], ["focusable", "true"], ["role", "presentation"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "a", [["role", "menuitem"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, [[4, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(5, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(0, i6.AsyncTranslatePipe, [i7.TranslationService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "assets/images/icons/menu-icons/", _v.context.$implicit.icon, ".svg"); _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.routerLink ? _v.context.$implicit.routerLink : "javascript:void"), ""); _ck(_v, 4, 0, currVal_3); var currVal_4 = "active"; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(("SideNav." + _v.context.$implicit.name))); _ck(_v, 8, 0, currVal_5); }); }
function View_SideNavTreeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ul", [["class", "sub-nav"], ["role", "menu"], ["tabindex", "-1"]], [[1, "id", 0], [1, "aria-labelledby", 0]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(2, 933888, null, 0, i8.DefaultClassDirective, [i1.ElementRef, i9.StyleUtils, i9.MediaMarshaller, i1.IterableDiffers, i1.KeyValueDiffers, i1.Renderer2, [6, i5.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SideNavTreeComponent_4)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(5, 1)], function (_ck, _v) { var currVal_2 = "sub-nav"; var currVal_3 = "expanded"; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = "expanded"; var currVal_5 = "sub-nav"; _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_6 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.subnav)); _ck(_v, 4, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = ("sub-" + _v.parent.context.$implicit.id); var currVal_1 = _v.parent.context.$implicit.id; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideNavTreeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [["class", "clearfix"], ["role", "none"]], [[2, "separate", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "svg-icon", [["class", "menu-icon"], ["role", "presentation"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(2, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "a", [["role", "menuitem"], ["routerLinkActive", "active"]], [[1, "id", 0], [1, "aria-expanded", 0], [1, "aria-haspopup", 0], [1, "aria-controls", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.toggleSection(_v.context.$implicit.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, [[2, 4]], 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(5, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(0, i6.AsyncTranslatePipe, [i7.TranslationService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavTreeComponent_3)), i1.ɵdid(11, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "assets/images/icons/menu-icons/", _v.context.$implicit.icon, ".svg"); _ck(_v, 2, 0, currVal_1); var currVal_8 = i1.ɵinlineInterpolate(1, "", (_v.context.$implicit.routerLink ? _v.context.$implicit.routerLink : "javascript:void"), ""); _ck(_v, 4, 0, currVal_8); var currVal_9 = "active"; _ck(_v, 5, 0, currVal_9); var currVal_11 = _v.context.$implicit.subnav; _ck(_v, 11, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.isSeparate; _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.context.$implicit.id; var currVal_3 = (_v.context.$implicit.id === _co.activeSection); var currVal_4 = (_v.context.$implicit.subnav ? true : false); var currVal_5 = (_v.context.$implicit.subnav ? ("sub-" + _v.context.$implicit.id) : null); var currVal_6 = i1.ɵnov(_v, 4).target; var currVal_7 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(("SideNav." + _v.context.$implicit.name))); _ck(_v, 8, 0, currVal_10); }); }
function View_SideNavTreeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [["aria-hidden", "false"], ["class", "sidenav"], ["role", "menubar"]], [[1, "aria-label", 0]], null, null, null, null)), i1.ɵpid(0, i6.AsyncTranslatePipe, [i7.TranslationService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavTreeComponent_2)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.mainMenu; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("layout.ProfileMenu")); _ck(_v, 0, 0, currVal_0); }); }
function View_SideNavTreeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "emergency"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "button", [["mat-icon-button", ""]], [[1, "aria-label", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.callUsRequestService.openCallUsContentAlert() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_MatButton_0, i10.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i11.MatButton, [i1.ElementRef, i12.Platform, i13.FocusMonitor, [2, i14.ANIMATION_MODULE_TYPE]], null, null), i1.ɵpid(0, i6.AsyncTranslatePipe, [i7.TranslationService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "svg-icon", [["class", "emergency-request"], ["role", "presentation"], ["src", "assets/images/icons/warning.svg"]], null, null, null, i2.View_SvgIconComponent_0, i2.RenderType_SvgIconComponent)), i1.ɵdid(5, 1032192, null, 0, i3.SvgIconComponent, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2, i3.SvgIconRegistryService, i1.ChangeDetectorRef], { src: [0, "src"] }, null), (_l()(), i1.ɵted(6, 0, [" ", " "])), i1.ɵpid(0, i6.AsyncTranslatePipe, [i7.TranslationService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = "assets/images/icons/warning.svg"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("alert.EmergencyRequestTitle")); var currVal_1 = (i1.ɵnov(_v, 2).disabled || null); var currVal_2 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("alert.EmergencyRequest")); _ck(_v, 6, 0, currVal_4); }); }
export function View_SideNavTreeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i15.KeysPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 5, "nav", [["class", "side-nav-tree"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-clientlogo", [], null, null, null, i16.View_ClientlogoComponent_0, i16.RenderType_ClientlogoComponent)), i1.ɵdid(3, 4374528, null, 0, i17.ClientlogoComponent, [i18.LocalstorageService, i19.TenantService, i20.HighContrastStoreService, i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SideNavTreeComponent_1)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "emergency-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SideNavTreeComponent_5)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.isAuthenticated)); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.userService.isAuthenticated())); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_SideNavTreeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-nav-tree", [], null, null, null, View_SideNavTreeComponent_0, RenderType_SideNavTreeComponent)), i1.ɵdid(1, 114688, null, 0, i21.SideNavTreeComponent, [i4.Router, i22.UserdatasharingService, i23.MainMenuService, i24.CallUsRequestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideNavTreeComponentNgFactory = i1.ɵccf("app-side-nav-tree", i21.SideNavTreeComponent, View_SideNavTreeComponent_Host_0, {}, {}, []);
export { SideNavTreeComponentNgFactory as SideNavTreeComponentNgFactory };
