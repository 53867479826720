import { Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as StackTraceParser from 'error-stack-parser';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LocalstorageService } from './localstorage.service';
import * as i0 from "@angular/core";
import * as i1 from "./localstorage.service";
var ErrorService = /** @class */ (function () {
    function ErrorService(injector, userData) {
        this.injector = injector;
        this.userData = userData;
    }
    ErrorService.prototype.getClientErrorMessage = function (error) {
        return error.message ?
            error.message :
            error.toString();
    };
    ErrorService.prototype.getServerErrorMessage = function (error) {
        return navigator.onLine ?
            (error.error && error.error.ExceptionMessage ? "" + error.error.ExceptionMessage : "[" + error.status + "] " + error.message) :
            'No Internet Connection';
    };
    ErrorService.prototype.getContextInfo = function (error) {
        var accountModel = this.userData.getAccount();
        var name = error.name || null;
        var clientId = accountModel ? accountModel.clientId : 'NA';
        var callerId = accountModel ? accountModel.callerId : 'NA';
        var userName = accountModel ? accountModel.userName : 'NA';
        var user = accountModel ? accountModel.firstName + " " + accountModel.lastName : 'NA';
        var time = new Date().getTime();
        var location = this.injector.get(LocationStrategy);
        var url = location instanceof PathLocationStrategy ? location.path() : '';
        var status = error instanceof HttpErrorResponse ? error.status : null;
        var message = error.message || error.toString();
        var stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);
        var errorWithContext = { name: name, clientId: clientId, callerId: callerId, userName: userName, user: user, time: time, url: url, status: status, message: message, stack: stack };
        return errorWithContext;
    };
    ErrorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.inject(i0.INJECTOR), i0.inject(i1.LocalstorageService)); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
