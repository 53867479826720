import { of } from 'rxjs';
import { MAINMENU } from '../models/main-nav';
import * as i0 from "@angular/core";
var MainMenuService = /** @class */ (function () {
    function MainMenuService() {
    }
    MainMenuService.prototype.getMenuItems = function () {
        return of(MAINMENU);
    };
    MainMenuService.ngInjectableDef = i0.defineInjectable({ factory: function MainMenuService_Factory() { return new MainMenuService(); }, token: MainMenuService, providedIn: "root" });
    return MainMenuService;
}());
export { MainMenuService };
