import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WebRequestConfig } from '../../wrcommon/classes/config';
import { CallUsContent } from '../models/call-us-content.model';

@Injectable({
  providedIn: 'root'
})
export class CallUsService {

  constructor(private http: HttpClient) {
 }

  GetRequestTypeCriticalContent(clientId: number): Observable<CallUsContent> {
    return this.http.get(`${WebRequestConfig.baseUrlClientRequest}public/GetRequestTypeCriticalContent/${clientId}`)
      .pipe(map((data: any) => CallUsContent.fromAPI(data)));
  };

  GetCallUsContent(clientId: number): Observable<CallUsContent> {
    return this.http.get(`${WebRequestConfig.baseUrlClientRequest}public/GetCallUsContent/${clientId}`)
      .pipe(map((data: any) => CallUsContent.fromAPI(data)));
  };

  GetCallUsPhoneNumber(clientId: number): Observable<string> {
    return this.http.get<string>(`${WebRequestConfig.baseUrlClientRequest}public/GetCallUsPhoneNumber/${clientId}`);
  };

  GetBGISPhoneNumber(): Observable<string> {
    return this.http.post<string>(`${WebRequestConfig.baseUrlClientRequest}public/GetBGISPhoneNumber/`, null);
  };
}
