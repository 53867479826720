import * as tslib_1 from "tslib";
import { Store } from '../models/store';
import * as i0 from "@angular/core";
var ChargeToAccountLabelStoreService = /** @class */ (function (_super) {
    tslib_1.__extends(ChargeToAccountLabelStoreService, _super);
    function ChargeToAccountLabelStoreService() {
        var _this = _super.call(this, '') || this;
        _this.enableWorkOrderChargeToAccount = true; // final decision flag
        _this.enableChargeToAccountAsMandatory = false; //final decision flag for optional or mandatory
        _this.enableCallerCallerChargeToAcctAutoPopulate = false;
        _this.enableChargeToAccountOnAllRequestTypes = false;
        _this.enableChargeToAccountOnAllRequestTypeAsMandatory = false;
        _this.enableChargeToAccountOnCMWorkOrders = false;
        _this.enableChargeToAccountOnCMWorkOrdersAsMandatory = false;
        _this.chargeToAccountBillableInd = false;
        return _this;
    }
    ChargeToAccountLabelStoreService.prototype.setChargeToAccountBasedOnBillableFlag = function (billableInd) {
        console.log('Is service billable???', billableInd);
        this.chargeToAccountBillableInd = billableInd;
        if (this.chargeToAccountBillableInd) {
            this.enableChargeToAccountAsMandatory = true;
        }
        else {
            this.enableChargeToAccountAsMandatory = this.enableChargeToAccountOnAllRequestTypeAsMandatory;
        }
        this.enableWorkOrderChargeToAccount = this.enableChargeToAccountOnAllRequestTypes || billableInd;
        console.log('Final flag enableWorkOrderChargeToAccount--->>>', this.enableWorkOrderChargeToAccount);
        console.log('Final flag enableChargeToAccountAsMandatory--->>>', this.enableChargeToAccountAsMandatory);
    };
    ChargeToAccountLabelStoreService.prototype.showChargeToAccountAsMandatoryField = function () {
        return this.chargeToAccountBillableInd ||
            (this.enableChargeToAccountOnAllRequestTypes &&
                this.enableChargeToAccountOnAllRequestTypeAsMandatory);
    };
    ChargeToAccountLabelStoreService.prototype.setChargeToAccountFields = function (data) {
        this.enableWorkOrderChargeToAccount = data.EnableWorkOrderChargeToAccount;
        this.enableCallerCallerChargeToAcctAutoPopulate = data.EnableCallerChargeToAcctAutoPopulate;
        this.enableChargeToAccountOnAllRequestTypes = data.EnableChargeToAccountOnAllRequestTypes;
        this.enableChargeToAccountOnAllRequestTypeAsMandatory = data.EnableChargeToAccountOnAllRequestTypeAsMandatory;
        this.enableChargeToAccountOnCMWorkOrders = data.EnableChargeToAccountOnCMWorkOrders;
        this.enableChargeToAccountOnCMWorkOrdersAsMandatory = data.EnableChargeToAccountOnCMWorkOrdersAsMandatory;
    };
    ChargeToAccountLabelStoreService.ngInjectableDef = i0.defineInjectable({ factory: function ChargeToAccountLabelStoreService_Factory() { return new ChargeToAccountLabelStoreService(); }, token: ChargeToAccountLabelStoreService, providedIn: "root" });
    return ChargeToAccountLabelStoreService;
}(Store));
export { ChargeToAccountLabelStoreService };
