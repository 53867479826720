import { TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/overlay";
var OverlayService = /** @class */ (function () {
    function OverlayService(overlay) {
        this.overlay = overlay;
    }
    OverlayService.prototype.createOverlay = function (config) {
        return this.overlay.create(config);
    };
    OverlayService.prototype.attachTemplatePortal = function (overlayRef, templateRef, vcRef) {
        var templatePortal = new TemplatePortal(templateRef, vcRef);
        overlayRef.attach(templatePortal);
    };
    OverlayService.prototype.positionGloballyCenter = function () {
        return this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();
    };
    OverlayService.ngInjectableDef = i0.defineInjectable({ factory: function OverlayService_Factory() { return new OverlayService(i0.inject(i1.Overlay)); }, token: OverlayService, providedIn: "root" });
    return OverlayService;
}());
export { OverlayService };
