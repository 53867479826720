import { Observable } from 'rxjs';
import { Component, Renderer2, ChangeDetectionStrategy, AfterViewInit, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material';
import { environment } from 'src/environments/environment';
import { WebRequestConfig } from 'src/app/modules/wrcommon/classes/config';
import { Language } from 'src/app/modules/wrcommon/classes/language';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { HighContrastStoreService } from '../../services/high-contrast-store.service';
import { LocalstorageService } from '../../services/localstorage.service';
import { brandVariables } from 'src/brand-variables';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit, AfterViewInit {

  isHighContrast = false;
  version: string;
  currentYear: number;
  footerCopyrightTranslationLabel: string;
  constructor(
    private userData: UserdatasharingService,
    private renderer: Renderer2,
    private storageService: LocalstorageService,
    private highContrastStoreService: HighContrastStoreService
  ) {
  }
  ngOnInit(): void {
    this.userData.getAppVersion().subscribe(res => this.version = res);
    this.currentYear = new Date().getFullYear();
    this.footerCopyrightTranslationLabel = brandVariables.footerCopyright;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isHighContrast = this.storageService.getIsHighContrast();
      this.applyContrastStyle();
    });
  }

  get currentLanguage(): string {
    return this.userData.getCurrentLanguage() === Language.French ? Language.TranslationLanguageFrench : Language.TranslationLanguageCanada;
  }

  get environment(): string {
    return WebRequestConfig.environment;
  }

  get environmentName(): string {
    return environment.name;
  }

  get environmentVersion(): string {
    return environment.version;
  }

  get buildDate(): Date {
    return new Date(environment.timeStamp);
  }

  toggleHighContrast(event: MatSlideToggleChange) {

    this.isHighContrast = event.checked;
    this.highContrastStoreService.setState(this.isHighContrast);
    this.storageService.setIsHighContrast(this.isHighContrast);

    this.applyContrastStyle();
  }

  private applyContrastStyle() {

    if (this.isHighContrast) {
      this.renderer.addClass(document.querySelector('body'), 'theme-hq');
    } else {
      this.renderer.removeClass(document.querySelector('body'), 'theme-hq');
    }
  }
}
