<div class="error-container">
  <div *ngIf="data?.error">
    <h1>{{'SideNav.Error' | translate}} {{ data?.error}}</h1>
    <h5 *ngIf="data?.error === 404">{{'error.PageNotFound' | translate}}</h5>
    <p>{{'error.PageNotFoundContent' | translate}}</p>
    <a mat-raised-button [routerLink]="'/'">
      <h5>{{'error.GoBackToHome' | translate}}</h5>
    </a>
  </div>

  <div *ngIf="routeParams.message">
    <h1 *ngIf="routeParams?.status"> {{'SideNav.Error' | translate}} {{'SideNav.Error' | translate}} routeParams?.status }}</h1><br />
    <h3 *ngIf="routeParams?.message">{{ routeParams?.message }}</h3><br />
    <h1 *ngIf="routeParams?.url && routeParams?.url !== '/'">{{'error.ErrorIn' | translate}} {{ routeParams?.url }}.</h1>
    <a mat-raised-button
       [routerLink]="routeParams?.url"
       *ngIf="routeParams?.url && routeParams.status !== '404'">
      <h5>{{'error.GoBackTo' | translate}} {{routeParams?.url}}</h5>
    </a>
    <a mat-raised-button
       routerLink="/"
       *ngIf="!routeParams.url || routeParams.status === '404'">
      <h5>{{'error.GoBackToHome' | translate}}</h5>
    </a>
  </div>

  <div *ngIf="!routeParams && !data">
    <h1>{{'error.UnknownError' | translate}}</h1>
    <a mat-raised-button routerLink="/">
      <h5>{{'error.GoBackToHome' | translate}}</h5>
    </a>
  </div>
</div>

<div class="pre-container" [hidden]="isProductionEnvironment" *ngIf="routeParams?.message">
  <p>Error Summary</p>
  <pre>
    {{ this.routeParams | json }}
  </pre>
</div>
