import * as tslib_1 from "tslib";
import { Renderer2, OnInit } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { LoaderService } from 'src/app/modules/core/services/loader.service';
import { TenantService } from 'src/app/modules/core/services/tenant.service';
import { UserdatasharingService } from 'src/app/modules/core/services/userdatasharing.service';
import { Constants } from 'src/app/modules/wrcommon/classes/constants';
import { TranslationService } from 'src/app/modules/wrcommon/services/translation.service';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { Language } from 'src/app/modules/wrcommon/classes/language';
import { LocalstorageService } from 'src/app/modules/core/services/localstorage.service';
import { WebRequestConfig } from 'src/app/modules/wrcommon/classes/config';
var AppComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AppComponent, _super);
    function AppComponent(loaderService, tenantService, userService, renderer, translationService, userIdle, storageService) {
        var _this = _super.call(this) || this;
        _this.loaderService = loaderService;
        _this.tenantService = tenantService;
        _this.userService = userService;
        _this.renderer = renderer;
        _this.translationService = translationService;
        _this.userIdle = userIdle;
        _this.storageService = storageService;
        _this.title = Constants.WebAssist;
        _this.isLoading = false;
        // used for all other routes with racing support
        _this.loaderService.state$
            .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(_this.destroyed))
            .subscribe(function (v) {
            _this.isLoading = v;
        });
        _this.userService.currentLanguage
            .pipe(takeUntil(_this.destroyed))
            .subscribe(function (lang) {
            var locale = Language.SupportedLanguages.getKey(lang);
            _this.renderer.setAttribute(document.querySelector('html'), 'lang', locale);
            _this.translationService.setCurrentLanguage(locale);
        });
        _this.tenantService.state$
            .pipe(takeUntil(_this.destroyed))
            .subscribe(function (tenant) {
            var theme = 'theme-' + (tenant ? tenant.Theme : 'bgis');
            var regex = /^theme-([a-zA-Z])+$/;
            var classes = Array.from(document.querySelector('body').classList); // get all classes
            classes.forEach(function (cl) {
                if (cl.match(regex)) {
                    _this.renderer.removeClass(document.querySelector('body'), cl);
                }
            });
            _this.renderer.addClass(document.querySelector('body'), theme);
            var isHighContrast = _this.storageService.getIsHighContrast();
            if (isHighContrast) {
                _this.renderer.addClass(document.querySelector('body'), 'theme-hq');
            }
            else {
                _this.renderer.removeClass(document.querySelector('body'), 'theme-hq');
            }
        });
        return _this;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        //Start watching for user inactivity.
        this.userIdle.startWatching();
        // Start watching when user idle is starting and reset if user action is there.
        this.userIdle.onTimerStart().subscribe(function (count) {
            var eventList = ['click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'keyup'];
            for (var _i = 0, eventList_1 = eventList; _i < eventList_1.length; _i++) {
                var event_1 = eventList_1[_i];
                document.body.addEventListener(event_1, function () { return _this.userIdle.resetTimer(); });
            }
        });
        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!!this.storageService.getAccount()) return [3 /*break*/, 2];
                        _a = alert;
                        return [4 /*yield*/, this.translationService.translate('session.Timeout')];
                    case 1:
                        _a.apply(void 0, [_b.sent()]);
                        window.location.href = WebRequestConfig.baseUrlRealSuite + "User/logout?redirectUrl=" + WebRequestConfig.baseUrlWebAssist + "login?logout=true";
                        return [3 /*break*/, 3];
                    case 2:
                        this.userIdle.resetTimer();
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    return AppComponent;
}(DestroyableComponentBase));
export { AppComponent };
