import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { clearPassword } from '../models/accountmodel';
import { LocalstorageService } from './localstorage.service';
import { CallUsRequestService } from './call-us-request.service';
import { Language } from '../../wrcommon/classes/language';
import { WebRequestConfig } from '../../wrcommon/classes/config';
import * as i0 from "@angular/core";
import * as i1 from "./localstorage.service";
import * as i2 from "./call-us-request.service";
import * as i3 from "@angular/common/http";
var UserdatasharingService = /** @class */ (function () {
    function UserdatasharingService(storageService, callUsRequestService, http) {
        this.storageService = storageService;
        this.callUsRequestService = callUsRequestService;
        this.http = http;
        this.INITIAL_VALUE = {
            userId: -1,
            clientId: -1,
            clientCode: '',
            firstName: '',
            lastName: '',
            userName: '',
            email: '',
            phoneNumber: '',
            phoneNumberCountryCode: '',
            password: '',
            confirmPassword: '',
            RetCode: null,
            ErrMsg: null,
            RememberMe: false,
            language: Language.English,
            requestReceivedNotification: false,
            requestAssignedNotification: true,
            arrivedNotification: false,
            inProgressNotification: true,
            completeNotification: false,
            hasWorkOrders: null,
            AccessToken: null,
            IsFederated: null
        };
        this.isCurrentUserAuthenticated$ = new BehaviorSubject(false);
        this.currentLanguage$ = new BehaviorSubject(Language.getLanguageFromBrowser());
        this.currentUser$ = new BehaviorSubject(this.INITIAL_VALUE);
    }
    Object.defineProperty(UserdatasharingService.prototype, "currentUser", {
        get: function () {
            return this.currentUser$.value;
        },
        enumerable: true,
        configurable: true
    });
    UserdatasharingService.prototype.addData = function (currentUser) {
        if (currentUser == null) {
            throw new Error("Invalid currentUser: currentUser == null.");
        }
        this.storageService.addAccount(clearPassword(currentUser));
        this.currentUser$.next(currentUser);
        this.isCurrentUserAuthenticated$.next(currentUser != null && currentUser.userId > 0);
        this.callUsRequestService.init(currentUser ? currentUser.clientId : -1);
    };
    UserdatasharingService.prototype.clear = function () {
        this.storageService.deleteAccount();
        this.storageService.deleteTenantUser();
        this.currentUser$.next(this.INITIAL_VALUE);
        this.isCurrentUserAuthenticated$.next(false);
        this.callUsRequestService.init(-1);
    };
    UserdatasharingService.prototype.getData = function () {
        var currentUser = this.storageService.getAccount();
        this.currentUser$.next(currentUser);
        this.isCurrentUserAuthenticated$.next(currentUser != null && currentUser.userId > 0);
        this.callUsRequestService.init(currentUser ? currentUser.clientId : -1);
        return this.currentUser$.asObservable();
    };
    UserdatasharingService.prototype.isAuthenticated = function () {
        var result = false;
        var tenant = this.storageService.getTenant();
        var currentUser = this.storageService.getAccount();
        if (!!currentUser && !!tenant && currentUser.clientId === tenant.rsClientId && currentUser.userId > 0) {
            result = true;
        }
        this.isCurrentUserAuthenticated$.next(result);
        return this.isCurrentUserAuthenticated$.asObservable();
    };
    Object.defineProperty(UserdatasharingService.prototype, "isRegistered", {
        get: function () {
            var tenant = this.storageService.getTenant();
            return (!!tenant && !!tenant.IsUserRegistered && tenant.CallerId > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserdatasharingService.prototype, "showTabs", {
        get: function () {
            var tenant = this.storageService.getTenant();
            if (!tenant) {
                return false;
            }
            var enableLogin = this.isRegistered || !tenant.LoginName;
            var enableSignup = !this.isRegistered && (!tenant.ssoFederated || !!tenant.LoginName);
            return enableLogin && enableSignup;
        },
        enumerable: true,
        configurable: true
    });
    UserdatasharingService.prototype.getClientId = function () {
        var result = null;
        var currentUser = this.storageService.getAccount();
        if (currentUser != null && currentUser.clientId > 0) {
            result = currentUser.clientId;
        }
        return result;
    };
    UserdatasharingService.prototype.getUserId = function () {
        var result = null;
        var currentUser = this.storageService.getAccount();
        if (currentUser != null && currentUser.userId > 0) {
            result = currentUser.userId;
        }
        return result;
    };
    UserdatasharingService.prototype.getCallerId = function () {
        var result = null;
        var currentUser = this.storageService.getAccount();
        if (currentUser != null && currentUser.callerId > 0) {
            result = currentUser.callerId;
        }
        return result;
    };
    UserdatasharingService.prototype.getAccessToken = function () {
        var result = null;
        var currentUser = this.storageService.getAccount();
        if (currentUser != null) {
            result = currentUser.AccessToken;
        }
        return result;
    };
    UserdatasharingService.prototype.setHasWorkOrders = function () {
        var currentUser = this.storageService.getAccount();
        currentUser.hasWorkOrders = true;
        this.addData(currentUser);
    };
    Object.defineProperty(UserdatasharingService.prototype, "currentLanguage", {
        get: function () {
            return this.currentLanguage$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    UserdatasharingService.prototype.getAppVersion = function () {
        return this.http.get(WebRequestConfig.baseUrlClientRequest + "public/appversion");
    };
    ;
    UserdatasharingService.prototype.setCurrentLanguage = function (currentLanguage) {
        if (!Language.SupportedLanguages.hasValue(currentLanguage)) {
            return;
        }
        if (this.currentLanguage$.value != currentLanguage) {
            this.currentLanguage$.next(currentLanguage);
        }
        var currentUser = this.storageService.getAccount();
        if (!!currentUser) {
            currentUser.language = currentLanguage;
            this.addData(currentUser);
        }
    };
    UserdatasharingService.prototype.getCurrentLanguage = function () {
        var result = this.currentLanguage$.value;
        var lang = this.getParameterByName(Language.LangQueryString);
        if (Language.SupportedLanguages.hasKey(lang)) {
            result = Language.SupportedLanguages.getValue(lang);
            this.setCurrentLanguage(result);
        }
        else if (this.currentLanguage$.value) {
            result = this.currentLanguage$.value;
        }
        else {
            result = Language.getLanguageFromBrowser();
        }
        return result;
    };
    UserdatasharingService.prototype.getCurrentLocale = function () {
        return Language.SupportedLanguages.getKey(this.getCurrentLanguage());
    };
    UserdatasharingService.prototype.getParameterByName = function (name, url) {
        if (url === void 0) { url = window.location.href; }
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);
        if (!results)
            return null;
        if (!results[2])
            return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };
    UserdatasharingService.ngInjectableDef = i0.defineInjectable({ factory: function UserdatasharingService_Factory() { return new UserdatasharingService(i0.inject(i1.LocalstorageService), i0.inject(i2.CallUsRequestService), i0.inject(i3.HttpClient)); }, token: UserdatasharingService, providedIn: "root" });
    return UserdatasharingService;
}());
export { UserdatasharingService };
