import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { KeysPipe } from './pipes/keys.pipe';
import { AsyncTranslatePipe } from './pipes/translate.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { TruncateTextPipe } from './pipes/truncatetext.pipe';
import { FocusDirective } from './directives/focus.directive';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { FileTypeIconPipe } from './pipes/fileTypeIcon.pipe';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    SearchInputComponent,
    InfoIconComponent,
    InfoDialogComponent,
    KeysPipe,
    AsyncTranslatePipe,
    SafePipe,
    HighlightPipe,
    PhonePipe,
    TruncateTextPipe,
    FocusDirective,
    FileTypeIconPipe
  ],
  imports: [
    CommonModule,
    AngularSvgIconModule,
    MaterialModule,
    FlexLayoutModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
    SearchInputComponent,
    InfoIconComponent,
    KeysPipe,
    AsyncTranslatePipe,
    SafePipe,
    HighlightPipe,
    PhonePipe,
    TruncateTextPipe,
    FocusDirective,
    CommonModule,
    AngularSvgIconModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FileTypeIconPipe
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    InfoDialogComponent
  ]
})
export class WrcommonModule {
  constructor() {
  }
}
