import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export enum AlertIcon {
  alert = "warning",
  error = "error",
  notification = "notification_important"
}

export interface AlertConfig {
  icon: AlertIcon,
  title: string,
  content: string,
  callUs: string,
  phoneNumber: string
}

@Component({
  selector: 'app-emergency-request-alert',
  templateUrl: './emergency-request-alert.component.html',
  styleUrls: ['./emergency-request-alert.component.scss']
})
export class EmergencyRequestAlertComponent {

  get alertData(): AlertConfig {
    return this.data;
  }

  constructor(public alertRef: MatDialogRef<EmergencyRequestAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertConfig) {
  }
}
