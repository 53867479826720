import * as tslib_1 from "tslib";
import { DefaultUrlSerializer } from '@angular/router';
var LowerCaseUrlSerializer = /** @class */ (function (_super) {
    tslib_1.__extends(LowerCaseUrlSerializer, _super);
    function LowerCaseUrlSerializer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LowerCaseUrlSerializer.prototype.parse = function (url) {
        var urlParts = url.split('?');
        urlParts[0] = urlParts[0].toLowerCase();
        return _super.prototype.parse.call(this, urlParts.join('?'));
    };
    return LowerCaseUrlSerializer;
}(DefaultUrlSerializer));
export { LowerCaseUrlSerializer };
