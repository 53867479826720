import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebRequestConfig } from '../../wrcommon/classes/config';

export interface UserInfo {
  LoginName: string,
  Email: string,
  FirstName: string,
  LastName: string,
  FullName: string,
  Phone: string,
  UserId: number,
  Delegator: string
}

export interface ClientProfile {
  client_id?: string,
  ssoFederated?: string,
  success?: boolean,
  error?: string,
  error_description?: string
}

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private http: HttpClient) {
  }

  GetUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`${WebRequestConfig.baseUrlRealSuite}User/UserInfo`);
  };

  GetClientProfile(rsClientId: number): Observable<ClientProfile> {
    const url = `${WebRequestConfig.baseUrlRealSuiteUserAuth}ClientProfile?rsClientId=${rsClientId}&Scope=WEB.ASSIST`;
    return this.http.get<ClientProfile>(url);
  }
}
