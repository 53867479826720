/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overlayspinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./overlayspinner.component";
import * as i8 from "../../services/overlay.service";
var styles_OverlaySpinnerComponent = [i0.styles];
var RenderType_OverlaySpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverlaySpinnerComponent, data: {} });
export { RenderType_OverlaySpinnerComponent as RenderType_OverlaySpinnerComponent };
function View_OverlaySpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(1, 49152, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"], strokeWidth: [2, "strokeWidth"], mode: [3, "mode"], value: [4, "value"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.color; var currVal_8 = _co.diameter; var currVal_9 = _co.strokeWidth; var currVal_10 = _co.mode; var currVal_11 = _co.value; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; var currVal_3 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 100 : null); var currVal_5 = i1.ɵnov(_v, 1).value; var currVal_6 = i1.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_OverlaySpinnerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { progressSpinnerRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["progressSpinnerRef", 2]], null, 0, null, View_OverlaySpinnerComponent_1))], null, null); }
export function View_OverlaySpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-overlayspinner", [], null, null, null, View_OverlaySpinnerComponent_0, RenderType_OverlaySpinnerComponent)), i1.ɵdid(1, 507904, null, 0, i7.OverlaySpinnerComponent, [i1.ViewContainerRef, i8.OverlayService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverlaySpinnerComponentNgFactory = i1.ɵccf("app-overlayspinner", i7.OverlaySpinnerComponent, View_OverlaySpinnerComponent_Host_0, { color: "color", diameter: "diameter", mode: "mode", strokeWidth: "strokeWidth", value: "value", backdropEnabled: "backdropEnabled", positionGloballyCenter: "positionGloballyCenter", displayProgressSpinner: "displayProgressSpinner" }, {}, []);
export { OverlaySpinnerComponentNgFactory as OverlaySpinnerComponentNgFactory };
