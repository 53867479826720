import { MatDialogRef } from '@angular/material';
export var AlertIcon;
(function (AlertIcon) {
    AlertIcon["alert"] = "warning";
    AlertIcon["error"] = "error";
    AlertIcon["notification"] = "notification_important";
})(AlertIcon || (AlertIcon = {}));
var EmergencyRequestAlertComponent = /** @class */ (function () {
    function EmergencyRequestAlertComponent(alertRef, data) {
        this.alertRef = alertRef;
        this.data = data;
    }
    Object.defineProperty(EmergencyRequestAlertComponent.prototype, "alertData", {
        get: function () {
            return this.data;
        },
        enumerable: true,
        configurable: true
    });
    return EmergencyRequestAlertComponent;
}());
export { EmergencyRequestAlertComponent };
