import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Constants } from '../../wrcommon/classes/constants';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === Constants.HttpCodeNotFound && error.url.endsWith('.svg')) {
          const defaultImageUrl = error.url.replace(/([a-zA-Z0-9\s_\\.\-\(\):])+(.svg)$/i, 'default.svg');
          console.log(`The path to the image: ${error.url} doesn't exist. Trying to use the default image from path: ${defaultImageUrl}.`);
          const dupReq = request.clone({ url: defaultImageUrl });
          return next.handle(dupReq).pipe(
            catchError((error: HttpErrorResponse) => throwError(error)));
        }
        return throwError(error);
      })
    );
  }
}
