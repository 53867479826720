import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { EMPTY } from 'rxjs'
import { EmergencyRequestAlertComponent, AlertIcon } from '../components/emergency-request-alert/emergency-request-alert.component';
import { CallUsService } from './call-us.service';
import { CallUsContent } from '../models/call-us-content.model';

@Injectable({
  providedIn: 'root'
})
export class CallUsRequestService {

  private callUsContent: CallUsContent;
  private requestTypeCriticalContent: CallUsContent;

  private clientId: number;
  private clientPhoneNumber: string = null;
  private globalPhoneNumber: string;
  private bgisPhoneNumber: string;

  get callUsPhone(): string {
    return this.clientPhoneNumber || this.globalPhoneNumber || this.bgisPhoneNumber || '1-877-353-4543';
  }

  constructor(private callUsService: CallUsService,
    public dialog: MatDialog) {

    this.callUsService.GetBGISPhoneNumber()
      .pipe(take(1))
      .subscribe(bgisPhoneNumber => this.bgisPhoneNumber = bgisPhoneNumber);

    this.callUsService.GetCallUsPhoneNumber(-1)
      .pipe(take(1))
      .subscribe(globalPhoneNumber => {
        this.globalPhoneNumber = globalPhoneNumber;
      });
  }

  init(clientId: number): void {

    // check if client was changed
    if (this.clientId === clientId) {
      return;
    }

    this.clientId = clientId;

    if (!this.clientId || this.clientId <= 0) {
      this.clientPhoneNumber = null;
      return;
    }

    this.callUsService.GetCallUsPhoneNumber(this.clientId)
      .pipe(take(1))
      .subscribe(clientPhoneNumber => {
        this.clientPhoneNumber = clientPhoneNumber;
      });

    this.callUsService.GetCallUsContent(this.clientId)
      .pipe(take(1))
      .subscribe(callUsContent => {
        this.callUsContent = callUsContent;
      });

    this.callUsService.GetRequestTypeCriticalContent(this.clientId)
      .pipe(take(1))
      .subscribe(requestTypeCriticalContent => this.requestTypeCriticalContent = requestTypeCriticalContent);
  }

  openEmergencyRequestKeywordAlert(emergencyKeywords: string[]): Promise<boolean> {
    if (!this.callUsContent) {
      return EMPTY.toPromise();
    }

    const callUsText = this.callUsContent.callUsTextEmergencyWords.replace(/{keyword}/g, `"${emergencyKeywords.join(', ')}"`);
    return this.openEmergencyRequestAlert(AlertIcon.alert, 'EmergencyRequestTitle', callUsText, this.callUsPhone);
  }

  openRequestTypeCriticalContentAlert() {
    if (!this.requestTypeCriticalContent) {
      return EMPTY.toPromise();
    }

    this.openEmergencyRequestAlert(AlertIcon.error, 'CriticalSiteTitle', this.requestTypeCriticalContent.callUsText, this.requestTypeCriticalContent.callUsPhoneNumber || this.callUsPhone);
  }

  openDuplicateDisabledContentAlert() {
    if (!this.callUsContent) {
      return EMPTY.toPromise();
    }

    this.openEmergencyRequestAlert(AlertIcon.alert, 'EmergencyRequestTitle', this.callUsContent.duplicateDisabledText, this.callUsPhone);
  }

  openCallUsContentAlert() {
    if (!this.callUsContent) {
      return EMPTY.toPromise();
    }

    this.openEmergencyRequestAlert(AlertIcon.alert, 'EmergencyRequestTitle', this.callUsContent.callUsText, this.callUsPhone);
  }

  private async openEmergencyRequestAlert(alertIcon: AlertIcon, title: string, content: string, phoneNumber: string): Promise<boolean> {

    const dialogRef = this.dialog.open(EmergencyRequestAlertComponent, {
      data: {
        icon: alertIcon,
        //title: 'alert.' + title,
        content: content,
        callUs: 'alert.CallUs',
        phoneNumber: phoneNumber
      },
      disableClose: true,
      hasBackdrop: true,
      restoreFocus: true,
      role: 'alertdialog'
    });

    return dialogRef.afterClosed().toPromise<boolean>();
  }
}
