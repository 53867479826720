import { Observable, of } from 'rxjs';
import { MAINMENU } from '../models/main-nav';
import { Injectable } from '@angular/core';
import { MainNav } from '../models/main-nav.model';

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  constructor() {}

  getMenuItems(): Observable<MainNav[]> {
    return of(MAINMENU);
  }
}
