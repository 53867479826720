import { ChangeDetectorRef, Pipe, PipeTransform, WrappedValue } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class AsyncTranslatePipe implements PipeTransform {
  private value: string;
  private originalKey: string;

  constructor(
    private translationService: TranslationService,
    private _ref: ChangeDetectorRef) {
  }

  transform(key: string, ...args: any[]): WrappedValue | string {
    if (key === this.originalKey) {
      return this.value;
    } else {
      this.translationService.translate(key)
        .then((val: string) => {
          if (args && args.length >= 2 && args[0] && args[1]) {
            val = val.replace(new RegExp(args[0], 'g'), args[1]);
            if (args.length >= 4 && args[2] && args[3]) {
              val = val.replace(new RegExp(args[2], 'g'), args[3]);
            }
          }
          this.value = val;
          this._ref.markForCheck();
        });
    }

    this.originalKey = key;
    this.value = key;

    return WrappedValue.wrap(key);
  }
}
