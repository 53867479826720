import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
var ErrorComponent = /** @class */ (function () {
    function ErrorComponent(activatedRoute) {
        this.activatedRoute = activatedRoute;
    }
    ErrorComponent.prototype.ngOnInit = function () {
        this.routeParams = this.activatedRoute.snapshot.queryParams;
        this.data = this.activatedRoute.snapshot.data;
    };
    Object.defineProperty(ErrorComponent.prototype, "isProductionEnvironment", {
        get: function () {
            return environment.production;
        },
        enumerable: true,
        configurable: true
    });
    return ErrorComponent;
}());
export { ErrorComponent };
