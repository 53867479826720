import { Injectable } from '@angular/core';
import { Store } from '../models/store';

@Injectable({
  providedIn: 'root'
})
export class LoaderService extends Store<boolean> {

  constructor() { super(false); }
}
