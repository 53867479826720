/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sso-login.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../wrcommon/pipes/translate.pipe";
import * as i3 from "../../../wrcommon/services/translation.service";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./sso-login.component";
import * as i10 from "../../services/localstorage.service";
import * as i11 from "../../services/userdatasharing.service";
import * as i12 from "@angular/router";
var styles_SSOLoginComponent = [i0.styles];
var RenderType_SSOLoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SSOLoginComponent, data: {} });
export { RenderType_SSOLoginComponent as RenderType_SSOLoginComponent };
export function View_SSOLoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "tab-content"]], [[2, "no-tabs", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 3, "a", [["class", "mat-raised-button mat-primary"], ["mat-raised-button", ""], ["style", "float:right; margin-top: 30px;"]], [[8, "href", 4], [1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatAnchor_0, i4.RenderType_MatAnchor)), i1.ɵdid(8, 180224, null, 0, i5.MatAnchor, [i6.Platform, i7.FocusMonitor, i1.ElementRef, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(9, 0, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasTabHeaders; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("login.WelcomeBack")); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("login.ClickLoginButton")); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.ssoLoginUrl, ""); var currVal_4 = (i1.ɵnov(_v, 8).disabled ? (0 - 1) : (i1.ɵnov(_v, 8).tabIndex || 0)); var currVal_5 = (i1.ɵnov(_v, 8).disabled || null); var currVal_6 = i1.ɵnov(_v, 8).disabled.toString(); var currVal_7 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("login.Signon")); _ck(_v, 9, 0, currVal_8); }); }
export function View_SSOLoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sso-login", [], null, null, null, View_SSOLoginComponent_0, RenderType_SSOLoginComponent)), i1.ɵdid(1, 245760, null, 0, i9.SSOLoginComponent, [i10.LocalstorageService, i11.UserdatasharingService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SSOLoginComponentNgFactory = i1.ɵccf("app-sso-login", i9.SSOLoginComponent, View_SSOLoginComponent_Host_0, {}, {}, []);
export { SSOLoginComponentNgFactory as SSOLoginComponentNgFactory };
