<div class="emergency-modal">
  <div class="modal-header">
    <button mat-icon-button
            [mat-dialog-close]="false"
            [attr.aria-label]="'shared.Close' | translate">
      <svg-icon class="icon-close"
                role="presentation"
                focusable="true"
                src="assets/images/icons/close.svg"></svg-icon>
      <span class="sr-only">{{'shared.Close' | translate}}</span>
    </button>
  </div>
  <svg-icon class="icon-warning"
            role="presentation"
            focusable="true"
            src="assets/images/icons/warning-round.svg">
  </svg-icon>
  <div class="mat-dialog-title">
    {{contentData.title | translate}}
  </div>
  <div mat-dialog-content>
    <div class="mat-dialog-text">{{contentData.content | translate}}</div>
  </div>
  <div mat-dialog-actions class="button-container mt-10">
    <button mat-raised-button color="primary" type="button" [mat-dialog-close]="false">{{'shared.Cancel' | translate}}</button>
    <button mat-raised-button color="primary" type="button" [mat-dialog-close]="true" cdkFocusInitial>{{'shared.Confirm' | translate}}</button>
  </div>
</div>
