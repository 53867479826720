import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Store } from '../models/store';
import { AccountService } from '../../shared-account/services/account.service';
import { UserInfoService } from './userinfo.service';
import { UserdatasharingService } from './userdatasharing.service';
import { LoggingService } from './logging.service';
import { LocalstorageService } from './localstorage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./localstorage.service";
import * as i3 from "./userinfo.service";
import * as i4 from "../../shared-account/services/account.service";
import * as i5 from "./userdatasharing.service";
import * as i6 from "./logging.service";
var TenantService = /** @class */ (function (_super) {
    tslib_1.__extends(TenantService, _super);
    function TenantService(router, storageService, userInfoService, accountService, userDataService, logger) {
        var _this = _super.call(this, {
            Host: 'bgis',
            Theme: 'bgis'
        }) || this;
        _this.router = router;
        _this.storageService = storageService;
        _this.userInfoService = userInfoService;
        _this.accountService = accountService;
        _this.userDataService = userDataService;
        _this.logger = logger;
        return _this;
    }
    TenantService.prototype.canActivate = function (route, state) {
        var _this = this;
        //const host: string = route.params['client'];
        var rsClientId = +route.params['rsClientId'];
        if (!rsClientId) {
            return true;
        }
        var sessionId = route.queryParams['sessionId'];
        var currentTenant = {
            rsClientId: rsClientId,
            Host: 'bgis',
            Theme: 'bgis',
            SessionId: sessionId
        };
        this.storageService.setTenant(currentTenant);
        this.accountService.getClientCode(rsClientId)
            .pipe(take(1))
            .subscribe(function (clientCode) {
            if (!!clientCode) {
                currentTenant.Host = clientCode.toLowerCase();
            }
        });
        this.userInfoService.GetClientProfile(rsClientId)
            .pipe(take(1))
            .subscribe(function (clientProfile) {
            if (clientProfile.success) {
                currentTenant.rsClientCertificate = clientProfile.client_id;
                currentTenant.ssoFederated = clientProfile.ssoFederated == 'Y';
                _this.userInfoService.GetUserInfo()
                    .pipe(take(1))
                    .subscribe(function (userInfo) {
                    if (!userInfo.UserId) {
                        _this.logger.warn("The current user: " + userInfo.LoginName + " has no userId.");
                        return _this.router.navigate(["/registration/clientcode"]);
                    }
                    _this.accountService.getUserAccount(userInfo.LoginName, userInfo.UserId)
                        .pipe(take(1))
                        .subscribe(function (account) {
                        if (account.ClientId && account.ClientId !== rsClientId) {
                            _this.logger.error("The User Account client: " + account.ClientId + " != rsClient: " + rsClientId);
                            return _this.router.navigate(["/access-forbidden"]);
                        }
                        currentTenant.LoginName = userInfo.LoginName;
                        currentTenant.UserId = userInfo.UserId;
                        currentTenant.Email = !!account.Email ? account.Email : userInfo.Email;
                        currentTenant.FirstName = !!account.FirstName ? account.FirstName : userInfo.FirstName;
                        currentTenant.LastName = !!account.LastName ? account.LastName : userInfo.LastName;
                        currentTenant.Phone = !!account.PhoneNumber ? account.PhoneNumber : userInfo.Phone;
                        currentTenant.IsUserRegistered = account.IsUserRegistered;
                        currentTenant.CallerId = account.CallerId;
                        _this.setState(currentTenant);
                        _this.storageService.setTenant(currentTenant);
                        if (account.IsUserRegistered && account.CallerId) {
                            var accountModel = _this.userDataService.currentUser;
                            accountModel.clientId = account.ClientId;
                            accountModel.userName = userInfo.LoginName;
                            accountModel.userId = userInfo.UserId;
                            accountModel.email = account.Email;
                            accountModel.firstName = account.FirstName;
                            accountModel.lastName = account.LastName;
                            accountModel.phoneNumber = account.PhoneNumber;
                            accountModel.phoneNumberCountryCode = account.PhoneNumberCountryCode;
                            accountModel.callerId = account.CallerId;
                            accountModel.language = account.Language;
                            accountModel.requestReceivedNotification = account.RequestReceivedNotification;
                            accountModel.requestAssignedNotification = account.RequestAssignedNotification;
                            accountModel.arrivedNotification = account.ArrivedNotification;
                            accountModel.inProgressNotification = account.InProgressNotification;
                            accountModel.completeNotification = account.CompleteNotification;
                            _this.userDataService.addData(accountModel);
                            // main page
                            _this.router.navigate(["/login"]);
                        }
                        else {
                            _this.router.navigate(["/registration/clientcode"]);
                        }
                    });
                }, function (err) {
                    _this.logger.warn("Error getting the user info. Error: " + err.message);
                    // remove account from session storage if exists
                    _this.userDataService.clear();
                    _this.router.navigate(['/login']); // main login
                })
                    .add(function () {
                    _this.setState(currentTenant);
                    _this.storageService.setTenant(currentTenant);
                    return false;
                });
            }
            else {
                _this.logger.error("Error getting the client profile. Error: " + clientProfile.error + " - " + clientProfile.error_description);
                // permission error page
                _this.router.navigate(['/access-forbidden']);
                return false;
            }
        }, function (err) {
            _this.logger.error("Error getting the client profile. Error: " + err.message);
            // access forbidden error page
            _this.setState(currentTenant);
            _this.storageService.setTenant(currentTenant);
            _this.router.navigate(['/access-forbidden']);
            return false;
        });
    };
    TenantService.ngInjectableDef = i0.defineInjectable({ factory: function TenantService_Factory() { return new TenantService(i0.inject(i1.Router), i0.inject(i2.LocalstorageService), i0.inject(i3.UserInfoService), i0.inject(i4.AccountService), i0.inject(i5.UserdatasharingService), i0.inject(i6.LoggingService)); }, token: TenantService, providedIn: "root" });
    return TenantService;
}(Store));
export { TenantService };
