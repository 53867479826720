import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from './logging.service';
import { ErrorService } from './error.service';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {
  }

  handleError(error: Error | HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);
    const router = this.injector.get(Router);

    // Always log errors
    console.error(error);

    let errorWithContextInfo = errorService.getContextInfo(error);
    let message: string;

    if (error instanceof HttpErrorResponse) {
      // Server error
      message = errorService.getServerErrorMessage(error);
      logger.error(message);

      if (error.error && error.error.Message && error.error.Message == "Security error") {

        notifier.showError("Your security token has expired. Did you recently login to RealSuite on the same computer? Please login again to continue working.");
      }
      else {
        notifier.showError(message);
      }
    } else {
      // Client Error
      message = errorService.getClientErrorMessage(error);
      logger.error(message);
      router.navigate(['/error'], { queryParams: errorWithContextInfo });
    }
  }
}
