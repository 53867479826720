import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlSegment, Route } from '@angular/router';
import { LocalstorageService } from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild  {

  constructor(private router: Router, private userData: LocalstorageService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canNavigate(state.url);
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canNavigate(state.url);
  }

  canLoad(route: Route): boolean {
    return this.canNavigate(route.path);
  }

  private canNavigate(url: string): boolean {
    if (this.isAuthenticated()) {
      return true;
    }

    this.router.navigate(["/login"], { queryParams: { redirectUrl: url } });
    return false;
  }

  private isAuthenticated(): boolean {
    const tenant = this.userData.getTenant();
    const account = this.userData.getAccount();
    return !!account && !!tenant && account.clientId === tenant.rsClientId && account.userId > 0;
  }
}
