import { BrowserModule, Title } from '@angular/platform-browser';
import { UserIdleModule } from 'angular-user-idle';
import { NgModule, LOCALE_ID, APP_INITIALIZER } from "@angular/core";
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatPaginatorIntl } from '@angular/material';
import { UrlSerializer } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppRoutingModule } from "./app-routing.module";

import { CoreModule } from './modules/core/core.module';
import { WrcommonModule } from './modules/wrcommon/wrcommon.module';
import { MaterialModule } from './material.module';

import { AppComponent } from './components/app/app.component';
import { UserdatasharingService } from './modules/core/services/userdatasharing.service';
import { PaginatorLocale } from './modules/wrcommon/classes/paginator-locale';
import { LowerCaseUrlSerializer } from './modules/core/models/url-serializer';
import { AppConfig } from './modules/core/services/app-config.service';

import { TranslationService } from './modules/wrcommon/services/translation.service';
import { Language } from './modules/wrcommon/classes/language';

import configSettings from 'src/assets/config/config.json';
import { environment } from 'src/environments/environment';

import localeFr from '@angular/common/locales/fr';
import localeGe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeFr, Language.TranslationLanguageFrench);
registerLocaleData(localeGe, Language.TranslationLanguageGerman);
registerLocaleData(localeEs, Language.TranslationLanguageSpanish);
registerLocaleData(localeIt, Language.TranslationLanguageItalian);
registerLocaleData(localeJa, Language.TranslationLanguageJapanese);
registerLocaleData(localePt, Language.TranslationLanguagePortuguese);
registerLocaleData(localeRu, Language.TranslationLanguageRussian);
registerLocaleData(localeEn, Language.TranslationLanguageEnglish);

export function appInit(translationService: TranslationService) {
  return () => translationService.forceLoad();
}

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function baseHrefFactory() {
  let expectedBaseHref = '/' + environment.baseUrlWebAssist;
  const currentBaseHref = window.location.pathname.substr(0, expectedBaseHref.length);
  if (environment.baseUrl.startsWith('../')) {
    if (expectedBaseHref !== currentBaseHref) {
      window.location.href = window.location.href.replace(currentBaseHref, expectedBaseHref);
    }
  } else {
    expectedBaseHref = '/';
  }
  return expectedBaseHref;
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    UserIdleModule.forRoot({ idle: 600, timeout: configSettings.SessionTimeoutInSeconds, ping: 60 }),
    HttpClientModule,
    BrowserAnimationsModule,

    FlexLayoutModule,
    MaterialModule,
    AngularSvgIconModule,

    CoreModule,
    AppRoutingModule,
    WrcommonModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    Title,
    {
      provide: LOCALE_ID,
      deps: [UserdatasharingService],
      useFactory: (userService) => userService.getCurrentLocale() // Currency/Date/Number pipes should pick up the locale
    },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorLocale
    },
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [TranslationService]
    },
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig], multi: true
    },
    {
      provide: APP_BASE_HREF,
      useFactory: baseHrefFactory
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
