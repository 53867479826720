import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {

  transform(phoneValue: string, country: string): any {
    try {

      // Get an instance of `PhoneNumberUtil`.
      const phoneUtil = PhoneNumberUtil.getInstance();

      // Add a leading '+' sign if not available. Assumption is all numbers contain country code prefixed.
      const newValue = phoneValue.length > 0 && phoneValue.charAt(0) != "+" ? "+" + phoneValue : phoneValue;

      // Format the number.
      const parsedPhoneObj = phoneUtil.parse(newValue, country);

      return phoneUtil.format(parsedPhoneObj, PhoneNumberFormat.NATIONAL);

    } catch (error) {
      return phoneValue;
    }
  }

}
