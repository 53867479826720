import { Router } from "@angular/router";
import { ChargeToAccountLabelStoreService } from 'src/app/modules/core/services/charge-to-account-label-store.service';
import { LocationType } from 'src/app/modules/shared-location/models/locationType';
import { WorkOrderRequestStoreService } from './work-order-request-store.service';
import { AdditionalInfo } from '../models/work-order-request.model';
import * as i0 from "@angular/core";
import * as i1 from "./work-order-request-store.service";
import * as i2 from "@angular/router";
import * as i3 from "../../core/services/charge-to-account-label-store.service";
var WorkOrderRequestStepperService = /** @class */ (function () {
    function WorkOrderRequestStepperService(workOrderRequestStore, router, chargeToAccountLabelStoreService) {
        this.workOrderRequestStore = workOrderRequestStore;
        this.router = router;
        this.chargeToAccountLabelStoreService = chargeToAccountLabelStoreService;
        this._lastCompletedStepIndex = -1;
        this._steps = [
            { stepIndex: 0, template: '/newrequest/location', title: 'steps.LocationLabel' },
            { stepIndex: 1, template: '/newrequest/locationdetails', title: 'steps.LocationDetailsLabel' },
            { stepIndex: 2, template: '/newrequest/servicerequest', title: 'steps.ServiceRequestLabel' },
            { stepIndex: 3, template: '/newrequest/servicerequestdetails', title: 'steps.ServiceRequestDetailsLabel' },
            { stepIndex: 4, template: '/newrequest/chargetoaccount', title: 'steps.ChargeToAccountLabel' },
            { stepIndex: 5, template: '/newrequest/confirmrequest', title: 'steps.ConfirmRequestLabel' },
        ];
        this.confirmationStep = this.steps.length - 1;
    }
    Object.defineProperty(WorkOrderRequestStepperService.prototype, "stepper", {
        get: function () {
            return this._stepper;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestStepperService.prototype, "lastCompletedStepIndex", {
        get: function () {
            return this._lastCompletedStepIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestStepperService.prototype, "steps", {
        get: function () { return this._steps; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestStepperService.prototype, "workOrderRequest", {
        get: function () {
            return this.workOrderRequestStore.state;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WorkOrderRequestStepperService.prototype, "Location", {
        get: function () {
            return {
                DisplayText: this.workOrderRequestStore.state.locationDescription,
                LocationId: this.workOrderRequestStore.state.locationId,
                LocationType: this.workOrderRequestStore.state.locationType,
                IsDefault: false,
                IsFavorite: false,
                HasInsideFloors: true,
                HasOutsideFloors: false,
                OperationalCode: this.workOrderRequestStore.state.operationalCode,
                OperationalCodeLabel: this.workOrderRequestStore.state.operationalCodeLabel,
            };
        },
        enumerable: true,
        configurable: true
    });
    WorkOrderRequestStepperService.prototype.init = function (stepper) {
        this._stepper = stepper;
        // initialize steps from routes
        var newRequestRoute = this.router.config.find(function (item) { return item.path === 'newrequest'; });
        if (newRequestRoute && newRequestRoute._loadedConfig && newRequestRoute._loadedConfig.routes && newRequestRoute._loadedConfig.routes.length > 0) {
            this._steps = newRequestRoute._loadedConfig.routes[0].children
                .filter(function (item) { return item.data != null && item.data.breadcrumb != null && item.data.stepIndex != null; })
                .map(function (item) {
                return {
                    title: item.data.breadcrumb,
                    template: "/newrequest/" + item.path,
                    stepIndex: item.data.stepIndex
                };
            });
            this.confirmationStep = this._steps.length - 1;
        }
    };
    WorkOrderRequestStepperService.prototype.reset = function () {
        this._stepper = null;
        this._lastCompletedStepIndex = -1;
        this.workOrderRequestStore.reset();
    };
    WorkOrderRequestStepperService.prototype.getUpdatedStepNumber = function (stepNumber) {
        var updatedStepNumber = stepNumber;
        if (stepNumber > 1 && this.Location.LocationType === LocationType.Land) {
            updatedStepNumber--;
        }
        if (stepNumber > 4 && !this.chargeToAccountLabelStoreService.enableWorkOrderChargeToAccount) {
            updatedStepNumber--;
        }
        return updatedStepNumber;
    };
    WorkOrderRequestStepperService.prototype.setLocation = function (location) {
        // skip the Floors screen if the selected location is a land or if it has no floors
        var shouldSkipTheFloorsScreen = location.LocationType == LocationType.Land || (!location.HasInsideFloors && !location.HasOutsideFloors);
        var wasStepUpdated = this.workOrderRequestStore.setLocation(location);
        if (!wasStepUpdated) {
            if (this.tryToSelectConfirmationPage()) {
                return;
            }
        }
        else {
            this._lastCompletedStepIndex = 0;
            if (shouldSkipTheFloorsScreen) {
                this._lastCompletedStepIndex++;
            }
        }
        if (!!wasStepUpdated) {
            // calculate and set the insideFlag
            this.calculateInsideFlag(location);
        }
        if (this.stepper) {
            this.stepper.selected.completed = true;
            this.stepper.next();
            if (shouldSkipTheFloorsScreen) {
                this.stepper.selected.completed = true;
                this.stepper.next();
            }
        }
    };
    WorkOrderRequestStepperService.prototype.setFloorArea = function (floorId, floorText, workspaceId, workspaceText) {
        if (workspaceId === void 0) { workspaceId = null; }
        if (workspaceText === void 0) { workspaceText = null; }
        var wasStepUpdated = this.workOrderRequestStore.setFloorArea(floorId, floorText, workspaceId, workspaceText);
        if (!wasStepUpdated) {
            if (this.tryToSelectConfirmationPage()) {
                return;
            }
        }
        else {
            this._lastCompletedStepIndex = 1;
        }
        if (this.stepper) {
            this.stepper.selected.completed = true;
            this.stepper.next();
        }
    };
    WorkOrderRequestStepperService.prototype.setRequestType = function (requestType) {
        var wasStepUpdated = this.workOrderRequestStore.setRequestType(requestType);
        if (!wasStepUpdated) {
            if (this.tryToSelectConfirmationPage()) {
                return;
            }
        }
        else {
            this._lastCompletedStepIndex = 2;
        }
        if (this.stepper) {
            this.stepper.selected.completed = true;
            this.stepper.next();
        }
    };
    WorkOrderRequestStepperService.prototype.setRequestDetails = function (description, questionsAndAnswers, uploader) {
        var wasStepUpdated = this.workOrderRequestStore.setRequestDetails(description, questionsAndAnswers.map(function (q) { return new AdditionalInfo(q.QuestionId, q.SelectedAnswerId, q.SelectedAnswerFreeText); }), questionsAndAnswers, uploader);
        if (!wasStepUpdated) {
            if (this.tryToSelectConfirmationPage()) {
                return;
            }
        }
        console.log('StepperService-->enableWorkOrderChargeToAccount-->', this.chargeToAccountLabelStoreService.enableWorkOrderChargeToAccount);
        if (this._lastCompletedStepIndex == 2) {
            this._lastCompletedStepIndex = 3;
            if (!this.chargeToAccountLabelStoreService.enableWorkOrderChargeToAccount) {
                this._lastCompletedStepIndex++;
            }
        }
        if (this.stepper) {
            this.stepper.selected.completed = true;
            this.stepper.next();
            if (!this.chargeToAccountLabelStoreService.enableWorkOrderChargeToAccount) {
                this.stepper.selected.completed = true;
                this.stepper.next();
            }
        }
    };
    WorkOrderRequestStepperService.prototype.setChargeToAccount = function (chargeToAccount, chargeToAccountLabel) {
        var wasStepUpdated = this.workOrderRequestStore.setChargeToAccount(chargeToAccount, chargeToAccountLabel);
        if (!wasStepUpdated) {
            if (this.tryToSelectConfirmationPage()) {
                return;
            }
        }
        else {
            this._lastCompletedStepIndex = 4;
        }
        if (this.stepper) {
            this.stepper.selected.completed = true;
            this.stepper.next();
        }
    };
    WorkOrderRequestStepperService.prototype.suppressedEmergencyKeywords = function (suppressedEmergencyKeywords) {
        this.workOrderRequestStore.suppressedEmergencyKeywords(suppressedEmergencyKeywords);
    };
    WorkOrderRequestStepperService.prototype.duplicateWorkOrder = function (workOrder) {
        if (workOrder.DuplicationEnabled) {
            this.workOrderRequestStore.duplicate(workOrder);
            this._lastCompletedStepIndex = this.confirmationStep - 1;
            this.tryToSelectConfirmationPage();
        }
    };
    WorkOrderRequestStepperService.prototype.tryToSelectConfirmationPage = function () {
        var canSelectLastStep = this._lastCompletedStepIndex === this.confirmationStep - 1;
        if (canSelectLastStep) {
            this.selectWizardStep(this.confirmationStep, false);
        }
        return canSelectLastStep;
    };
    WorkOrderRequestStepperService.prototype.selectWizardStep = function (stepIndex, bypassGuard) {
        if (bypassGuard === void 0) { bypassGuard = true; }
        var step = this.steps.find(function (s) { return s.stepIndex == stepIndex; });
        this.router.navigate([step.template], { queryParams: bypassGuard ? { bypassGuard: true } : {}, skipLocationChange: bypassGuard });
    };
    WorkOrderRequestStepperService.prototype.calculateInsideFlag = function (location) {
        var insideFlag = null;
        if (location != null) {
            if (location.LocationType == LocationType.Building) {
                if (!location.HasInsideFloors && !location.HasOutsideFloors) {
                    // building with no floors
                    insideFlag = true;
                }
            }
            else // land
             {
                insideFlag = false;
            }
        }
        this.workOrderRequestStore.setInsideFlag(insideFlag);
    };
    WorkOrderRequestStepperService.ngInjectableDef = i0.defineInjectable({ factory: function WorkOrderRequestStepperService_Factory() { return new WorkOrderRequestStepperService(i0.inject(i1.WorkOrderRequestStoreService), i0.inject(i2.Router), i0.inject(i3.ChargeToAccountLabelStoreService)); }, token: WorkOrderRequestStepperService, providedIn: "root" });
    return WorkOrderRequestStepperService;
}());
export { WorkOrderRequestStepperService };
