import { MatDialogRef } from '@angular/material';
var InfoDialogComponent = /** @class */ (function () {
    function InfoDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    Object.defineProperty(InfoDialogComponent.prototype, "contentData", {
        get: function () {
            return this.data;
        },
        enumerable: true,
        configurable: true
    });
    return InfoDialogComponent;
}());
export { InfoDialogComponent };
