<div class="info-modal">
  <div class="modal-header">
    <button mat-icon-button
            [mat-dialog-close]="false"
            [attr.aria-label]="'shared.Close' | translate">
      <svg-icon class="icon-close"
                role="presentation"
                focusable="true"
                src="assets/images/icons/close.svg"></svg-icon>
      <span class="sr-only">{{'shared.Close' | translate}}</span>
    </button>
  </div>
  <svg-icon class="icon-info"
            role="presentation"
            focusable="true"
            src="assets/images/icons/info.svg">
  </svg-icon>
  <div class="mat-dialog-title">
    {{contentData.title | translate: contentData.keyword: contentData.value}}
  </div>
  <div mat-dialog-content>
    <div *ngFor="let contentPart of contentData.content" class="mat-dialog-text {{contentPart.class}}">
      <span *ngIf="contentPart.icon"><svg-icon class="icon-{{contentPart.iconClass}}" src="assets/images/icons/{{contentPart.icon}}.svg"></svg-icon> </span>
      {{contentPart.text | translate: contentData.keyword: contentData.value}}
    </div>
  </div>
  <div mat-dialog-actions class="button-container mt-10">
    <button mat-raised-button color="primary" type="button" [mat-dialog-close]="true" cdkFocusInitial>{{'shared.OK' | translate}}</button>
  </div>
</div>
