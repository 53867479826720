import { Component, OnInit } from '@angular/core';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { Tenant } from 'src/app/modules/core/services/tenant.service';
import { WebRequestConfig } from 'src/app/modules/wrcommon/classes/config';
import { LocalstorageService } from 'src/app/modules/core/services/localstorage.service';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SSOLoginComponent extends DestroyableComponentBase implements OnInit {

  ssoLoginUrl: string = `${WebRequestConfig.baseUrlRealSuiteUserAuth}login`;
  hasTabHeaders: boolean = true;

  constructor(private storageService: LocalstorageService,
    private userService: UserdatasharingService,
    private router: Router) {
    super();
  }

  ngOnInit() {

    const tenant: Tenant = this.storageService.getTenant();
    this.ssoLoginUrl = WebRequestConfig.getLoginUrl(tenant);
    this.hasTabHeaders = this.userService.showTabs;

    if (!this.userService.isRegistered && !!tenant.LoginName) {
      this.router.navigate(["/registration/clientcode"]);
    }

    let isUserRegistered: boolean = tenant.IsUserRegistered;
    let loginName: string = tenant.LoginName;
    const account = this.storageService.getAccount();
    if (!!account) {
      isUserRegistered = isUserRegistered || !!account.callerId;
      loginName = loginName || account.userName;
    }
  }
}
