<footer>
    <div class="mb-3">
        <span class="copyright">
            &copy; {{ footerCopyrightTranslationLabel | translate : '{copyrightYear}' : currentYear}}
        </span>
        <a
            class="footer-link"
            href="https://www.bgis.com/{{currentLanguage}}/privacy-policy.htm"
            target="_blank">
            {{'footer.PrivacyPolicy' | translate}}
        </a>
        |
        <a
            class="footer-link"
            href="https://www.bgis.com/{{currentLanguage}}/terms-of-use.htm"
            target="_blank">
            {{'footer.TermsOfUse' | translate}}
        </a>
        <div>
          <span class="version">
            <span *ngIf="version">{{'footer.Version' | translate}}: {{version }}</span> <span *ngIf="environmentName != 'prod'"> | {{'footer.LastUpdatedOn' | translate}}: {{buildDate | date: 'medium'}} ({{environmentName | uppercase}}-{{environment}})</span>
          </span>
        </div>
    </div>
    <div class="mb-3">
        <span>{{'footer.Accessibility' | translate}}</span>
        <div>
          <span class="hight-contrast">
            <mat-slide-toggle
                              labelPosition="before"
                              [color]="'green'"
                              [checked]="isHighContrast"
                              (change)="toggleHighContrast($event)"
                              >
              <span class="hight-contrast-label">{{'footer.HightContrast' | translate}}</span>
            </mat-slide-toggle>
          </span>
          <div *ngIf="false">
            <span class="text-size"
                  *ngIf="!(responsiveService.state$ | async)">
              <span class="text-size-label">{{'footer.TextSize' | translate}}</span>
              <button mat-mini-fab
                      color="primary">
                <svg-icon src="assets/images/icons/plus.svg"></svg-icon>
              </button>
              <span class="text-size-value">100%</span>
              <button mat-mini-fab
                      color="primary">
                <svg-icon src="assets/images/icons/minus.svg"></svg-icon>
              </button>
            </span>
          </div>
        </div>
    </div>
</footer>
