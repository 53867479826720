import { environment } from 'src/environments/environment';
import { Tenant } from '../../core/services/tenant.service';

export class WebRequestConfig {

  // local
  // static readonly baseUrlClientRequest = "http://localhost/RealClientRequest.WebApi/api/";
  // static readonly baseUrlUserAccount = "http://localhost/RealUserAccount.WebApi/api/";


  // rsqa
  // static readonly baseUrlClientRequest = "https://rsqa.bljc.com/RealSuiteApps/RealSuiteWebAPIs/RealClientRequest/api/";
  // static readonly baseUrlUserAccount = "https://rsqa.bljc.com/RealSuiteApps/RealSuiteWebAPIs/RealUserAccount/api/";
  // static readonly baseUrlClientLogo = "https://rsqa.bljc.com/RealSuite/";

  // qa01
  static readonly baseUrl = environment.baseUrl.startsWith('../') ? `${document.location.protocol}//${document.location.host}/` : environment.baseUrl;
  static readonly baseUrlClientRequest = environment.baseUrlClientRequest.startsWith('http') ? environment.baseUrlClientRequest : WebRequestConfig.baseUrl + environment.baseUrlClientRequest;
  static readonly baseUrlUserAccount = environment.baseUrlUserAccount.startsWith('http') ? environment.baseUrlUserAccount : WebRequestConfig.baseUrl + environment.baseUrlUserAccount;
  static readonly baseUrlClientLogo = environment.baseUrlClientLogo.startsWith('http') ? environment.baseUrlClientLogo : WebRequestConfig.baseUrl + environment.baseUrlClientLogo;
  static readonly baseUrlWebApi = environment.baseUrlWebApi.startsWith('http') ? environment.baseUrlWebApi : WebRequestConfig.baseUrl + environment.baseUrlWebApi;
  static readonly baseUrlRealSuite = environment.baseUrlRealSuite.startsWith('http') ? environment.baseUrlRealSuite : WebRequestConfig.baseUrl + environment.baseUrlRealSuite;
  static readonly baseUrlRealSuiteUserAuth = environment.baseUrlRealSuiteUserAuth.startsWith('http') ? environment.baseUrlRealSuiteUserAuth : WebRequestConfig.baseUrl + environment.baseUrlRealSuiteUserAuth;
  static readonly baseUrlWebAssist = environment.baseUrlWebAssist.startsWith('http') ? environment.baseUrlWebAssist : WebRequestConfig.baseUrl + environment.baseUrlWebAssist;

  // rsua
  // static readonly baseUrlClientRequest = "https://rsua.bljc.com/RealSuiteApps/RealSuiteWebAPIs/RealClientRequest/api/";
  // static readonly baseUrlUserAccount = "https://rsua.bljc.com/RealSuiteApps/RealSuiteWebAPIs/RealUserAccount/api/";
  // static readonly baseUrlClientLogo = "https://rsua.bljc.com/RealSuite/";

  static get environment(): string {

    if (WebRequestConfig.baseUrlClientRequest.startsWith('https://rsqa.bljc.com')) {
      return 'RSQA';
    } else if (WebRequestConfig.baseUrlClientRequest.startsWith('https://rsua.bljc.com')) {
      return 'RSUA';
    } else if (WebRequestConfig.baseUrlClientRequest.startsWith('http://10')) {
      return 'LOCAL';
    } else if (WebRequestConfig.baseUrlClientRequest.startsWith('https://qa01.bljc.com')) {
      return 'QA01';
    } else if (WebRequestConfig.baseUrlClientRequest.startsWith('https://') && WebRequestConfig.baseUrlClientRequest.length > 7 && WebRequestConfig.baseUrlClientRequest.indexOf('.') > 7) {
      return WebRequestConfig.baseUrlClientRequest.substring(7, WebRequestConfig.baseUrlClientRequest.indexOf('.') - 7).toUpperCase();
    }

    return 'UNKNOWN';
  }

  static getLoginUrl(tenant: Tenant): string {

    let loginUrl = `${WebRequestConfig.baseUrlRealSuiteUserAuth}Login?scope=WEB.ASSIST`;
    loginUrl += `&redirectUrl=${WebRequestConfig.baseUrlWebAssist}`;
    if (tenant) {
      if (tenant.rsClientId) {
        loginUrl += `${tenant.rsClientId}`;
      }
      if (tenant.rsClientCertificate) {
        loginUrl += `&client_id=${tenant.rsClientCertificate}`;
      }
    }
    return loginUrl;
  }
}
