import { NgModule, ModuleWithProviders } from '@angular/core';
import { WorkOrderRequestStepperService } from './services/work-order-request-stepper.service';
import { WorkOrderRequestStoreService } from './services/work-order-request-store.service';
import { WrcommonModule } from '../wrcommon/wrcommon.module';
import { FollowupComponent } from './components/followup/followup.component';

@NgModule({
  declarations: [FollowupComponent],
  imports: [
    WrcommonModule
  ],
  exports: [
    FollowupComponent,
    WrcommonModule
  ]
})
export class SharedRequestModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedRequestModule,
      providers: [
        WorkOrderRequestStepperService,
        WorkOrderRequestStoreService
      ]
    };
  }
}

