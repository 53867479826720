import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebRequestConfig } from '../classes/config';
import { TranslationModel } from '../models/translationmodel';
import { Language } from '../classes/language';
import { LocalstorageService } from '../../core/services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    private http: HttpClient,
    private storageService: LocalstorageService
  )
  {
    this.loadTranslations();
  }

  async translate(translateKey: string): Promise<string> {

    let fullKey: string = this.getCurrentLanguage() + "." + translateKey;

    if (!this.storageService.getTranslationIsLoaded())
    {
      return await this.translateNoCache(translateKey);
    }
    else
    {
      let translateValue : string = this.storageService.getTranslation(fullKey);

      // If no translation in language, try English as default
      if (translateValue == null && this.getCurrentLanguage() != Language.TranslationLanguageEnglish) {
        fullKey = `${Language.TranslationLanguageEnglish}.${translateKey}`;

        translateValue = this.storageService.getTranslation(fullKey);
      }

      if (translateValue == null) {
        return `<<<${translateKey}>>>`;
      }
      else
      {
        return translateValue;
      }
    }
  }

  async translateNoCache(translateKey: string): Promise<string> {

    let language: string = Language.English;
    let languageClientSide: string = this.getCurrentLanguage();
    if (Language.SupportedLanguages.hasKey(languageClientSide)) {
      language = Language.SupportedLanguages.getValue(languageClientSide);
    }
    return await this.translateToLanguageNoCache(language, translateKey);
  }

  async translateToLanguageNoCache(language: string, translateKey: string): Promise<string> {
    let attributeName: string = translateKey.split(".")[0];
    let code: string = translateKey.split(".")[1];
    
    let asyncResult = await this.http.get<string>(`${WebRequestConfig.baseUrlClientRequest}public/translateNoCache/CSRWEB/${language}/${attributeName}/${code}`).toPromise();

    return asyncResult;
  }

  getCurrentLanguage(): string {

    let result: string = '';
    if (this.storageService.getCurrentLanguage() == null) {
      this.storageService.setCurrentLanguage(Language.TranslationLanguageEnglish);

      result = Language.TranslationLanguageEnglish;

    }
    else {
      result = this.storageService.getCurrentLanguage();
    }

    return result;

  }

  setCurrentLanguage(languageCode: string) {
    this.storageService.setCurrentLanguage(languageCode);
  }


  loadTranslations(): void {

    if (!this.storageService.getTranslationIsLoaded() )
    {
      console.log("loading all translations ...");

      this.http.get<TranslationModel[]>(WebRequestConfig.baseUrlClientRequest + 'public/GetTranslationsByDomainAndLanguage/CSRWEB')
        .subscribe
        (
          res => {

            for (var i = 0; i < res.length; i++) {
              this.storageService.setTranslation(res[i].TranslationKey, res[i].TranslationValue);
            }

            console.log(`loaded ${res.length} translations.`);
            this.storageService.setTranslationIsLoaded(true);
        },
        err => console.error('Observer got an error: ' + err)
        );

    }
    else {
      console.log("skipping loading all translations new ...");
    }

  }


  forceLoad(): void {
    this.storageService.setTranslationIsLoaded(false);
    this.loadTranslations();      
  }
}
