import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserdatasharingService } from './userdatasharing.service';
import { Constants } from '../../wrcommon/classes/constants';
import { Language } from '../../wrcommon/classes/language';
import { LocalstorageService } from './localstorage.service';

@Injectable()
export class LanguageInterceptorService implements HttpInterceptor {

  private userService: UserdatasharingService;

  constructor(private injector: Injector,
    private storageService: LocalstorageService)
  {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    const urlParts = request.url.split('?');
    const tenant = this.storageService.getTenant();
    if (!!tenant && !!tenant.SessionId) {
      if (urlParts.length == 1) {
        urlParts.push(`sessionId=${tenant.SessionId}`);
      } else {
        urlParts[1] = `${urlParts[1]}&sessionId=${tenant.SessionId}`;
      }
    }

    // Clone the request to add the new header
    const clonedRequest: HttpRequest<any> = request.clone({
      url: urlParts.join('?'),
      headers: request.headers.set(Constants.AcceptLanguage, this.getCurrentLanguage())
    });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }


  // we need to decide about cookies or RealSuite language because the language is required for the Login page and other pages where we do not know the user
  getCurrentLanguage(): string {
    let result: string = Language.English;
    this.userService = this.injector.get(UserdatasharingService);
    if (this.userService != null) {
      result = this.userService.getCurrentLanguage();
    }
    return result;
  }



}
