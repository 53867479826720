import { HttpHeaders } from '@angular/common/http';
var WebAssistHttpHeaders = /** @class */ (function () {
    function WebAssistHttpHeaders() {
    }
    Object.defineProperty(WebAssistHttpHeaders, "HttpOptions", {
        //TO-DO: the language is not hard coded.
        get: function () {
            return {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    "Accept-Language": "ENGLISH"
                })
            };
        },
        enumerable: true,
        configurable: true
    });
    return WebAssistHttpHeaders;
}());
export { WebAssistHttpHeaders };
