import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { takeUntil } from "rxjs/operators";
import { LocalstorageService } from 'src/app/modules/core/services/localstorage.service';
import { TenantService } from 'src/app/modules/core/services/tenant.service';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { HighContrastStoreService } from '../../services/high-contrast-store.service';
import { brandVariables } from 'src/brand-variables';
var ClientlogoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ClientlogoComponent, _super);
    function ClientlogoComponent(storageService, tenantService, highContrastStoreService, cdref) {
        var _this = _super.call(this) || this;
        _this.storageService = storageService;
        _this.tenantService = tenantService;
        _this.highContrastStoreService = highContrastStoreService;
        _this.cdref = cdref;
        _this.client = 'bgis';
        _this.hasClientLogo = false;
        _this.Math = Math;
        _this.wideImage = brandVariables.showWideImage;
        _this.defaultClientName = brandVariables.defaultClientName;
        return _this;
    }
    ClientlogoComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            var tenant = _this.storageService.getTenant();
            _this.client = (tenant ? (tenant.Host || 'bgis') : 'bgis').replace(/\d+/g, '');
            if (_this.client !== 'bgis' && !_this.hasClientLogo && !_this.wideImage) {
                _this.hasClientLogo = true;
                _this.cdref.markForCheck();
                setTimeout(function () {
                    if (_this.client !== 'bgis' && !_this.wideImage && _this.logo && Math.abs(_this.logo.nativeElement.offsetHeight - _this.logo.nativeElement.offsetWidth) > 20) {
                        _this.wideImage = true;
                        _this.cdref.markForCheck();
                    }
                }, 200);
            }
        }, 200);
        this.tenantService.state$
            .pipe(takeUntil(this.destroyed))
            .subscribe(function (tenant) {
            _this.client = (tenant ? (tenant.Host || 'bgis') : 'bgis').replace(/\d+/g, '');
            if (_this.client !== 'bgis' && !_this.hasClientLogo && !_this.wideImage) {
                _this.hasClientLogo = true;
                _this.cdref.markForCheck();
                setTimeout(function () {
                    if (_this.client !== 'bgis' && !_this.wideImage && _this.logo && Math.abs(_this.logo.nativeElement.offsetHeight - _this.logo.nativeElement.offsetWidth) > 20) {
                        _this.wideImage = true;
                        _this.cdref.markForCheck();
                    }
                }, 200);
            }
        });
    };
    ClientlogoComponent.prototype.setDefaultLogo = function () {
        this.client = 'bgis';
        this.hasClientLogo = false;
        console.log('setDefaultLogo');
    };
    return ClientlogoComponent;
}(DestroyableComponentBase));
export { ClientlogoComponent };
