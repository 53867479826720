import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { ErrorComponent } from 'src/app/modules/core/components/error/error.component';
import { AuthGuard } from './modules/core/services/auth.guard';
import { TenantService } from './modules/core/services/tenant.service';
import { AfterLoginService } from './modules/core/services/after-login.service';
import { SSOLoginComponent } from './modules/core/components/sso-login/sso-login.component';
import { AccessForbiddenComponent } from './modules/core/components/access-forbidden/access-forbidden.component';
import { RegistrationGuard } from './modules/core/services/registration.guard';
import { MainComponent } from './modules/core/components/main/main.component';

const appRoutes: Routes = [
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: "login",
        component: SSOLoginComponent,
        canActivate: [AfterLoginService],
        data: { breadcrumb: 'shared.WebRequest' }
      },
      {
        path: "registration",
        loadChildren: "./modules/registration/registration.module#RegistrationModule",
        canLoad: [RegistrationGuard],
        data: { breadcrumb: 'shared.WebRequest' }
      },
      {
        path: "account",
        loadChildren: "./modules/unlockreset/unlockreset.module#UnlockResetModule",
        data: { breadcrumb: 'shared.WebRequest', needHelp: true }
      }
    ]
  },
  {
    path: "settings",
    loadChildren: "./modules/account-settings/account-settings.module#AccountSettingsModule",
    canLoad: [AuthGuard]
  },
  {
    path: "newrequest",
    loadChildren: "./modules/new-request/new-request.module#NewRequestModule",
    data: { breadcrumb: 'SideNav.NewRequest' },
    canLoad: [AuthGuard]
  },
  {
    path: "mylocations",
    loadChildren: "./modules/my-locations/my-locations.module#MyLocationsModule",
    data: { breadcrumb: 'SideNav.ManageLocations' },
    canLoad: [AuthGuard]
  },
  {
    path: "myrequests",
    loadChildren: "./modules/my-requests/my-requests.module#MyRequestsModule",
    data: { breadcrumb: 'SideNav.MyRequests' },
    canLoad: [AuthGuard]
  },
  {
    path: "faq",
    loadChildren: "./modules/faq/faq.module#FaqModule",
    data: { breadcrumb: 'SideNav.FAQs' },
    canLoad: [AuthGuard]
  },
  {
    path: 'error',
    component: ErrorComponent,
    data: { breadcrumb: 'SideNav.Error' }
  },
  {
    path: 'access-forbidden',
    component: AccessForbiddenComponent,
    data: { breadcrumb: 'SideNav.Error' }
  },
  {
    path: ':rsClientId',
    canActivate: [TenantService],
    children: [{
      path: "**", component: ErrorComponent, data: { error: 404 }
    }]
  },
  { path: "**", component: ErrorComponent, data: { error: 404 } }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        // enableTracing: true, // <-- debugging purposes only
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: PreloadAllModules
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
