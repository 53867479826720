import { HttpHeaders } from '@angular/common/http';

export class WebAssistHttpHeaders {
  constructor() {
  }

  //TO-DO: the language is not hard coded.
  public static get HttpOptions()
  {
    return {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept-Language": "ENGLISH"
      })
    };
  }

}
