/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clientlogo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./clientlogo.component";
import * as i5 from "../../services/localstorage.service";
import * as i6 from "../../services/tenant.service";
import * as i7 from "../../services/high-contrast-store.service";
var styles_ClientlogoComponent = [i0.styles];
var RenderType_ClientlogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientlogoComponent, data: {} });
export { RenderType_ClientlogoComponent as RenderType_ClientlogoComponent };
function View_ClientlogoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["logo", 1]], null, 4, "img", [["class", "client-logo main-logo"]], [[8, "src", 4], [8, "alt", 0], [2, "notSquare", null]], [[null, "error"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("error" === en)) {
        var pd_1 = (_co.setDefaultLogo() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 1), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵppd(4, 1)], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, "/"); _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(2, "./assets/logos/logo-", _co.client, "", (i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 3).transform(_co.highContrastStoreService.state$)) ? "-hc.png" : ".png"), ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.client)), " logo"); var currVal_2 = _co.wideImage; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ClientlogoComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.UpperCasePipe, []), i1.ɵqud(671088640, 1, { logo: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "clientLogo"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientlogoComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "img", [["alt", "BGIS logo"], ["class", "bgis-logo main-logo"]], [[8, "src", 4]], null, null, null, null)), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.client && _co.hasClientLogo); _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(2, "./assets/logos/logo-", _co.defaultClientName, "", (i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.highContrastStoreService.state$)) ? "-hc.png" : ".png"), ""); _ck(_v, 5, 0, currVal_1); }); }
export function View_ClientlogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clientlogo", [], null, null, null, View_ClientlogoComponent_0, RenderType_ClientlogoComponent)), i1.ɵdid(1, 4374528, null, 0, i4.ClientlogoComponent, [i5.LocalstorageService, i6.TenantService, i7.HighContrastStoreService, i1.ChangeDetectorRef], null, null)], null, null); }
var ClientlogoComponentNgFactory = i1.ɵccf("app-clientlogo", i4.ClientlogoComponent, View_ClientlogoComponent_Host_0, {}, {}, []);
export { ClientlogoComponentNgFactory as ClientlogoComponentNgFactory };
