import * as i0 from "@angular/core";
var LoggingService = /** @class */ (function () {
    function LoggingService() {
    }
    // Send errors to server here
    LoggingService.prototype.debug = function (message) {
        var logEntry = this.createLogStatement('debug', message);
        console.info(logEntry);
        return logEntry;
    };
    LoggingService.prototype.error = function (message) {
        var logEntry = this.createLogStatement('error', message);
        console.error(logEntry);
        return logEntry;
    };
    LoggingService.prototype.warn = function (message) {
        var logEntry = this.createLogStatement('warning', message);
        console.warn(logEntry);
        return logEntry;
    };
    LoggingService.prototype.info = function (message) {
        var logEntry = this.createLogStatement('info', message);
        console.info(logEntry);
        return logEntry;
    };
    LoggingService.prototype.getCurrentDate = function () {
        var now = new Date();
        return "[" + now.toLocaleString() + "]";
    };
    LoggingService.prototype.createLogStatement = function (level, message) {
        var SEPARATOR = " ";
        var date = this.getCurrentDate();
        return "[" + level + "]" + SEPARATOR + date + SEPARATOR + message;
    };
    LoggingService.ngInjectableDef = i0.defineInjectable({ factory: function LoggingService_Factory() { return new LoggingService(); }, token: LoggingService, providedIn: "root" });
    return LoggingService;
}());
export { LoggingService };
