import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { WebRequestConfig } from 'src/app/modules/wrcommon/classes/config';
import { LocalstorageService } from 'src/app/modules/core/services/localstorage.service';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { Router } from '@angular/router';
var SSOLoginComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SSOLoginComponent, _super);
    function SSOLoginComponent(storageService, userService, router) {
        var _this = _super.call(this) || this;
        _this.storageService = storageService;
        _this.userService = userService;
        _this.router = router;
        _this.ssoLoginUrl = WebRequestConfig.baseUrlRealSuiteUserAuth + "login";
        _this.hasTabHeaders = true;
        return _this;
    }
    SSOLoginComponent.prototype.ngOnInit = function () {
        var tenant = this.storageService.getTenant();
        this.ssoLoginUrl = WebRequestConfig.getLoginUrl(tenant);
        this.hasTabHeaders = this.userService.showTabs;
        if (!this.userService.isRegistered && !!tenant.LoginName) {
            this.router.navigate(["/registration/clientcode"]);
        }
        var isUserRegistered = tenant.IsUserRegistered;
        var loginName = tenant.LoginName;
        var account = this.storageService.getAccount();
        if (!!account) {
            isUserRegistered = isUserRegistered || !!account.callerId;
            loginName = loginName || account.userName;
        }
    };
    return SSOLoginComponent;
}(DestroyableComponentBase));
export { SSOLoginComponent };
