import { Injectable } from '@angular/core';
import { MatStepper } from '@angular/material';
import { Router } from "@angular/router";
import { FileUploader } from 'ng2-file-upload';
import { ChargeToAccountLabelStoreService } from 'src/app/modules/core/services/charge-to-account-label-store.service';
import { LocationType } from 'src/app/modules/shared-location/models/locationType';
import { LocationModel } from 'src/app/modules/shared-location/models/locationModel';
import { WorkOrderRequestStoreService } from './work-order-request-store.service';
import { RequestTypeModel } from '../models/RequestCategoryResultModel';
import { WorkOrderRequestModel, AdditionalInfo } from '../models/work-order-request.model';
import { ChargeToAccountModel } from '../models/ChargeToAccountModel';
import { QuestionModel } from '../models/questionModel';
import { FullWorkOrderModel } from '../models/fullWorkOrderModel';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderRequestStepperService {

  private _stepper: MatStepper;
  get stepper(): MatStepper {
    return this._stepper;
  }

  private _lastCompletedStepIndex: number = -1;
  get lastCompletedStepIndex(): number {
    return this._lastCompletedStepIndex;
  }

  private _steps: { stepIndex: number, template: string, title: string }[] = [
    { stepIndex: 0, template: '/newrequest/location', title: 'steps.LocationLabel' },
    { stepIndex: 1, template: '/newrequest/locationdetails', title: 'steps.LocationDetailsLabel' },
    { stepIndex: 2, template: '/newrequest/servicerequest', title: 'steps.ServiceRequestLabel' },
    { stepIndex: 3, template: '/newrequest/servicerequestdetails', title: 'steps.ServiceRequestDetailsLabel' },
    { stepIndex: 4, template: '/newrequest/chargetoaccount', title: 'steps.ChargeToAccountLabel' },
    { stepIndex: 5, template: '/newrequest/confirmrequest', title: 'steps.ConfirmRequestLabel' },
  ];

  private confirmationStep: number = this.steps.length - 1;

  get steps() { return this._steps; }

  constructor(
    private workOrderRequestStore: WorkOrderRequestStoreService,
    private router: Router,
    private chargeToAccountLabelStoreService: ChargeToAccountLabelStoreService
  ) {
  }

  get workOrderRequest(): WorkOrderRequestModel {
    return this.workOrderRequestStore.state;
  }

  get Location(): LocationModel {
    return {
      DisplayText: this.workOrderRequestStore.state.locationDescription,
      LocationId: this.workOrderRequestStore.state.locationId,
      LocationType: this.workOrderRequestStore.state.locationType,
      IsDefault: false,
      IsFavorite: false,
      HasInsideFloors: true,
      HasOutsideFloors: false,
      OperationalCode: this.workOrderRequestStore.state.operationalCode,
      OperationalCodeLabel: this.workOrderRequestStore.state.operationalCodeLabel,
    };
  }

  init(stepper: MatStepper) {
    this._stepper = stepper;

    // initialize steps from routes
    const newRequestRoute = this.router.config.find(item => item.path === 'newrequest') as any;
    if (newRequestRoute && newRequestRoute._loadedConfig && newRequestRoute._loadedConfig.routes && newRequestRoute._loadedConfig.routes.length > 0) {
      this._steps = newRequestRoute._loadedConfig.routes[0].children
        .filter(item => item.data != null && item.data.breadcrumb != null && item.data.stepIndex != null)
        .map(item => {
          return {
            title: item.data.breadcrumb,
            template: `/newrequest/${item.path}`,
            stepIndex: item.data.stepIndex
          };
        });

      this.confirmationStep = this._steps.length - 1;
    }
  }

  reset() {
    this._stepper = null;
    this._lastCompletedStepIndex = -1;
    this.workOrderRequestStore.reset();
  }

  getUpdatedStepNumber(stepNumber: number): number {
    let updatedStepNumber = stepNumber;

    if (stepNumber > 1 && this.Location.LocationType === LocationType.Land) {
      updatedStepNumber--;
    }

    if (stepNumber > 4 && !this.chargeToAccountLabelStoreService.enableWorkOrderChargeToAccount) {
      updatedStepNumber--;
    }

    return updatedStepNumber;
  }

  setLocation(location: LocationModel) {

    // skip the Floors screen if the selected location is a land or if it has no floors
    const shouldSkipTheFloorsScreen: boolean = location.LocationType == LocationType.Land || (!location.HasInsideFloors && !location.HasOutsideFloors);

    const wasStepUpdated = this.workOrderRequestStore.setLocation(location);
    if (!wasStepUpdated) {
      if (this.tryToSelectConfirmationPage()) {
        return;
      }
    } else {
      this._lastCompletedStepIndex = 0;

      if (shouldSkipTheFloorsScreen) {
        this._lastCompletedStepIndex++;
      }
    }

    if (!!wasStepUpdated) {
      // calculate and set the insideFlag
      this.calculateInsideFlag(location);
    }

    if (this.stepper) {
      this.stepper.selected.completed = true;
      this.stepper.next();

      if (shouldSkipTheFloorsScreen) {
        this.stepper.selected.completed = true;
        this.stepper.next();
      }
    }
  }

  setFloorArea(
    floorId: number | null,
    floorText: string | null,
    workspaceId: number | null = null,
    workspaceText: string | null = null
  ) {
    const wasStepUpdated = this.workOrderRequestStore.setFloorArea(floorId, floorText, workspaceId, workspaceText);
    if (!wasStepUpdated) {
      if (this.tryToSelectConfirmationPage()) {
        return;
      }
    } else {
      this._lastCompletedStepIndex = 1;
    }

    if (this.stepper) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
  }

  setRequestType(requestType: RequestTypeModel) {
    const wasStepUpdated = this.workOrderRequestStore.setRequestType(requestType);
    if (!wasStepUpdated) {
      if (this.tryToSelectConfirmationPage()) {
        return;
      }
    } else {
      this._lastCompletedStepIndex = 2;
    }

    if (this.stepper) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
  }

  setRequestDetails(
    description: string,
    questionsAndAnswers: Array<QuestionModel>,
    uploader: FileUploader
  )
  {
    const wasStepUpdated = this.workOrderRequestStore.setRequestDetails(
      description,
      questionsAndAnswers.map(q => { return new AdditionalInfo(q.QuestionId, q.SelectedAnswerId, q.SelectedAnswerFreeText) }),
      questionsAndAnswers,
      uploader);

    if (!wasStepUpdated) {
      if (this.tryToSelectConfirmationPage()) {
        return;
      }
    }
    console.log('StepperService-->enableWorkOrderChargeToAccount-->', this.chargeToAccountLabelStoreService.enableWorkOrderChargeToAccount);
    if (this._lastCompletedStepIndex == 2) {
      this._lastCompletedStepIndex = 3;
      if (!this.chargeToAccountLabelStoreService.enableWorkOrderChargeToAccount) {
        this._lastCompletedStepIndex++;
      }
    }

    if (this.stepper) {
      this.stepper.selected.completed = true;
      this.stepper.next();

      if (!this.chargeToAccountLabelStoreService.enableWorkOrderChargeToAccount) {
        this.stepper.selected.completed = true;
        this.stepper.next();
      }
    }
  }

  setChargeToAccount(chargeToAccount: ChargeToAccountModel, chargeToAccountLabel: string)
  {
    const wasStepUpdated = this.workOrderRequestStore.setChargeToAccount(chargeToAccount, chargeToAccountLabel);
    if (!wasStepUpdated) {
      if (this.tryToSelectConfirmationPage()) {
        return;
      }
    } else {
      this._lastCompletedStepIndex = 4;
    }

    if (this.stepper) {
      this.stepper.selected.completed = true;
      this.stepper.next();
    }
  }

  suppressedEmergencyKeywords(suppressedEmergencyKeywords: string[]) {
    this.workOrderRequestStore.suppressedEmergencyKeywords(suppressedEmergencyKeywords);
  }

  duplicateWorkOrder(workOrder: FullWorkOrderModel) {
    if (workOrder.DuplicationEnabled) {
      this.workOrderRequestStore.duplicate(workOrder);

      this._lastCompletedStepIndex = this.confirmationStep - 1;
      this.tryToSelectConfirmationPage();
    }
  }

  private tryToSelectConfirmationPage(): boolean {
    const canSelectLastStep: boolean = this._lastCompletedStepIndex === this.confirmationStep - 1;
    if (canSelectLastStep) {
      this.selectWizardStep(this.confirmationStep, false);
    }

    return canSelectLastStep;
  }

  private selectWizardStep(stepIndex: number, bypassGuard: boolean = true) {
    const step = this.steps.find(s => s.stepIndex == stepIndex);
    this.router.navigate([step.template], { queryParams: bypassGuard ? { bypassGuard: true } : {}, skipLocationChange: bypassGuard });
  }

  private calculateInsideFlag(location: LocationModel) {
    let insideFlag: boolean | null = null;

    if (location != null) {
      if (location.LocationType == LocationType.Building) {
        if (!location.HasInsideFloors && !location.HasOutsideFloors) {
          // building with no floors
          insideFlag = true;
        }
      }
      else // land
      {
        insideFlag = false;
      }
    }

    this.workOrderRequestStore.setInsideFlag(insideFlag);
  }
}
