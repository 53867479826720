import { MainNav } from './main-nav.model';
export const MAINMENU: MainNav[] = [
  {
    name: 'NewRequest',
    id: 'new-request',
    routerLink: '/newrequest/',
    icon: 'plus',
    isSeparate: true
  },
  {
    id: 'my-requests',
    name: 'MyRequests',
    routerLink: '/myrequests/activerequests',
    icon: 'wrench',
    subnav: [
      {
        name: 'ArchivedRequests',
        routerLink: '/myrequests/archivedrequests',
        icon: 'archive'
      }
    ]
  },
  {
    id: 'manage-locations',
    name: 'ManageLocations',
    routerLink: '/mylocations',
    icon: 'building',
  },
  {
    id: 'faq',
    name: 'FAQs',
    routerLink: '/faq',
    icon: 'question'
  }
];
