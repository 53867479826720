import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountModel, clearPassword } from '../models/accountmodel';
import { LocalstorageService } from './localstorage.service';
import { CallUsRequestService } from './call-us-request.service';
import { Language } from '../../wrcommon/classes/language';
import { WebRequestConfig } from '../../wrcommon/classes/config';

@Injectable({
  providedIn: 'root'
})
export class UserdatasharingService {

  private readonly INITIAL_VALUE: AccountModel = {
    userId: -1,
    clientId: -1,
    clientCode: '',
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    phoneNumber: '',
    phoneNumberCountryCode: '',
    password: '',
    confirmPassword: '',
    RetCode: null,
    ErrMsg: null,

    RememberMe: false,
    language: Language.English,

    requestReceivedNotification: false,
    requestAssignedNotification: true,
    arrivedNotification: false,
    inProgressNotification: true,
    completeNotification: false,
    hasWorkOrders: null,
    AccessToken: null,
    IsFederated: null
  };

  constructor(
    private storageService: LocalstorageService,
    private callUsRequestService: CallUsRequestService,
    private http: HttpClient) {
  }

  private isCurrentUserAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(
    Language.getLanguageFromBrowser()
  );

  private currentUser$: BehaviorSubject<AccountModel> = new BehaviorSubject<AccountModel>(this.INITIAL_VALUE);

  get currentUser() {
    return this.currentUser$.value;
  }


  addData(currentUser: AccountModel) {

    if (currentUser == null) {
      throw new Error(`Invalid currentUser: currentUser == null.`);
    }

    this.storageService.addAccount(clearPassword(currentUser));
    this.currentUser$.next(currentUser);
    this.isCurrentUserAuthenticated$.next(currentUser != null && currentUser.userId > 0);

    this.callUsRequestService.init(currentUser ? currentUser.clientId : -1);
  }

  clear() {
    this.storageService.deleteAccount();
    this.storageService.deleteTenantUser();
    this.currentUser$.next(this.INITIAL_VALUE);
    this.isCurrentUserAuthenticated$.next(false);
    this.callUsRequestService.init(-1);
  }

  getData(): Observable<AccountModel> {
    let currentUser: AccountModel = this.storageService.getAccount();
    this.currentUser$.next(currentUser);
    this.isCurrentUserAuthenticated$.next(currentUser != null && currentUser.userId > 0);
    this.callUsRequestService.init(currentUser ? currentUser.clientId : -1);
    return this.currentUser$.asObservable();
  }

  isAuthenticated(): Observable<boolean> {
    let result: boolean = false;
    const tenant = this.storageService.getTenant();
    const currentUser: AccountModel = this.storageService.getAccount();
    if (!!currentUser && !!tenant && currentUser.clientId === tenant.rsClientId && currentUser.userId > 0) {
      result = true;
    }
    this.isCurrentUserAuthenticated$.next(result);
    return this.isCurrentUserAuthenticated$.asObservable();
  }

  get isRegistered(): boolean {
    const tenant = this.storageService.getTenant();
    return (!!tenant && !!tenant.IsUserRegistered && tenant.CallerId > 0);
  }

  get showTabs(): boolean {
    const tenant = this.storageService.getTenant();
    if (!tenant) {
      return false;
    }

    const enableLogin: boolean = this.isRegistered || !tenant.LoginName;
    const enableSignup: boolean = !this.isRegistered && (!tenant.ssoFederated || !!tenant.LoginName);
    return enableLogin && enableSignup;
  }

  getClientId(): number | null
  {
    let result: number | null = null;
    let currentUser: AccountModel = this.storageService.getAccount();
    if (currentUser != null && currentUser.clientId > 0) {
      result = currentUser.clientId;
    }
    return result;
  }

  getUserId(): number | null {
    let result: number | null = null;
    let currentUser: AccountModel = this.storageService.getAccount();
    if (currentUser != null && currentUser.userId > 0) {
      result = currentUser.userId;
    }
    return result;
  }

  getCallerId(): number | null {
    let result: number | null = null;
    let currentUser: AccountModel = this.storageService.getAccount();
    if (currentUser != null && currentUser.callerId > 0) {
      result = currentUser.callerId;
    }
    return result;
  }

  getAccessToken(): string | null {
    let result: string | null = null;
    let currentUser: AccountModel = this.storageService.getAccount();
    if (currentUser != null) {
      result = currentUser.AccessToken;
    }
    return result;
  }

  setHasWorkOrders(): void {
    let currentUser: AccountModel = this.storageService.getAccount();
    currentUser.hasWorkOrders = true;
    this.addData(currentUser);
  }


  get currentLanguage(): Observable<string> {
    return this.currentLanguage$.asObservable();
  }

  getAppVersion(): Observable<string> {
    return this.http.get<string>(`${WebRequestConfig.baseUrlClientRequest}public/appversion`);
  };


  setCurrentLanguage(currentLanguage: string): void {
    if (!Language.SupportedLanguages.hasValue(currentLanguage)) {
      return;
    }

    if (this.currentLanguage$.value != currentLanguage) {
      this.currentLanguage$.next(currentLanguage);
    }
    const currentUser: AccountModel = this.storageService.getAccount();
    if (!!currentUser) {
      currentUser.language = currentLanguage;
      this.addData(currentUser);
    }
  }

  getCurrentLanguage(): string {
    let result: string = this.currentLanguage$.value;

    const lang = this.getParameterByName(Language.LangQueryString);
    if (Language.SupportedLanguages.hasKey(lang))
    {
      result = Language.SupportedLanguages.getValue(lang);
      this.setCurrentLanguage(result);
    }
    else if (this.currentLanguage$.value)
    {
      result = this.currentLanguage$.value;
    }
    else
    {
      result = Language.getLanguageFromBrowser();
    }

    return result;
  }

  getCurrentLocale(): string {
    return Language.SupportedLanguages.getKey(this.getCurrentLanguage());
  }

  private getParameterByName(name: string, url: string = window.location.href): string {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
