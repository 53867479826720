import { Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';

export abstract class DestroyableComponentBase implements OnDestroy {
  protected destroyed = new Subject<void>();

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
