import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { WebRequestConfig } from '../classes/config';
import { Language } from '../classes/language';
import { LocalstorageService } from '../../core/services/localstorage.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/services/localstorage.service";
var TranslationService = /** @class */ (function () {
    function TranslationService(http, storageService) {
        this.http = http;
        this.storageService = storageService;
        this.loadTranslations();
    }
    TranslationService.prototype.translate = function (translateKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fullKey, translateValue;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        fullKey = this.getCurrentLanguage() + "." + translateKey;
                        if (!!this.storageService.getTranslationIsLoaded()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.translateNoCache(translateKey)];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2:
                        translateValue = this.storageService.getTranslation(fullKey);
                        // If no translation in language, try English as default
                        if (translateValue == null && this.getCurrentLanguage() != Language.TranslationLanguageEnglish) {
                            fullKey = Language.TranslationLanguageEnglish + "." + translateKey;
                            translateValue = this.storageService.getTranslation(fullKey);
                        }
                        if (translateValue == null) {
                            return [2 /*return*/, "<<<" + translateKey + ">>>"];
                        }
                        else {
                            return [2 /*return*/, translateValue];
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TranslationService.prototype.translateNoCache = function (translateKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var language, languageClientSide;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        language = Language.English;
                        languageClientSide = this.getCurrentLanguage();
                        if (Language.SupportedLanguages.hasKey(languageClientSide)) {
                            language = Language.SupportedLanguages.getValue(languageClientSide);
                        }
                        return [4 /*yield*/, this.translateToLanguageNoCache(language, translateKey)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TranslationService.prototype.translateToLanguageNoCache = function (language, translateKey) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var attributeName, code, asyncResult;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        attributeName = translateKey.split(".")[0];
                        code = translateKey.split(".")[1];
                        return [4 /*yield*/, this.http.get(WebRequestConfig.baseUrlClientRequest + "public/translateNoCache/CSRWEB/" + language + "/" + attributeName + "/" + code).toPromise()];
                    case 1:
                        asyncResult = _a.sent();
                        return [2 /*return*/, asyncResult];
                }
            });
        });
    };
    TranslationService.prototype.getCurrentLanguage = function () {
        var result = '';
        if (this.storageService.getCurrentLanguage() == null) {
            this.storageService.setCurrentLanguage(Language.TranslationLanguageEnglish);
            result = Language.TranslationLanguageEnglish;
        }
        else {
            result = this.storageService.getCurrentLanguage();
        }
        return result;
    };
    TranslationService.prototype.setCurrentLanguage = function (languageCode) {
        this.storageService.setCurrentLanguage(languageCode);
    };
    TranslationService.prototype.loadTranslations = function () {
        var _this = this;
        if (!this.storageService.getTranslationIsLoaded()) {
            console.log("loading all translations ...");
            this.http.get(WebRequestConfig.baseUrlClientRequest + 'public/GetTranslationsByDomainAndLanguage/CSRWEB')
                .subscribe(function (res) {
                for (var i = 0; i < res.length; i++) {
                    _this.storageService.setTranslation(res[i].TranslationKey, res[i].TranslationValue);
                }
                console.log("loaded " + res.length + " translations.");
                _this.storageService.setTranslationIsLoaded(true);
            }, function (err) { return console.error('Observer got an error: ' + err); });
        }
        else {
            console.log("skipping loading all translations new ...");
        }
    };
    TranslationService.prototype.forceLoad = function () {
        this.storageService.setTranslationIsLoaded(false);
        this.loadTranslations();
    };
    TranslationService.ngInjectableDef = i0.defineInjectable({ factory: function TranslationService_Factory() { return new TranslationService(i0.inject(i1.HttpClient), i0.inject(i2.LocalstorageService)); }, token: TranslationService, providedIn: "root" });
    return TranslationService;
}());
export { TranslationService };
