import { Router, ActivatedRoute } from '@angular/router';
import { WorkOrderRequestService } from 'src/app/modules/shared-request/services/work-order-request.service';
import { UserdatasharingService } from 'src/app/modules/core/services/userdatasharing.service';
import { RegistrationDataSharingService } from 'src/app/modules/core/services/registrationdatasharing.service';
import { LocalstorageService } from '../../core/services/localstorage.service';
import { LoggingService } from './logging.service';
import * as i0 from "@angular/core";
import * as i1 from "./userdatasharing.service";
import * as i2 from "./registrationdatasharing.service";
import * as i3 from "../../shared-request/services/work-order-request.service";
import * as i4 from "@angular/router";
import * as i5 from "./localstorage.service";
import * as i6 from "./logging.service";
var AfterLoginService = /** @class */ (function () {
    function AfterLoginService(userDataSharingService, registrationDataSharingService, workOrderRequestService, router, activatedRoute, storageService, logger) {
        this.userDataSharingService = userDataSharingService;
        this.registrationDataSharingService = registrationDataSharingService;
        this.workOrderRequestService = workOrderRequestService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.storageService = storageService;
        this.logger = logger;
    }
    AfterLoginService.prototype.canActivate = function (route, state) {
        if ('true' === route.queryParamMap.get('logout')) {
            this.userDataSharingService.clear();
        }
        var tenant = this.storageService.getTenant();
        if (!tenant || !tenant.rsClientCertificate || !tenant.Host || !tenant.rsClientId) {
            if (tenant) {
                this.logger.error("Access is denied!!! rsClientCertificate=" + tenant.rsClientCertificate + ", Host=" + tenant.rsClientCertificate + ", rsClientId=" + tenant.rsClientId);
            }
            else {
                this.logger.error('Access is denied!!! No tenant was loaded.');
            }
            this.router.navigateByUrl("/access-forbidden");
            return false;
        }
        var currentUser = this.storageService.getAccount();
        if (currentUser != null && currentUser.userId > 0) {
            if (currentUser.clientId !== tenant.rsClientId) {
                this.logger.error("Access is denied!!! tenant.rsClientId(" + tenant.rsClientId + ")!=currentUser.clientId(" + currentUser.clientId + ")");
                this.router.navigateByUrl("/access-forbidden");
                return false;
            }
            this.navigateFromLogin(currentUser);
            return false;
        }
        return true;
    };
    AfterLoginService.prototype.navigateFromLogin = function (accountModel) {
        var _this = this;
        this.workOrderRequestService.HasActiveWorkOrders(accountModel.userId)
            .subscribe(function (hasWorkOrders) {
            _this.registrationDataSharingService.clearMessages();
            _this.userDataSharingService.clear();
            accountModel.hasWorkOrders = hasWorkOrders;
            _this.userDataSharingService.addData(accountModel);
            var redirectUrl = _this.activatedRoute.snapshot.queryParams['redirectUrl'];
            if (!redirectUrl) {
                if (hasWorkOrders) {
                    _this.router.navigateByUrl("/myrequests/activeRequests");
                }
                else {
                    _this.router.navigateByUrl("/newrequest");
                }
            }
            else {
                _this.router.navigateByUrl(redirectUrl);
            }
        });
    };
    AfterLoginService.prototype.convertFullLoginModelToAccountModel = function (loginModel) {
        var result = loginModel == null || loginModel.RetCode < 0 ? null :
            {
                userId: loginModel.UserId,
                userName: loginModel.UserName,
                clientId: loginModel.ClientId,
                firstName: loginModel.FirstName,
                lastName: loginModel.LastName,
                language: loginModel.Language,
                callerId: loginModel.CallerId,
                requestReceivedNotification: loginModel.RequestReceivedNotification,
                requestAssignedNotification: loginModel.RequestAssignedNotification,
                arrivedNotification: loginModel.ArrivedNotification,
                inProgressNotification: loginModel.InProgressNotification,
                completeNotification: loginModel.CompleteNotification
            };
        return result;
    };
    AfterLoginService.ngInjectableDef = i0.defineInjectable({ factory: function AfterLoginService_Factory() { return new AfterLoginService(i0.inject(i1.UserdatasharingService), i0.inject(i2.RegistrationDataSharingService), i0.inject(i3.WorkOrderRequestService), i0.inject(i4.Router), i0.inject(i4.ActivatedRoute), i0.inject(i5.LocalstorageService), i0.inject(i6.LoggingService)); }, token: AfterLoginService, providedIn: "root" });
    return AfterLoginService;
}());
export { AfterLoginService };
