/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../modules/core/components/layout/layout.component.ngfactory";
import * as i3 from "../../modules/core/components/layout/layout.component";
import * as i4 from "../../modules/core/services/userdatasharing.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../modules/wrcommon/services/translation.service";
import * as i8 from "../../modules/core/services/charge-to-account-label-store.service";
import * as i9 from "../../modules/core/services/tenant.service";
import * as i10 from "../../modules/core/services/responsive.service";
import * as i11 from "../../modules/core/services/call-us-request.service";
import * as i12 from "../../modules/core/components/overlayspinner/overlayspinner.component.ngfactory";
import * as i13 from "../../modules/core/components/overlayspinner/overlayspinner.component";
import * as i14 from "../../modules/core/services/overlay.service";
import * as i15 from "./app.component";
import * as i16 from "../../modules/core/services/loader.service";
import * as i17 from "angular-user-idle";
import * as i18 from "../../modules/core/services/localstorage.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, i2.View_LayoutComponent_0, i2.RenderType_LayoutComponent)), i1.ɵdid(1, 245760, null, 0, i3.LayoutComponent, [i4.UserdatasharingService, i5.Router, i6.Title, i7.TranslationService, i8.ChargeToAccountLabelStoreService, i9.TenantService, i10.ResponsiveService, i11.CallUsRequestService], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "app-overlayspinner", [], null, null, null, i12.View_OverlaySpinnerComponent_0, i12.RenderType_OverlaySpinnerComponent)), i1.ɵdid(3, 507904, null, 0, i13.OverlaySpinnerComponent, [i1.ViewContainerRef, i14.OverlayService], { displayProgressSpinner: [0, "displayProgressSpinner"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.isLoading; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i15.AppComponent, [i16.LoaderService, i9.TenantService, i4.UserdatasharingService, i1.Renderer2, i7.TranslationService, i17.UserIdleService, i18.LocalstorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i15.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
