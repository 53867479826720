import { Router } from '@angular/router';
import { LocalstorageService } from './localstorage.service';
import { TenantService } from './tenant.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./localstorage.service";
import * as i3 from "./tenant.service";
var RegistrationGuard = /** @class */ (function () {
    function RegistrationGuard(router, storageService, tenantService) {
        this.router = router;
        this.storageService = storageService;
        this.tenantService = tenantService;
    }
    RegistrationGuard.prototype.canActivate = function (next, state) {
        return this.canNavigate(state.url);
    };
    RegistrationGuard.prototype.canActivateChild = function (next, state) {
        return this.canNavigate(state.url);
    };
    RegistrationGuard.prototype.canLoad = function (route) {
        return this.canNavigate(route.path);
    };
    RegistrationGuard.prototype.canNavigate = function (url) {
        var tenant = this.storageService.getTenant();
        if (!tenant) {
            this.router.navigateByUrl("/access-forbidden");
            return false;
        }
        this.tenantService.setState(tenant);
        var isUserRegistered = tenant.IsUserRegistered;
        var loginName = tenant.LoginName;
        var account = this.storageService.getAccount();
        if (!!account) {
            isUserRegistered = isUserRegistered || !!account.callerId;
            loginName = loginName || account.userName;
        }
        if ((!!isUserRegistered) || (tenant.ssoFederated && (!loginName))) {
            this.router.navigate(["/login"]);
            return false;
        }
        return true;
    };
    RegistrationGuard.ngInjectableDef = i0.defineInjectable({ factory: function RegistrationGuard_Factory() { return new RegistrationGuard(i0.inject(i1.Router), i0.inject(i2.LocalstorageService), i0.inject(i3.TenantService)); }, token: RegistrationGuard, providedIn: "root" });
    return RegistrationGuard;
}());
export { RegistrationGuard };
