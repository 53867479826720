import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountModel } from 'src/app/modules/core/models/accountmodel';
import { WorkOrderRequestService } from 'src/app/modules/shared-request/services/work-order-request.service';
import { UserdatasharingService } from 'src/app/modules/core/services/userdatasharing.service';
import { RegistrationDataSharingService } from 'src/app/modules/core/services/registrationdatasharing.service';
import { FullLoginModel } from 'src/app/modules/shared-account/models/loginmodel';
import { LocalstorageService } from '../../core/services/localstorage.service';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginService implements CanActivate {

  constructor(
    private userDataSharingService: UserdatasharingService,
    private registrationDataSharingService: RegistrationDataSharingService,
    private workOrderRequestService: WorkOrderRequestService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storageService: LocalstorageService,
    private logger: LoggingService
  )
  { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if ('true' === route.queryParamMap.get('logout')) {
      this.userDataSharingService.clear();
    }

    const tenant = this.storageService.getTenant();
    if (!tenant || !tenant.rsClientCertificate || !tenant.Host || !tenant.rsClientId) {
      if (tenant) {
        this.logger.error(`Access is denied!!! rsClientCertificate=${tenant.rsClientCertificate}, Host=${tenant.rsClientCertificate}, rsClientId=${tenant.rsClientId}`);
      } else {
        this.logger.error('Access is denied!!! No tenant was loaded.');
      }
      this.router.navigateByUrl("/access-forbidden");
      return false;
    }

    const currentUser: AccountModel = this.storageService.getAccount();
    if (currentUser != null && currentUser.userId > 0) {
      if (currentUser.clientId !== tenant.rsClientId) {
        this.logger.error(`Access is denied!!! tenant.rsClientId(${tenant.rsClientId})!=currentUser.clientId(${currentUser.clientId})`);
        this.router.navigateByUrl("/access-forbidden");
        return false;
      }

      this.navigateFromLogin(currentUser);
      return false;
    }

    return true;
  }

  public navigateFromLogin(accountModel: AccountModel) {
    this.workOrderRequestService.HasActiveWorkOrders(accountModel.userId)
      .subscribe(hasWorkOrders => {

        this.registrationDataSharingService.clearMessages();
        this.userDataSharingService.clear();
        accountModel.hasWorkOrders = hasWorkOrders;
        this.userDataSharingService.addData(accountModel);

        let redirectUrl: string = this.activatedRoute.snapshot.queryParams['redirectUrl'];

        if (!redirectUrl) {
          if (hasWorkOrders) {
            this.router.navigateByUrl("/myrequests/activeRequests");
          }
          else {
            this.router.navigateByUrl("/newrequest");
          }
        }
        else {
          this.router.navigateByUrl(redirectUrl);
        }
      })
  }

  convertFullLoginModelToAccountModel(loginModel: FullLoginModel): AccountModel {
    let result: AccountModel = loginModel == null || loginModel.RetCode < 0 ? null :
      <AccountModel>{
        userId : loginModel.UserId,
        userName : loginModel.UserName,
        clientId : loginModel.ClientId,
        firstName : loginModel.FirstName,
        lastName : loginModel.LastName,
        language : loginModel.Language,
        callerId : loginModel.CallerId,
        requestReceivedNotification : loginModel.RequestReceivedNotification,
        requestAssignedNotification : loginModel.RequestAssignedNotification,
        arrivedNotification : loginModel.ArrivedNotification,
        inProgressNotification : loginModel.InProgressNotification,
        completeNotification : loginModel.CompleteNotification
      };

    return result;
  }
}
