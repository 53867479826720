/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./access-forbidden.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../wrcommon/pipes/translate.pipe";
import * as i3 from "../../../wrcommon/services/translation.service";
import * as i4 from "./access-forbidden.component";
var styles_AccessForbiddenComponent = [i0.styles];
var RenderType_AccessForbiddenComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccessForbiddenComponent, data: {} });
export { RenderType_AccessForbiddenComponent as RenderType_AccessForbiddenComponent };
export function View_AccessForbiddenComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" 4"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "lock"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["3 "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("account.AccessDenied")); _ck(_v, 8, 0, currVal_0); }); }
export function View_AccessForbiddenComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-access-forbidden", [], null, null, null, View_AccessForbiddenComponent_0, RenderType_AccessForbiddenComponent)), i1.ɵdid(1, 49152, null, 0, i4.AccessForbiddenComponent, [], null, null)], null, null); }
var AccessForbiddenComponentNgFactory = i1.ɵccf("app-access-forbidden", i4.AccessForbiddenComponent, View_AccessForbiddenComponent_Host_0, {}, {}, []);
export { AccessForbiddenComponentNgFactory as AccessForbiddenComponentNgFactory };
