import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfig } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone) { }

  showSuccess(message: string): void {
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      this.snackBar.open(message, 'X', { panelClass: ['success-notification'], duration: AppConfig.settings.SuccessNotificationDurationInMilliseconds }); 
    });
  }

  showWarning(message: string): void {
    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      this.snackBar.open(message, 'X', { panelClass: ['warning-notification'], duration: AppConfig.settings.WarningNotificationDurationInMilliseconds });
    });
  }

  showError(message: string): void {
    this.zone.run(() => {
      // The second parameter is the text in the button. 
      // In the third, we send in the css class for the snack bar.
      this.snackBar.open(message, 'X', { panelClass: ['error-notification'] });
    });
  }
}
