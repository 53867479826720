import * as tslib_1 from "tslib";
import { Store } from '../models/store';
import { LocalstorageService } from './localstorage.service';
import * as i0 from "@angular/core";
import * as i1 from "./localstorage.service";
var HighContrastStoreService = /** @class */ (function (_super) {
    tslib_1.__extends(HighContrastStoreService, _super);
    function HighContrastStoreService(storageService) {
        var _this = _super.call(this, storageService.getIsHighContrast()) || this;
        _this.storageService = storageService;
        return _this;
    }
    HighContrastStoreService.ngInjectableDef = i0.defineInjectable({ factory: function HighContrastStoreService_Factory() { return new HighContrastStoreService(i0.inject(i1.LocalstorageService)); }, token: HighContrastStoreService, providedIn: "root" });
    return HighContrastStoreService;
}(Store));
export { HighContrastStoreService };
