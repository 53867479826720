<div class="emergency-modal">
    <div class="modal-header">
        <button
            mat-icon-button
            [mat-dialog-close]="true"
            [aria-label]="'shared.Close' | translate">
            <svg-icon
              class="icon-close"
              src="assets/images/icons/close.svg">
            </svg-icon>
            <span class="sr-only">{{'shared.Close' | translate}}</span>
        </button>
    </div>
    <svg-icon
        class="icon-warning"
        src="assets/images/icons/warning.svg"></svg-icon>
    <!-- <mat-icon
        aria-hidden="false"
        [attr.aria-label]="alertData.icon">
      {{alertData.icon}}
    </mat-icon> -->
    <div class="mat-dialog-title">
        {{alertData.title | translate | titlecase}}
    </div>
    <div mat-dialog-content>
        <div class="mat-dialog-text" [innerHTML]="alertData.content"></div>
    </div>
    <div mat-dialog-actions
         class="button-container mt-0">
      <a class="mt-5"
         mat-raised-button
         color="primary"
         cdkFocusInitial
         href="tel:{{alertData.phoneNumber}}">
        <svg-icon class="phone"
                  role="presentation"
                  focusable="false"
                  src="assets/images/icons/phone.svg">
        </svg-icon>
        {{alertData.phoneNumber | phone}}
      </a>
      <button class="mt-5" mat-raised-button color="primary" type="button" [mat-dialog-close]="true">{{'shared.Cancel' | translate}}</button>
    </div>
</div>
