/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../wrcommon/pipes/translate.pipe";
import * as i3 from "../../../wrcommon/services/translation.service";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "@angular/router";
import * as i11 from "./error.component";
var styles_ErrorComponent = [i0.styles];
var RenderType_ErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorComponent, data: {} });
export { RenderType_ErrorComponent as RenderType_ErrorComponent };
function View_ErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("error.PageNotFound")); _ck(_v, 1, 0, currVal_0); }); }
function View_ErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 5, "a", [["mat-raised-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatAnchor_0, i5.RenderType_MatAnchor)), i1.ɵdid(10, 180224, null, 0, i6.MatAnchor, [i7.Platform, i8.FocusMonitor, i1.ElementRef, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(11, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.data == null) ? null : _co.data.error) === 404); _ck(_v, 5, 0, currVal_2); var currVal_10 = "/"; _ck(_v, 11, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("SideNav.Error")); var currVal_1 = ((_co.data == null) ? null : _co.data.error); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("error.PageNotFoundContent")); _ck(_v, 7, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 10).disabled ? (0 - 1) : (i1.ɵnov(_v, 10).tabIndex || 0)); var currVal_5 = (i1.ɵnov(_v, 10).disabled || null); var currVal_6 = i1.ɵnov(_v, 10).disabled.toString(); var currVal_7 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); var currVal_8 = i1.ɵnov(_v, 11).target; var currVal_9 = i1.ɵnov(_v, 11).href; _ck(_v, 9, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("error.GoBackToHome")); _ck(_v, 13, 0, currVal_11); }); }
function View_ErrorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " routeParams?.status }}"])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef]), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("SideNav.Error")); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform("SideNav.Error")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ErrorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.routeParams == null) ? null : _co.routeParams.message); _ck(_v, 1, 0, currVal_0); }); }
function View_ErrorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " ", "."])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("error.ErrorIn")); var currVal_1 = ((_co.routeParams == null) ? null : _co.routeParams.url); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ErrorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["mat-raised-button", ""]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatAnchor_0, i5.RenderType_MatAnchor)), i1.ɵdid(1, 180224, null, 0, i6.MatAnchor, [i7.Platform, i8.FocusMonitor, i1.ElementRef, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " ", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_6 = ((_co.routeParams == null) ? null : _co.routeParams.url); _ck(_v, 2, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 1).disabled ? (0 - 1) : (i1.ɵnov(_v, 1).tabIndex || 0)); var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_3 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_4 = i1.ɵnov(_v, 2).target; var currVal_5 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("error.GoBackTo")); var currVal_8 = ((_co.routeParams == null) ? null : _co.routeParams.url); _ck(_v, 4, 0, currVal_7, currVal_8); }); }
function View_ErrorComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["mat-raised-button", ""], ["routerLink", "/"]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatAnchor_0, i5.RenderType_MatAnchor)), i1.ɵdid(1, 180224, null, 0, i6.MatAnchor, [i7.Platform, i8.FocusMonitor, i1.ElementRef, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(2, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_6 = "/"; _ck(_v, 2, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled ? (0 - 1) : (i1.ɵnov(_v, 1).tabIndex || 0)); var currVal_1 = (i1.ɵnov(_v, 1).disabled || null); var currVal_2 = i1.ɵnov(_v, 1).disabled.toString(); var currVal_3 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); var currVal_4 = i1.ɵnov(_v, 2).target; var currVal_5 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("error.GoBackToHome")); _ck(_v, 4, 0, currVal_7); }); }
function View_ErrorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_4)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_5)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_6)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_7)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_8)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.routeParams == null) ? null : _co.routeParams.status); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.routeParams == null) ? null : _co.routeParams.message); _ck(_v, 5, 0, currVal_1); var currVal_2 = (((_co.routeParams == null) ? null : _co.routeParams.url) && (((_co.routeParams == null) ? null : _co.routeParams.url) !== "/")); _ck(_v, 8, 0, currVal_2); var currVal_3 = (((_co.routeParams == null) ? null : _co.routeParams.url) && (_co.routeParams.status !== "404")); _ck(_v, 10, 0, currVal_3); var currVal_4 = (!_co.routeParams.url || (_co.routeParams.status === "404")); _ck(_v, 12, 0, currVal_4); }, null); }
function View_ErrorComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 5, "a", [["mat-raised-button", ""], ["routerLink", "/"]], [[1, "tabindex", 0], [1, "disabled", 0], [1, "aria-disabled", 0], [2, "_mat-animation-noopable", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._haltDisabledEvents($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_MatAnchor_0, i5.RenderType_MatAnchor)), i1.ɵdid(5, 180224, null, 0, i6.MatAnchor, [i7.Platform, i8.FocusMonitor, i1.ElementRef, [2, i9.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(6, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(0, i2.AsyncTranslatePipe, [i3.TranslationService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_7 = "/"; _ck(_v, 6, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("error.UnknownError")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled ? (0 - 1) : (i1.ɵnov(_v, 5).tabIndex || 0)); var currVal_2 = (i1.ɵnov(_v, 5).disabled || null); var currVal_3 = i1.ɵnov(_v, 5).disabled.toString(); var currVal_4 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); var currVal_5 = i1.ɵnov(_v, 6).target; var currVal_6 = i1.ɵnov(_v, 6).href; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("error.GoBackToHome")); _ck(_v, 8, 0, currVal_8); }); }
function View_ErrorComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "pre-container"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Error Summary"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["    ", "\n  "])), i1.ɵpid(0, i4.JsonPipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isProductionEnvironment; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.routeParams)); _ck(_v, 4, 0, currVal_1); }); }
export function View_ErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "error-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_9)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorComponent_10)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.error); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.routeParams.message; _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.routeParams && !_co.data); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.routeParams == null) ? null : _co.routeParams.message); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error", [], null, null, null, View_ErrorComponent_0, RenderType_ErrorComponent)), i1.ɵdid(1, 114688, null, 0, i11.ErrorComponent, [i10.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorComponentNgFactory = i1.ɵccf("app-error", i11.ErrorComponent, View_ErrorComponent_Host_0, {}, {}, []);
export { ErrorComponentNgFactory as ErrorComponentNgFactory };
