import { registerLocaleData } from '@angular/common';
import { AppConfig } from './modules/core/services/app-config.service';
import { TranslationService } from './modules/wrcommon/services/translation.service';
import { Language } from './modules/wrcommon/classes/language';
import { environment } from 'src/environments/environment';
import localeFr from '@angular/common/locales/fr';
import localeGe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';
registerLocaleData(localeFr, Language.TranslationLanguageFrench);
registerLocaleData(localeGe, Language.TranslationLanguageGerman);
registerLocaleData(localeEs, Language.TranslationLanguageSpanish);
registerLocaleData(localeIt, Language.TranslationLanguageItalian);
registerLocaleData(localeJa, Language.TranslationLanguageJapanese);
registerLocaleData(localePt, Language.TranslationLanguagePortuguese);
registerLocaleData(localeRu, Language.TranslationLanguageRussian);
registerLocaleData(localeEn, Language.TranslationLanguageEnglish);
export function appInit(translationService) {
    return function () { return translationService.forceLoad(); };
}
export function initializeApp(appConfig) {
    return function () { return appConfig.load(); };
}
export function baseHrefFactory() {
    var expectedBaseHref = '/' + environment.baseUrlWebAssist;
    var currentBaseHref = window.location.pathname.substr(0, expectedBaseHref.length);
    if (environment.baseUrl.startsWith('../')) {
        if (expectedBaseHref !== currentBaseHref) {
            window.location.href = window.location.href.replace(currentBaseHref, expectedBaseHref);
        }
    }
    else {
        expectedBaseHref = '/';
    }
    return expectedBaseHref;
}
var ɵ0 = function (userService) { return userService.getCurrentLocale(); } // Currency/Date/Number pipes should pick up the locale
;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
