import { HttpClient } from '@angular/common/http';
import { WebRequestConfig } from '../../wrcommon/classes/config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Constants } from '../../wrcommon/classes/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-device-detector";
var WorkOrderRequestService = /** @class */ (function () {
    function WorkOrderRequestService(http, deviceService) {
        this.http = http;
        this.deviceService = deviceService;
        this.deviceInfo = null;
        this.workOrderSource = Constants.WebAssistWeb;
        this.checkIsMobile();
    }
    WorkOrderRequestService.prototype.checkIsMobile = function () {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        var isMobile = this.deviceService.isMobile(); // returns if the device is a mobile device (android / iPhone / windows-phone etc)
        var isTablet = this.deviceService.isTablet(); // returns if the device us a tablet (iPad etc)
        if (isMobile || isTablet) {
            this.workOrderSource = Constants.WebAssistMobile;
        }
    };
    WorkOrderRequestService.prototype.HasActiveWorkOrders = function (userId) {
        return this.http.get(WebRequestConfig.baseUrlClientRequest + 'workorder/hasactiveworkorders/' + userId.toString());
    };
    ;
    WorkOrderRequestService.prototype.getActiveWorkOrderList = function (userId) {
        return this.http.post(WebRequestConfig.baseUrlClientRequest + 'workorder/getactivelist/' + userId.toString(), null);
    };
    ;
    WorkOrderRequestService.prototype.getArchiveWorkOrderList = function (userId) {
        return this.http.post(WebRequestConfig.baseUrlClientRequest + 'workorder/getarchivelist/' + userId.toString(), null);
    };
    ;
    WorkOrderRequestService.prototype.getFullWorkorderRequest = function (clientId, workOrderNumber, needToDuplicate) {
        if (needToDuplicate === void 0) { needToDuplicate = false; }
        var url = WebRequestConfig.baseUrlClientRequest + "/workorder/getfullworkorderbyworkordernum/" + clientId + "/" + workOrderNumber + "/" + needToDuplicate;
        return this.http.get(url);
    };
    ;
    WorkOrderRequestService.prototype.archiveWorkOrder = function (userId, workOrderNumber) {
        return this.http.post(WebRequestConfig.baseUrlClientRequest + "/workorder/archive/" + userId.toString() + "/" + workOrderNumber, null);
    };
    ;
    WorkOrderRequestService.prototype.saveWorkOrder = function (request) {
        request.workOrderSource = this.workOrderSource;
        return this.http.post(WebRequestConfig.baseUrlClientRequest + "workOrder/create/", request);
    };
    WorkOrderRequestService.prototype.getAPIVersionNumber = function (clientId) {
        return this.http.post(WebRequestConfig.baseUrlClientRequest + "client/getversionnumber/" + clientId.toString(), null);
    };
    WorkOrderRequestService.prototype.getProgressNotes = function (request) {
        return this.http.post(WebRequestConfig.baseUrlClientRequest + "workorder/getprogressnotes/", request);
    };
    WorkOrderRequestService.prototype.getReceiveProgressNoteFlag = function (clientId) {
        return this.http.get(WebRequestConfig.baseUrlClientRequest + "workorder/getreceiveprogressnoteflag/" + clientId);
    };
    WorkOrderRequestService.prototype.shouldDisplayProgressNotes = function (clientId, serviceRequestNumber) {
        return this.http.get(WebRequestConfig.baseUrlClientRequest + "workorder/shouldDisplayProgressNotes/" + clientId + "/" + serviceRequestNumber);
    };
    WorkOrderRequestService.prototype.getModuleOptions = function (clientId) {
        return this.http.post(WebRequestConfig.baseUrlClientRequest + "moduleoption/get/" + clientId, clientId);
    };
    WorkOrderRequestService.prototype.saveFollowUp = function (followUpModel) {
        return this.http.post(WebRequestConfig.baseUrlWebApi + "servicerequest/savefollowupnote/", followUpModel);
    };
    WorkOrderRequestService.ngInjectableDef = i0.defineInjectable({ factory: function WorkOrderRequestService_Factory() { return new WorkOrderRequestService(i0.inject(i1.HttpClient), i0.inject(i2.DeviceDetectorService)); }, token: WorkOrderRequestService, providedIn: "root" });
    return WorkOrderRequestService;
}());
export { WorkOrderRequestService };
