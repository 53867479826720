import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Store } from '../models/store';
import { WebRequestConfig } from '../../wrcommon/classes/config';
import { AccountService } from '../../shared-account/services/account.service';
import { AccountModel } from '../models/accountmodel';
import { Tenant } from './tenant.service';
import { UserInfoService, UserInfo, ClientProfile } from './userinfo.service';
import { UserdatasharingService } from './userdatasharing.service';
import { LoggingService } from './logging.service';
import { LocalstorageService } from './localstorage.service';

export interface Tenant {
  rsClientId?: number;
  Host: string;
  Theme: string;
  rsClientCertificate?: string;
  ssoFederated?: boolean;
  LoginName?: string;
  Email?: string;
  FirstName?: string;
  LastName?: string;
  Phone?: string;
  UserId?: number;
  IsUserRegistered?: boolean;
  CallerId?: number;
  SessionId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TenantService extends Store<Tenant> implements CanActivate {

  constructor(private router: Router,
    private storageService: LocalstorageService,
    private userInfoService: UserInfoService,
    private accountService: AccountService,
    private userDataService: UserdatasharingService,
    private logger: LoggingService) {
    super({
      Host: 'bgis',
      Theme: 'bgis'
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> {

    //const host: string = route.params['client'];
    const rsClientId: number = +route.params['rsClientId'];
    if (!rsClientId) {
      return true;
    }

    const sessionId = route.queryParams['sessionId'];

    const currentTenant: Tenant = {
      rsClientId: rsClientId,
      Host: 'bgis',
      Theme: 'bgis',
      SessionId: sessionId
    }

    this.storageService.setTenant(currentTenant);

    this.accountService.getClientCode(rsClientId)
      .pipe(take(1))
      .subscribe((clientCode: string) => {
        if (!!clientCode) {
          currentTenant.Host = clientCode.toLowerCase();
        }
      });

    this.userInfoService.GetClientProfile(rsClientId)
      .pipe(take(1))
      .subscribe((clientProfile: ClientProfile) => {
        if (clientProfile.success) {
          currentTenant.rsClientCertificate = clientProfile.client_id;
          currentTenant.ssoFederated = clientProfile.ssoFederated == 'Y';

          this.userInfoService.GetUserInfo()
            .pipe(take(1))
            .subscribe((userInfo: UserInfo) => {
              if (!userInfo.UserId) {
                this.logger.warn(`The current user: ${userInfo.LoginName} has no userId.`);
                return this.router.navigate(["/registration/clientcode"]);
              }

              this.accountService.getUserAccount(userInfo.LoginName, userInfo.UserId)
                .pipe(take(1))
                .subscribe(account => {
                  if (account.ClientId && account.ClientId !== rsClientId) {
                    this.logger.error(`The User Account client: ${account.ClientId} != rsClient: ${rsClientId}`);
                    return this.router.navigate(["/access-forbidden"]);
                  }
                  currentTenant.LoginName = userInfo.LoginName;
                  currentTenant.UserId = userInfo.UserId;
                  currentTenant.Email = !!account.Email ? account.Email : userInfo.Email;
                  currentTenant.FirstName = !!account.FirstName ? account.FirstName : userInfo.FirstName;
                  currentTenant.LastName = !!account.LastName ? account.LastName : userInfo.LastName;
                  currentTenant.Phone = !!account.PhoneNumber ? account.PhoneNumber : userInfo.Phone;
                  currentTenant.IsUserRegistered = account.IsUserRegistered;
                  currentTenant.CallerId = account.CallerId;
                  this.setState(currentTenant);
                  this.storageService.setTenant(currentTenant);
                  if (account.IsUserRegistered && account.CallerId) {
                    const accountModel: AccountModel = this.userDataService.currentUser;
                    accountModel.clientId = account.ClientId;
                    accountModel.userName = userInfo.LoginName;
                    accountModel.userId = userInfo.UserId;
                    accountModel.email = account.Email;
                    accountModel.firstName = account.FirstName;
                    accountModel.lastName = account.LastName;
                    accountModel.phoneNumber = account.PhoneNumber;
                    accountModel.phoneNumberCountryCode = account.PhoneNumberCountryCode;
                    accountModel.callerId = account.CallerId;
                    accountModel.language = account.Language;
                    accountModel.requestReceivedNotification = account.RequestReceivedNotification;
                    accountModel.requestAssignedNotification = account.RequestAssignedNotification;
                    accountModel.arrivedNotification = account.ArrivedNotification;
                    accountModel.inProgressNotification = account.InProgressNotification;
                    accountModel.completeNotification = account.CompleteNotification;

                    this.userDataService.addData(accountModel);
                    // main page
                    this.router.navigate(["/login"]);
                  } else {
                    this.router.navigate(["/registration/clientcode"]);
                  }
                });
            },
            err => {
              this.logger.warn(`Error getting the user info. Error: ${err.message}`);
              // remove account from session storage if exists
              this.userDataService.clear();

              this.router.navigate(['/login']); // main login
            })
            .add(() => {
              this.setState(currentTenant);
              this.storageService.setTenant(currentTenant);
              return false;
            });
        } else {
          this.logger.error(`Error getting the client profile. Error: ${clientProfile.error} - ${clientProfile.error_description}`);
          // permission error page
          this.router.navigate(['/access-forbidden']);
          return false;
        }
      },
        err => {
          this.logger.error(`Error getting the client profile. Error: ${err.message}`);
          // access forbidden error page
          this.setState(currentTenant);
          this.storageService.setTenant(currentTenant);
          this.router.navigate(['/access-forbidden']);
          return false;
        }
      );
  }
}

