import { ChangeDetectorRef, PipeTransform, WrappedValue } from '@angular/core';
import { TranslationService } from '../services/translation.service';
var AsyncTranslatePipe = /** @class */ (function () {
    function AsyncTranslatePipe(translationService, _ref) {
        this.translationService = translationService;
        this._ref = _ref;
    }
    AsyncTranslatePipe.prototype.transform = function (key) {
        var _this = this;
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (key === this.originalKey) {
            return this.value;
        }
        else {
            this.translationService.translate(key)
                .then(function (val) {
                if (args && args.length >= 2 && args[0] && args[1]) {
                    val = val.replace(new RegExp(args[0], 'g'), args[1]);
                    if (args.length >= 4 && args[2] && args[3]) {
                        val = val.replace(new RegExp(args[2], 'g'), args[3]);
                    }
                }
                _this.value = val;
                _this._ref.markForCheck();
            });
        }
        this.originalKey = key;
        this.value = key;
        return WrappedValue.wrap(key);
    };
    return AsyncTranslatePipe;
}());
export { AsyncTranslatePipe };
