import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, RouterEvent, Route } from "@angular/router";
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/modules/wrcommon/services/translation.service';
import { UserdatasharingService } from '../../services/userdatasharing.service';
import { CallUsRequestService } from '../../services/call-us-request.service';
import { ResponsiveService } from '../../services/responsive.service';
import { TenantService } from '../../services/tenant.service';
import { AccountModel } from '../../models/accountmodel';
import { ChargeToAccountLabelStoreService } from '../../services/charge-to-account-label-store.service';
import { Constants } from 'src/app/modules/wrcommon/classes/constants';
import { WebRequestConfig } from 'src/app/modules/wrcommon/classes/config';
import { DestroyableComponentBase } from 'src/app/modules/wrcommon/classes/destroyable-component-base';
import { virtualKeyboardDetector } from 'src/app/modules/wrcommon/classes/virtual-keyboard-detector';
import { brandVariables } from 'src/brand-variables';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends DestroyableComponentBase implements OnInit {

  displayLeftNavTree = true;
  visible = false;
  title: string;
  phoneSlideoutActive: boolean = false;
  virtualKeyboardVisible: boolean = false;
  localBrandVariables = brandVariables;

  ssoLogoutUrl: string = `${WebRequestConfig.baseUrlRealSuite}User/logout?redirectUrl=${WebRequestConfig.baseUrlWebAssist}login?logout=true`;
  isSSOFederated: boolean = false;
  hasUserName: boolean = false;

  constructor(private userService: UserdatasharingService,
    private router: Router,
    private titleService: Title,
    private translationService: TranslationService,
    private chargeToAccountLabelStoreService: ChargeToAccountLabelStoreService,
    private tenantService: TenantService,
    public responsiveService: ResponsiveService,
    public callUsRequestService: CallUsRequestService) {
    super();
  }

  get isAuthenticated(): Observable<boolean> {
    return this.userService.isAuthenticated();
  }

  ngOnInit() {
    this.tenantService.state$
      .pipe(takeUntil(this.destroyed))
      .subscribe(tenant => {
        this.isSSOFederated = tenant && tenant.ssoFederated;
        this.hasUserName = tenant && !!tenant.LoginName;
      });

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      takeUntil(this.destroyed)
    ).subscribe((event: NavigationEnd) => {
      const url = event.urlAfterRedirects;
      var parts = url.split('/');
      let path = parts.pop() || parts.pop();  // handle potential trailing slash
      path = path.split('?')[0];
      const prevPath = parts.pop();

      let route: Route = this.router.config.find(item => item.path === path);
      if (!route) {
        const childRoutes: Route[] = [].concat.apply([], this.router.config.filter(item => !!item.children).map(item => item.children));
        route = childRoutes.find(item => item.path === path);
        if (!route) {
          const parentRoute = childRoutes.find(item => item.path === prevPath) as any;
          if (parentRoute) {
            if (parentRoute._loadedConfig && parentRoute._loadedConfig.routes && parentRoute._loadedConfig.routes.length > 0) {
              route = parentRoute._loadedConfig.routes.find(item => item.path === path);
            } else {
              route = parentRoute;
            }
          }
        }
      }
      if (!route) {
        const parentRoute = this.router.config.find(item => item.path === prevPath) as any;
        if (parentRoute) {
          if (!route && parentRoute._loadedConfig && parentRoute._loadedConfig.routes && parentRoute._loadedConfig.routes.length > 0) {
            route = parentRoute._loadedConfig.routes.find(item => item.path === path);
            if (!route && parentRoute._loadedConfig.routes[0].children) {
              route = parentRoute._loadedConfig.routes[0].children.find(item => item.path === path);
            }
            if (!route || !route.data || !route.data.breadcrumb) {
              route = parentRoute;
            }
          }
        }
      }

      let title: string = 'shared.WebRequest';
      if (!!route && route.data != null) {
        if (route.data.breadcrumb != null) {
          title = route.data.breadcrumb;
        } else if (route.data.title != null) {
          title = route.data.title;
        }
      }
      this.setHeaderTitle(title);

      // Start listening for virtual keyboard (dis)appearences
      virtualKeyboardDetector.init({ recentlyFocusedTimeoutDuration: 3000 });
      // Handle the appearing of the virtual keyboard
      virtualKeyboardDetector.on('virtualKeyboardVisible', () => {
        this.virtualKeyboardVisible = true;
      });
      // Handle the disappearing of the virtual keyboard
      virtualKeyboardDetector.on('virtualKeyboardHidden', () => {
        this.virtualKeyboardVisible = false;
      });
    });

    //this.responsiveService.state$
    //  .pipe(takeUntil(this.destroyed))
    //  .subscribe(isMobile => {
    //  if (isMobile) {
    //    console.log('Mobile device detected')
    //  }
    //  else {
    //    console.log('Desktop detected')
    //  }
    //});

    this.onResize();
  }

  async setHeaderTitle(title: string) {
    let webRequest = await this.translationService.translate("shared.WebRequest");
    if(webRequest === 'BGIS Assist') {
      webRequest = this.localBrandVariables.appTitle;
    }
    if (title === 'shared.WebRequest') {
      this.title = this.localBrandVariables.appTitle;
    }
    else {
      this.title = await this.translationService.translate(title);
    }
    // update charge To Account label after waiting for 2 sec
    if (title === Constants.ChargeToAccountTitle) {
      this.chargeToAccountLabelStoreService.state$
        .pipe(takeUntil(this.destroyed))
        .subscribe(label => {
          if (!!label) {
            this.title = this.title.replace(new RegExp(Constants.ChargeToAccountKeyword, 'g'), label);
            const headerTitle: string = `${webRequest} - ${this.title}`;
            this.titleService.setTitle(headerTitle);
          }
        })
    }
    const headerTitle: string = webRequest == this.title ? this.title : `${webRequest} - ${this.title}`;
    this.titleService.setTitle(headerTitle);
  }

  get currentUser$(): Observable<AccountModel> {
    return this.userService.getData();
  }

  onResize() {
    this.responsiveService.checkWidth();
  }
}
