import { NgModule, ErrorHandler, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { GlobalErrorHandler } from './services/global-error-handler';
import { ServerErrorInterceptor } from './services/server-error-interceptor';
import { LoaderInterceptor } from './services/loader-interceptor';
import { LanguageInterceptorService } from './services/language-interceptor.service';
import { LayoutComponent } from './components/layout/layout.component';
import { ErrorComponent } from './components/error/error.component';
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';
import { ClientlogoComponent } from './components/clientlogo/clientlogo.component';
import { SideNavTreeComponent } from './components/side-nav-tree/side-nav-tree.component';
import { EmergencyRequestAlertComponent } from './components/emergency-request-alert/emergency-request-alert.component';
import { WrcommonModule } from '../wrcommon/wrcommon.module';
import { SharedRequestModule } from '../shared-request/shared-request.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { OverlaySpinnerComponent } from './components/overlayspinner/overlayspinner.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainComponent } from './components/main/main.component';
import { SSOLoginComponent } from './components/sso-login/sso-login.component';
import { AccessForbiddenComponent } from './components/access-forbidden/access-forbidden.component';

// exports
export { SideNavTreeComponent } from './components/side-nav-tree/side-nav-tree.component';

@NgModule({
  declarations: [
    LayoutComponent,
    ErrorComponent,
    BottomNavComponent,
    ClientlogoComponent,
    SideNavTreeComponent,
    EmergencyRequestAlertComponent,
    BreadcrumbComponent,
    OverlaySpinnerComponent,
    FooterComponent,
    MainComponent,
    SSOLoginComponent,
    AccessForbiddenComponent
  ],
  imports: [
    WrcommonModule,
    AngularSvgIconModule,
    RouterModule,
    SharedRequestModule.forRoot()
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptorService, multi: true }
  ],
  exports: [
    LayoutComponent,
    ErrorComponent,
    BottomNavComponent,
    ClientlogoComponent,
    SideNavTreeComponent,
    OverlaySpinnerComponent,
    SSOLoginComponent,
    AccessForbiddenComponent
  ],
  entryComponents: [
    EmergencyRequestAlertComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}
