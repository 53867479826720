
export class CallUsContent {
  constructor(public callUsText: string,
    public callUsTextEmergencyWords: string,
    public duplicateDisabledText: string,
    public callUsPhoneNumber: string) {
  }

  static fromAPI(item: any): CallUsContent {
    return new CallUsContent(
      item.CallUsText,
      item.CallUsTextEmergencyWords,
      item.DuplicateDisabledText,
      item.CallUsPhoneNumber
    );
  }
}

