import { NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfig } from './app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
var NotificationService = /** @class */ (function () {
    function NotificationService(snackBar, zone) {
        this.snackBar = snackBar;
        this.zone = zone;
    }
    NotificationService.prototype.showSuccess = function (message) {
        var _this = this;
        // Had an issue with the snackbar being ran outside of angular's zone.
        this.zone.run(function () {
            _this.snackBar.open(message, 'X', { panelClass: ['success-notification'], duration: AppConfig.settings.SuccessNotificationDurationInMilliseconds });
        });
    };
    NotificationService.prototype.showWarning = function (message) {
        var _this = this;
        // Had an issue with the snackbar being ran outside of angular's zone.
        this.zone.run(function () {
            _this.snackBar.open(message, 'X', { panelClass: ['warning-notification'], duration: AppConfig.settings.WarningNotificationDurationInMilliseconds });
        });
    };
    NotificationService.prototype.showError = function (message) {
        var _this = this;
        this.zone.run(function () {
            // The second parameter is the text in the button. 
            // In the third, we send in the css class for the snack bar.
            _this.snackBar.open(message, 'X', { panelClass: ['error-notification'] });
        });
    };
    NotificationService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.inject(i1.MatSnackBar), i0.inject(i0.NgZone)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
