import { Constants } from '../../wrcommon/classes/constants';
import { Language } from '../../wrcommon/classes/language';
import SecureLS from 'secure-ls';
import * as i0 from "@angular/core";
var LocalstorageService = /** @class */ (function () {
    function LocalstorageService() {
        this.secureLS = new SecureLS();
    }
    //Add/Get/Remove account data (model) to/from sessionStorage
    LocalstorageService.prototype.addAccount = function (obj) {
        this.saveSessionVariable(Constants.AccountStorageKey, obj);
    };
    LocalstorageService.prototype.getAccount = function () {
        return this.getSessionVariable(Constants.AccountStorageKey);
    };
    LocalstorageService.prototype.deleteAccount = function () {
        this.deleteSessionVariable(Constants.AccountStorageKey);
    };
    //Add/Get/Remove user name data to/from localStorage
    LocalstorageService.prototype.addUserName = function (obj) {
        this.saveLocalVariable(Constants.UserNameStorageKey, obj);
    };
    LocalstorageService.prototype.getUserName = function () {
        return this.getLocalVariable(Constants.UserNameStorageKey);
    };
    LocalstorageService.prototype.deleteUserName = function () {
        this.deleteLocalVariable(Constants.AccountStorageKey);
    };
    //Add/Get/Remove contrast info to/from sessionStorage
    LocalstorageService.prototype.setIsHighContrast = function (isHighContrast) {
        this.saveSessionVariable(Constants.IsHighContrastStorageKey, isHighContrast);
    };
    LocalstorageService.prototype.getIsHighContrast = function () {
        var isHighContrast = this.getSessionVariable(Constants.IsHighContrastStorageKey);
        return isHighContrast == null ? false : isHighContrast;
    };
    LocalstorageService.prototype.deleteIsHighContrast = function () {
        this.deleteSessionVariable(Constants.IsHighContrastStorageKey);
    };
    //Add/Get/Remove tenant to/from sessionStorage
    LocalstorageService.prototype.setTenant = function (tenant) {
        this.saveSecureVariable(Constants.TenantKey, tenant);
    };
    LocalstorageService.prototype.getTenant = function () {
        return this.getSecureVariable(Constants.TenantKey);
    };
    LocalstorageService.prototype.deleteTenantUser = function () {
        var tenant = this.getTenant();
        if (tenant) {
            delete tenant.LoginName;
            delete tenant.UserId;
            delete tenant.IsUserRegistered;
            delete tenant.Email;
            this.setTenant(tenant);
        }
    };
    // set translation
    LocalstorageService.prototype.setTranslation = function (translationKey, translationValue) {
        this.saveSessionVariable(translationKey, translationValue);
    };
    LocalstorageService.prototype.getTranslation = function (translationKey) {
        return this.getSessionVariable(translationKey);
    };
    LocalstorageService.prototype.setCurrentLanguage = function (currentLanguage) {
        this.saveLocalVariable(Language.CurrentLanguageStorageKey, currentLanguage);
    };
    LocalstorageService.prototype.getCurrentLanguage = function () {
        return this.getLocalVariable(Language.CurrentLanguageStorageKey);
    };
    LocalstorageService.prototype.setTranslationIsLoaded = function (isTranslationLoaded) {
        this.saveSessionVariable(Language.TranslationLoadedStorageKey, isTranslationLoaded);
    };
    LocalstorageService.prototype.getTranslationIsLoaded = function () {
        var isTranslationLoaded = this.getSessionVariable(Language.TranslationLoadedStorageKey);
        return isTranslationLoaded == null ? false : isTranslationLoaded;
    };
    LocalstorageService.prototype.saveLocalVariable = function (key, obj) {
        localStorage.setItem(key, JSON.stringify(obj));
    };
    LocalstorageService.prototype.getLocalVariable = function (key) {
        var item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    };
    LocalstorageService.prototype.deleteLocalVariable = function (key) {
        localStorage.removeItem(key);
    };
    LocalstorageService.prototype.saveSessionVariable = function (key, obj) {
        sessionStorage.setItem(key, JSON.stringify(obj));
    };
    LocalstorageService.prototype.getSessionVariable = function (key) {
        var item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    };
    LocalstorageService.prototype.deleteSessionVariable = function (key) {
        sessionStorage.removeItem(key);
    };
    LocalstorageService.prototype.saveSecureVariable = function (key, obj) {
        this.secureLS.set(key, JSON.stringify(obj));
    };
    LocalstorageService.prototype.getSecureVariable = function (key) {
        var item = this.secureLS.get(key);
        return item ? JSON.parse(item) : null;
    };
    LocalstorageService.prototype.deleteSecureVariable = function (key) {
        this.secureLS.remove(key);
    };
    LocalstorageService.ngInjectableDef = i0.defineInjectable({ factory: function LocalstorageService_Factory() { return new LocalstorageService(); }, token: LocalstorageService, providedIn: "root" });
    return LocalstorageService;
}());
export { LocalstorageService };
