import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import * as StackTraceParser from 'error-stack-parser';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LocalstorageService } from './localstorage.service';
import { AccountModel } from '../models/accountmodel';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private injector: Injector,
    private userData: LocalstorageService) {
  }

  getClientErrorMessage(error: Error): string {
    return error.message ?
      error.message :
      error.toString();
  }

  getServerErrorMessage(error: HttpErrorResponse): string {
    return navigator.onLine ?
       (error.error && error.error.ExceptionMessage ? `${error.error.ExceptionMessage }` : `[${error.status}] ${error.message}`) :
      'No Internet Connection';

  }

  getContextInfo(error: Error | HttpErrorResponse) {
    let accountModel: AccountModel = this.userData.getAccount();
    const name = error.name || null;
    const clientId = accountModel ? accountModel.clientId : 'NA';
    const callerId = accountModel ? accountModel.callerId : 'NA';
    const userName = accountModel ? accountModel.userName : 'NA';
    const user = accountModel ? `${accountModel.firstName} ${accountModel.lastName}` : 'NA';
    const time = new Date().getTime();
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : '';
    const status = error instanceof HttpErrorResponse ? error.status : null;
    const message = error.message || error.toString();
    const stack = error instanceof HttpErrorResponse ? null : StackTraceParser.parse(error);

    const errorWithContext = { name, clientId, callerId, userName, user, time, url, status, message, stack };
    return errorWithContext;
  }
}
